import { useEffect, useState } from 'react';
import SortUsers from './SortUsers'
import { Dialog } from 'primereact/dialog';

const SortUsersPopup = ({ visible, setVisible }) => {
  let [users, setUsers] = useState([])
  let [updatedUsers, setUpdatedUsers] = useState([])
  let [loadingUsers, setLoadingUsers] = useState(false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      setLoadingUsers(true)
      fetch(`/dealerships/${window.dealer_slug}/users.json`)
        .then(res => res.json())
        .then(data => {
          setUsers(data);
          setLoadingUsers(false)
        })
    }
  }, [visible]);

  const sendUsersToServer = () => {
    if (updatedUsers !== users) {
      fetch(`/dealerships/${window.dealer_slug}/users/sort`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_ids: updatedUsers.map(u => u.id)
        })
      }).then(() => window.location.reload())
    }
  }

  return (
    <Dialog
      header="Sort Users"
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      dismissableMask={true}
    >
      <div className="row">
        {users && users.length > 0 && <SortUsers users={users} setUpdated={users => setUpdatedUsers(users)} />}
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          sendUsersToServer()
        }}
        disabled={loading}
      >
        {loading ? "Loading..." : "Save changes"}
      </button>
    </Dialog>
  )
}

export default SortUsersPopup
