import { DataTable } from 'primereact/datatable'
import { useViewportData } from '../hooks'
import { ModuleProps } from '../types'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'

const LeadClusterCountByAge: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const [transformedData, setTransformedData] = useState<any>(null)
  const [columnTotals, setColumnTotals] = useState<{}>({})
  const [overallTotal, setOverallTotal] = useState<number>(0)

  useEffect(() => {
    if (data && data.car_lead_cluster_matrix) {
      const rows = Object.entries(data.car_lead_cluster_matrix).map(([range, values]) => {
        // Calculate row total
        const rowTotal = Object.values(values).reduce((sum, count) => sum + count, 0)
        return {
          range,
          ...(typeof values === 'object' ? values : {}),
          total: rowTotal, // Add row total
        }
      })

      // Calculate column totals
      const totals = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5+': 0 }
      let grandTotal = 0

      rows.forEach((row) => {
        Object.keys(totals).forEach((key) => {
          totals[key] += row[key]
        })
        grandTotal += row.total
      })

      setColumnTotals(totals)
      setOverallTotal(grandTotal)
      setTransformedData(rows)
    }
  }, [data])

  return (
    <div ref={viewportRef} className="mt-2">
      <DataTable value={transformedData} loading={loading}>
        <Column
          field="range"
          headerClassName="data-table-header"
          className="data-table-header"
          footer="Total"
        />
        <Column
          field="0"
          header="0"
          headerClassName="data-table-header"
          footer={columnTotals['0']}
        />
        <Column
          field="1"
          header="1"
          headerClassName="data-table-header"
          footer={columnTotals['1']}
        />
        <Column
          field="2"
          header="2"
          headerClassName="data-table-header"
          footer={columnTotals['2']}
        />
        <Column
          field="3"
          header="3"
          headerClassName="data-table-header"
          footer={columnTotals['3']}
        />
        <Column
          field="4"
          header="4"
          headerClassName="data-table-header"
          footer={columnTotals['4']}
        />
        <Column
          field="5+"
          header="5+"
          headerClassName="data-table-header"
          footer={columnTotals['5+']}
        />
        <Column
          field="total"
          header="Total"
          headerClassName="data-table-header"
          footer={overallTotal}
        />
      </DataTable>
    </div>
  )
}

export default LeadClusterCountByAge
