import { useState, useEffect, useRef } from 'react'
import { humanize } from '../entries/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Loading from '../Loading'
import { SplitButton } from 'primereact/splitbutton';
import moment from 'moment'
import { Toggle } from '../settings/leadSettings'
import { CopyableText } from '../shared/CopyableText'
import Show from './show'
import { standardHeaders } from '../entries/utils'
import { Toast } from 'primereact/toast'
import showToast from '../shared/ShowToast'

const feedTypes = [
  "import",
  "email",
  "export",
  "url-only",
  "webhook",
  "dealer_solutions_api",
  "loopit",
  "titan_api",
  "database_feed",
  "orbee"
]

const Name = (rowData) => {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Show feedId={rowData.id} isOpen={isOpen} setIsOpen={setIsOpen} />
      <a
        href={`/dealerships/${window.dealership.slug}/feeds/${rowData.id}`}
        onClick={(e) => {
          e.preventDefault()
          setIsOpen(true)
        }}>
        {rowData.name || 'View'}
      </a>
      {rowData.csv_filename && (
        <div className="small text-secondary">
          <CopyableText text={rowData.csv_filename} />
        </div>
      )}
      <div className="small text-secondary">
        Created {moment(rowData.created_at).fromNow()}
      </div>
      <div className="small text-secondary">
        Last edited by {rowData.last_edited_by} {moment(rowData.updated_at).fromNow()}
      </div>
      {rowData.secondary_feed && (
        <div>
          <div className="badge badge-secondary badge-sm">
            Secondary feed
          </div>
        </div>
      )}
    </div>
  )
}

const Email = (rowData) => {
  return (
    <div className="small text-secondary">
      <CopyableText text={rowData.email} />
    </div>
  )
}

const StockType = (rowData) => (
  <>
    <div>
      {rowData.stock_type || rowData.stock_types.join(', ')}
    </div>
    {rowData.stock_filters_count > 0 && (
      <div className="small text-secondary">
        {rowData.stock_filters_count} stock filters
      </div>
    )}
  </>
)

const ActionsDropdown = ({ rowData, notification }) => {
  const items = [
    {
      label: 'View',
      command: () => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}`
    },
    {
      label: 'Edit',
      command: () => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}/edit`
    },
    {
      label: 'Destroy',
      command: () => {
        fetch(`/dealerships/${window.dealership.slug}/feeds/${rowData.id}`, {
          method: 'DELETE',
          headers: standardHeaders,
        })
          .then(response => {
            if (response.ok) {
              showToast(notification, 'success', 'Feed scheduled for deletion')
            } else {
              showToast(notification, 'error', 'An error has occured')
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    }
  ];

  if (["export", "url-only", 'webhook'].includes(rowData.feed_type)) {
    items.push({
      label: 'Download CSV Sample',
      command: () => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}.csv`
    });
  }

  if (['email', 'import', 'titan_api', 'export'].includes(rowData.feed_type)) {
    items.push({
      label: 'Stock Filters',
      command: () => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}/stock_filters`
    })
  }


  if (rowData.feed_type === 'export') {
    items.push({
      label: 'Export',
      command: () => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}`
    });
  }

  return (
    <SplitButton
      label="View"
      onClick={() => window.location.href = `/dealerships/${window.dealership.slug}/feeds/${rowData.id}`}
      model={items}
      className="p-button-secondary"
      size="small"
      outlined
    />
  );
}

const ActiveToggle = (rowData) => {
  return (
    <Toggle
      rowData={rowData}
      url={`/dealerships/${window.dealership.slug}/feeds/${rowData.id}`}
      attributeName="active"
      objectType="feed"
    />
  )
}

const FeedType = ({ feedType, feeds, notification }) => {

  let feedsOfType = feeds.filter(feed => feed.feed_type === feedType)

  if (feedsOfType.length === 0) {
    return null
  }

  return (
    <>
      <h5 className="text-secondary">{humanize(feedType)}</h5>
      <div key={feedType} className="box mb-3">
        <DataTable value={feedsOfType}>
          <Column field="name" header="Name" body={Name} sortable />
          {feedType === 'email' && <Column field="email" header="Email" body={Email} />}
          <Column field="schema" header="Provider" sortable />
          <Column field="stock_type" header="Stock Type(s)" body={StockType} />
          <Column field="active" header="Active" body={ActiveToggle} />
          <Column
            header="Actions"
            body={(rowData) => <ActionsDropdown rowData={rowData} notification={notification} />}
          />
        </DataTable>
      </div>
    </>
  )
}

const FeedsIndex = () => {
  const [feeds, setFeeds] = useState([])
  let [loading, setLoading] = useState(true)
  let dealershipSlug = window.dealership.slug
  const notification = useRef(null)

  const fetchFeeds = () => {
    fetch(`/dealerships/${dealershipSlug}/feeds.json`)
      .then(response => response.json())
      .then(data => {
        setFeeds(data)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchFeeds()
  }, [])

  return (
    <div className="w-100">
      <Toast ref={notification} />
      {loading && <Loading />}

      {feedTypes.map(feedType => <FeedType key={feedType} feedType={feedType} feeds={feeds} notification={notification} />)}
    </div>
  )
}

export default FeedsIndex