import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { DealershipContext, TestDriveContext, WebsiteContext } from './contexts'

/**
 * Explanation: The context providers are set up outside of the Routes component in App.js. 
 * So we wait until the child components have loaded before we fetch the full JSON objects,
 * because the child components have access to the Route params (useParams) and the context providers.
 */
export const useFetchDealership = async (dealershipId = null) => {
  const { dealershipSlug } = useParams()
  const { websiteSlug } = useParams()
  const { dealership, setDealership } = useContext(DealershipContext)

  if (!dealership && (dealershipSlug || dealershipId)) {
    try {
      const response = await fetch(`/dealerships/${dealershipSlug || dealershipId}.json`)
      const data = await response.json()
      setDealership(data)
    } catch (error) {
      console.error('Error fetching dealership data:', error)
    }
  } else if (!dealership && websiteSlug) {
    try {
      const response = await fetch(`/websites/${websiteSlug}.json`)
      const data = await response.json()
      const response2 = await fetch(`/dealerships/${data.dealership_id}.json`)
      const data2 = await response2.json()
      setDealership(data2)
    } catch (error) {
      console.error('Error fetching dealership data:', error)
    }
  }
}

export const useFetchWebsite = async () => {
  const { websiteSlug } = useParams()
  const { website, setWebsite } = useContext(WebsiteContext)

  if (!website && websiteSlug) {
    try {
      const response = await fetch(`/websites/${websiteSlug}.json`)
      const data = await response.json()
      setWebsite(data)
    } catch (error) {
      console.error('Error fetching website data:', error)
    }
  }
}

export const useFetchTestDrive = async () => {
  const params = useParams()
  const testDriveId = params.testDriveId || null
  const { testDrive, setTestDrive } = useContext(TestDriveContext)

  if (!testDrive && testDriveId) {
    try {
      const response = await fetch(`/dealerships/${window.dealer_slug}/test_drives/${testDriveId}.json`)
      const data = await response.json()
      setTestDrive(data)
    } catch (error) {
      console.error('Error fetching test drive data:', error)
    }
  }
}