import { useEffect, useRef, useState } from 'react'
import { Level, TimeRange, Location, TimeRangeOverrideGroup } from './types'
import { fetchModuleData, getModuleComponentFromName } from './utils'
import { getYearlyTimeRange } from '../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useTabs } from './contexts/hooks'

export const useViewportData = (
  module: string,
  timeRange: TimeRange,
  selectedLocation: Location
): {
  data: any
  loading: boolean
  viewportRef: React.RefObject<HTMLDivElement>
} => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewed, setViewed] = useState<boolean>(false)
  const viewportRef = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(viewportRef)
  const { config }: { config: { defaultTimeRangeOverride?: string[] | TimeRangeOverrideGroup[] } } =
    useConfig()
  const { level } = useAnalyticsDashboard()
  const { selectedTab } = useTabs()
  const moduleConfig = getModuleComponentFromName(module)

  function shouldOverride(group: TimeRangeOverrideGroup): boolean {
    return moduleConfig?.group === group && config?.defaultTimeRangeOverride?.includes(group)
  }

  let overrideToYearlyTimeRange = false
  switch (true) {
    case shouldOverride('Google Adwords Ads'):
      overrideToYearlyTimeRange = true
      break
    case shouldOverride('Google Display Ads'):
      overrideToYearlyTimeRange = true
      break
    case shouldOverride('Google VLA Ads'):
      overrideToYearlyTimeRange = true
      break
    case shouldOverride('Facebook Ads'):
      overrideToYearlyTimeRange = true
      break
    case shouldOverride('Google Analytics'):
      overrideToYearlyTimeRange = true
      break
    default:
      break
  }

  const yearlyTimeRange = overrideToYearlyTimeRange ? getYearlyTimeRange() : undefined

  const loadData = (params: {
    module: string
    timeRange: TimeRange
    yearlyTimeRange?: TimeRange | undefined
    selectedLocation: Location
    level: Level
    selectedTab: number
  }) => {
    setLoading(true)
    fetchModuleData(params)
      .then((newData) => {
        setData(newData)
      })
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => {
        setLoading(false)
        setViewed(true)
      })
  }

  // Load the data when the element is in the viewport and hasn't been viewed yet.
  useEffect(() => {
    if (isInViewport && !viewed) {
      loadData({ module, timeRange, yearlyTimeRange, selectedLocation, level, selectedTab })
    }
  }, [
    isInViewport,
    viewed,
    module,
    timeRange,
    selectedLocation,
    level,
    selectedTab,
    config?.defaultTimeRangeOverride,
  ])

  // Reload data when timeRange, selectedLocation or level changes,
  // regardless of whether the component has been viewed or not.
  useEffect(() => {
    if (viewed) {
      loadData({ module, timeRange, yearlyTimeRange, selectedLocation, level, selectedTab })
    }
  }, [module, timeRange, selectedLocation, level, selectedTab, config?.defaultTimeRangeOverride])

  return { data, loading, viewportRef }
}

export function useOnScreen(ref: React.RefObject<HTMLDivElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
//  WIP
// export const useTotalForTimePeriod = (
//   module: string,
//   timeRange: TimeRange,
//   filteredCampaigns: Campaign[] | undefined = undefined
// ): { total: number; loading: boolean } => {
//   const [loading, setLoading] = useState<boolean>(false)
//   const [data, setData] = useState<any>(null)

//   setLoading(true)
//   fetchModuleData({
//     module,
//     timeRange: timeRange,
//     showTimePeriodTotal: true,
//     adCampaigns: filteredCampaigns ? filteredCampaigns : undefined,
//   })
//     .then((newData) => {
//       setData(newData)
//     })
//     .catch((error) => console.error('Error fetching data:', error))
//     .finally(() => {
//       setLoading(false)
//     })

//   const total = data?.[module]

//   return { total, loading }
// }
