import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { PrimeReactProvider } from 'primereact/api'
import { Toast } from 'primereact/toast'

import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import { OrderStatusOptionsService } from '../orderStatusOptions/orderStatusOptionService'
import OrderStatusOptionForm from '../orderStatusOptions/OrderStatusOptionForm'
import * as Routes from '../../routes'

export default function OrderStatusOptions() {
  const [orderStatusOptions, setOrderStatusOptions] = useState([])
  const [colourOptions, setColourOptions] = useState([])
  const notification = useRef(null)
  const [selectedOrderStatusOption, setSelectedOrderStatusOption] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { websiteSlug } = useParams()

  const openModal = (orderStatusOption = null) => {
    setSelectedOrderStatusOption(orderStatusOption)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedOrderStatusOption(null)
    setIsModalOpen(false)
  }

  const colourBody = (rowData) => {
    return (
      <div className={`badge badge-${rowData.colour_class_name}`}>{rowData.colour_name}</div>
    )
  }  

  const handleSave = async (orderStatusOption) => {
    if (orderStatusOption.id) {
      const url = Routes.website_order_status_option_path(websiteSlug, orderStatusOption.id)
      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify({ order_status_option: orderStatusOption }),
      })
      if (response) {
        showToast(notification, 'success', 'Order Status Option Updated')
        closeModal()
        fetchOrderStatusOptions()
      } else {
        showToast(notification, 'error', 'Error updating Order Status Option')
      }
    } else {
      //const url = `/websites/${websiteSlug}/order_status_options`
      const url = Routes.website_order_status_options_path(websiteSlug)
      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ order_status_option: orderStatusOption }),
      })
      if (response) {
        showToast(notification, 'success', 'Order Status Option Created')
        closeModal()
        fetchOrderStatusOptions()
      } else {
        showToast(notification, 'error', 'Error creating Order Status Option')
      }
    }
  }

  const fetchOrderStatusOptions = () => {
    let params = { website_id: websiteSlug }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    try {
      OrderStatusOptionsService.getOrderStatusOptions(params, csrf, websiteSlug).then((data) => {
        setOrderStatusOptions(data.data.order_status_options)
        setColourOptions(data.data.colour_options)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchOrderStatusOptions()
  }, [])

  return (
    <PrimeReactProvider>
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="px-3 py-1">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <h2>Order Status Options</h2>
              <button
                className="btn btn-outline-primary"
                id="new-order-status-option-button"
                onClick={() => openModal()}
              >
                <i className="fa fa-plus"></i>
                &nbsp;Create Order Status Option
              </button>
            </div>

            <DataTable value={orderStatusOptions}>
              <Column field="name" header="Name"></Column>
              <Column field="colour_name" body={colourBody } header="Colour"></Column>
              <Column
                header="Actions"
                body={(rowData) => (
                  <div>
                    <button
                      className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                      onClick={() => openModal(rowData)}
                    >
                      {' '}
                      <i className="fas fa-edit"></i>
                      &nbsp;Edit
                    </button>
                    <button
                      className="btn btn-outline-danger ml-auto btn-sm mb-2"
                      onClick={() =>
                        confirmDialog({
                          message: 'Are you sure you want to delete this Order Status Option?',
                          header: 'Delete Order Status Option',
                          icon: 'pi pi-exclamation-triangle',
                          accept: async () => {
                            const url = Routes.website_order_status_option_path(websiteSlug, rowData.id)
                            const response = await fetch(url, {
                              method: 'DELETE',
                              headers: standardHeaders,
                            })
                            if (response) {
                              showToast(notification, 'success', 'Order Status Option Deleted')
                              fetchOrderStatusOptions()
                            } else {
                              showToast(notification, 'error', 'Error deleting Order Status Option')
                            }
                          },
                        })
                      }
                    >
                      <i className="fas fa-trash"></i>
                      &nbsp;Delete
                    </button>
                  </div>
                )}
              ></Column>
            </DataTable>

            {isModalOpen && (
              <Dialog
                header={
                  selectedOrderStatusOption ? 'Edit Order Status Option' : 'New Order Status Option'
                }
                visible={isModalOpen}
                style={{ minWidth: '400px' }}
                onHide={closeModal}
                dismissableMask={true}
              >
                <OrderStatusOptionForm
                  orderStatusOption={selectedOrderStatusOption}
                  onSave={handleSave}
                  colourOptions={colourOptions}
                />
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </PrimeReactProvider>
  )
}
