import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { SplitButton } from 'primereact/splitbutton'
import { FilterMatchMode, PrimeReactProvider, FilterService } from 'primereact/api'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { Paginator } from 'primereact/paginator'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'

import { truncateString, time_ago } from '../entries/utils'
import ItemStatus from './ItemStatus'
import showToast from '../shared/ShowToast'
import { TestDriveService } from './testDriveService'
import { DealershipContext, TestDriveProvider } from '../contexts'
import { useFetchDealership, useFetchTestDrive } from '../dataHooks'

const Action = ({ testDrive, onActionComplete, notification }) => {
  const navigate = useNavigate()
  let url = `/dealerships/${testDrive.dealership_id}/test_drives/${testDrive.id}`

  useFetchTestDrive()

  const items = [
    {
      label: 'Duplicate',
      icon: 'fa fa-copy',
      command: () => {
        const duplicateUrl = `${url}/duplicate`
        fetch(duplicateUrl, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }).then((data) => {
          data.json().then((newTestDrive) => {
            url = `/dealerships/${testDrive.dealership_id}/test_drives/${newTestDrive.id}/edit`
            navigate(url)
          })
        })
      }
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        navigate(`${url}/audit_trail`)
      }
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        fetch(url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }).then(() => {
          showToast(notification, 'success', 'Lead cluster deleted!', '')
          onActionComplete()
        })
      }
    }
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => { navigate(`${url}/edit`) }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
    </div >
  )
}

const customLocationFilter = (value, filters) => {
  if (value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value === filters
}

// Use a calendar input to select a date range
const createdAtFilter = (value, filters) => {
  if (filters === '') {
    return true
  }
  return value === filters
}

FilterService.register('custom_location', customLocationFilter)
FilterService.register('created_at', createdAtFilter)

const LastEditBy = (testDrive) => {
  return (
    <div>
      <div>{testDrive.last_edit_by.name}</div>
      <div className="small text-secondary">{time_ago(testDrive.updated_at)}</div>
    </div>
  )
}

const CreatedAt = (testDrive) => {
  return (
    <div>
      <div>{new Date(testDrive.created_at).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
    </div>
  )
}

export const Title = (testDrive) => {
  let url = `/dealerships/${testDrive.dealership_id}/test_drives/${testDrive.id}/edit`
  return (
    <>
      <a href={url}>
        {truncateString(testDrive.title, 50)}
      </a>
      <div className="small text-secondary">
        Created: {time_ago(testDrive.created_at)}
      </div>
      {testDrive?.lead?.id && (
        <div className="small text-secondary">
          <a href={`/dealerships/${testDrive.dealership_id}/leads/${testDrive.lead.id}`}><i className="fa fa-bolt"></i> Lead</a>
        </div>
      )}
    </>
  )
}

export const Contact = (testDrive) => {
  let url = `/dealerships/${testDrive.dealership_id}/contacts/${testDrive.contact_id}`
  return (
    <div>
      <a href={url}>
        {testDrive.contact_name}
      </a>
      <div className="small text-secondary">{testDrive.contact_phone_number}</div>
      <div className="small text-secondary">{testDrive.contact_email}</div>
    </div>
  )
}

export const Status = (testDrive) => {
  return (
    <ItemStatus status={testDrive.status} />
  )
}

export const Location = (testDrive) => {
  return (
    testDrive.location || 'All'
  )
}

const App = () => {
  const { dealership } = useContext(DealershipContext)
  const locations = dealership?.locations
  const dealershipId = dealership?.id
  const notification = useRef(null)
  const [fromCreatedAtDate, setFromCreatedAtDate] = useState('')
  const [toCreatedAtDate, setToCreatedAtDate] = useState('')
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  useFetchDealership()

  const getSeverity = (status) => {
    switch (status) {
      case 'Ready':
        return 'primary'

      case 'Completed':
        return 'success'

      case 'In Progress':
        return 'warning'
    }
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [testDrives, setTestDrives] = useState(null)

  const [statuses] = useState(['Completed', 'Ready', 'In Progress'])

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select Status" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    )
  }

  const createdAtRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null]

    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center mb-2">
          <label htmlFor="from_date" className="flex-shrink-0 mr-2" style={{ minWidth: '50px' }}>After</label>
          <input
            type="date"
            value={from}
            onChange={(e) => {
              setFromCreatedAtDate(e.target.value)
              options.filterApplyCallback([e.target.value, toCreatedAtDate])
            }}
            className="flex-grow-1 p-inputtext p-component p-column-filter"
          />
        </div>
        <div className="d-flex align-items-center">
          <label htmlFor="until_date" className="flex-shrink-0 mr-2" style={{ minWidth: '50px' }}>Before</label>
          <input
            type="date"
            value={to}
            onChange={(e) => {
              setToCreatedAtDate(e.target.value)
              options.filterApplyCallback([fromCreatedAtDate, e.target.value])
            }}
            className="flex-grow-1 p-inputtext p-component p-column-filter"
          />
        </div>
      </div>
    )
  }

  const locationOptionsMap = {
    'Location': locations?.map(location => ({ label: location.name, value: location.id }))
  }

  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '10rem') => {
    const selectedOptions = locationOptionsMap[name]
    return (
      <Dropdown value={options.value} options={selectedOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={`Select ${name}`} className="p-column-filter" style={{ maxWidth: width }} />
    )
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: 'created_at',
    sortOrder: -1,
    filters: {
      'status': { value: null, matchMode: 'equals' },
      'title': { value: null, matchMode: 'contains' },
      'location': { value: null, matchMode: 'contains' },
      'contact_name': { value: null, matchMode: 'contains' },
      'contact_phone_number': { value: null, matchMode: 'contains' },
      'contact_email': { value: null, matchMode: 'contains' },
      'last_edit_by': { value: null, matchMode: 'contains' },
      'created_at': { value: null, matchMode: 'contains' }
    }
  })

  useEffect(() => {
    const createdAtFilter = lazyState.filters.created_at?.value

    if (!createdAtFilter || (Array.isArray(createdAtFilter) && createdAtFilter.every(date => !date))) {
      setFromCreatedAtDate('') // Reset the "from" date state
      setToCreatedAtDate('') // Reset the "to" date state
    }
    loadLazyData()
  }, [lazyState, dealership])

  const loadLazyData = () => {
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }

    if (lazyState.sortField === undefined) {
      lazyState.sortField = 'created_at'
    }

    if (lazyState.sortOrder === undefined) {
      lazyState.sortOrder = -1
    }

    let params = {
      page: page_number,
      rows: lazyState.rows,
      sortField: lazyState.sortField,
      sortOrder: lazyState.sortOrder,
      filters: JSON.stringify(lazyState.filters),
    }

    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    let dealershipId = dealership?.id

    if (dealershipId) {
      let urlParams = new URLSearchParams(window.location.search)
      try {
        TestDriveService.getTestDrives({ params, csrf, dealershipId, urlParams }).then((data) => {
          const testDrivesData = data.data.test_drives.map((testDrive) => ({
            ...testDrive,
            created_at: new Date(testDrive.created_at), // Ensure this is a Date object
          }))
          setTotalRecords(data.data.total_records)
          setTestDrives(testDrivesData)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onFilter = (event) => {
    event['first'] = 0
    setlazyState(event)
  }

  const onSort = (event) => {
    setlazyState(event)
  }

  const onPage = (event) => {
    setlazyState(event)
  }

  const onPageChange = (event) => {
    event['filters'] = lazyState.filters
    setlazyState(event)
  }

  const handleActionComplete = () => {
    loadLazyData()
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)

    setlazyState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        global: { value, matchMode: FilterMatchMode.CONTAINS }
      }
    }))
  }

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <IconField iconPosition="left">
          <InputIcon className="fa fa-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </IconField>
        <div className="ml-2 font-weight-light">Search by Contact details or Stock Number</div>
      </div>
    )
  }
  const header = renderHeader()

  return (
    <PrimeReactProvider>
      <TestDriveProvider>
        <div className="pt-3 container-fluid">
          <Toast ref={notification} />
          <div className="mb-3 d-flex align-items-center">
            <h4 className="m-0">Test Drives</h4>
            <div className="ml-auto">
              <a href={`/dealerships/${dealershipId}/test_drives/new`} className="btn btn-outline-success">
                <i className="fa fa-plus mr-1"></i>
                New Test Drive
              </a>
            </div>
          </div>
          <div className="box">
            <DataTable
              value={testDrives}
              tableStyle={{ minWidth: '50rem' }}
              onPage={onPage}
              onSort={onSort}
              onFilter={onFilter}
              first={lazyState.first}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              rows={lazyState.rows}
              filters={lazyState.filters}
              totalRecords={totalRecords}
              lazy
              style={{ overflow: 'visible' }}
              wrapper={{ style: { overflow: 'visible' } }}
              filterDisplay='row'
              header={header}
            >
              <Column sortable body={Title} field="title" header="Title"></Column>
              <Column sortable body={Contact} field="contact_name" header="Contact"></Column>
              <Column
                field="status"
                header="Status"
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                body={Status}
                filter
                filterElement={statusRowFilterTemplate}
              />
              <Column
                body={Location}
                field="location"
                header="Location"
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                filter
                filterElement={locationRowFilterTemplate}
              />
              <Column
                sortable
                body={CreatedAt}
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                filter
                filterElement={createdAtRowFilterTemplate}
                field="created_at"
                header="Creation Date"
                dataType='date'
              />
              <Column body={LastEditBy} field="last_edit_by" header="Last Edited By" dataType='date'></Column>
              <Column body={(testDrive) => <Action testDrive={testDrive} onActionComplete={handleActionComplete} notification={notification} />} header="Actions"></Column>
            </DataTable>
            <Paginator first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecords} onPageChange={onPageChange} />
          </div>
        </div>
      </TestDriveProvider>
    </PrimeReactProvider >
  )
}

export default App