import { useState } from 'react'

const ExportDestination = ({
  car,
  destination,
  fieldName,
  disabled = false,
  setCar
}) => {
  let [value, setValue] = useState(car[fieldName])
  let [isDisabled, setIsDisabled] = useState(disabled)
  const toggleValue = () => {
    setIsDisabled(true)
    let newValue = !value
    setValue(newValue)
    let carData = {}
    carData[fieldName] = newValue
    let data = {
      car: carData
    }
    setCar({ ...car, [fieldName]: newValue })
    fetch(`/cars/${car.id}`, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(res => res.json()).then(() => {
      setIsDisabled(false)
    })
  }
  return (
    <div className="custom-control custom-switch mr-3">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={value}
        onChange={toggleValue}
        id={`switch-${destination}-${car.id}`}
        disabled={isDisabled}
      />
      <label
        className="custom-control-label"
        htmlFor={`switch-${destination}-${car.id}`}
      >
        {destination}
      </label>
    </div>
  )
}

export default ExportDestination