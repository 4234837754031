import { useState, useEffect } from 'react'
import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
  addNodeUnderParent,
} from 'react-sortable-tree'
import { standardHeaders } from '../entries/utils'
import { useParams } from 'react-router-dom'

const MenuTitle = ({ node, path, setMenuItems, getNodeKey }) => {
  if (node.name === 'Header' || node.name === 'Footer') {
    return <span>{node.name}</span>
  }

  return (
    <>
      <input
        style={{ fontSize: '1.1rem' }}
        className="menu-title-editor mr-2"
        value={node.name}
        onChange={(event) => {
          const name = event.target.value

          setMenuItems((state) => ({
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey,
              newNode: { ...node, name },
            }),
          }))
        }}
      />
      {(node.children?.length === 0 ||
        node.children === null ||
        typeof node.children === 'undefined') && (
        <input
          style={{ fontSize: '1.1rem' }}
          className="menu-url-editor mr-2"
          value={node.url}
          placeholder="Path/URL"
          onChange={(event) => {
            const url = event.target.value

            setMenuItems((state) => ({
              treeData: changeNodeAtPath({
                treeData: state.treeData,
                path,
                getNodeKey,
                newNode: { ...node, url },
              }),
            }))
          }}
        />
      )}
    </>
  )
}

const RemoveButton = ({ node, path, setMenuItems, getNodeKey }) => {
  if (node.name === 'Header' || node.name === 'Footer') {
    return <></>
  }

  return (
    <button
      className="btn btn-outline-danger btn-sm"
      onClick={() =>
        setMenuItems((state) => ({
          treeData: removeNodeAtPath({
            treeData: state.treeData,
            path,
            getNodeKey,
          }),
        }))
      }
    >
      <i className="fa fa-times" />
    </button>
  )
}

const AddButton = ({ path, setMenuItems, getNodeKey }) => {
  return (
    <button
      className="btn btn-outline-primary btn-sm ml-1"
      onClick={() =>
        setMenuItems((state) => ({
          treeData: addNodeUnderParent({
            treeData: state.treeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: {
              name: `new item`,
            },
          }).treeData,
        }))
      }
    >
      <i className="fa fa-plus" />
    </button>
  )
}

const MenuEditor = ({ website }) => {
  let [originalMenuItems, setOriginalMenuItems] = useState(false)
  let [menuItems, setMenuItems] = useState(false)
  let [changed, setChanged] = useState(false)
  let [status, setStatus] = useState(null)
  let [saved, setSaved] = useState(false)

  let { websiteSlug } = useParams()

  useEffect(() => {
    if (!menuItems) {
      fetch(`/websites/${websiteSlug}/menus.json`)
        .then((response) => response.json())
        .then((data) => {
          setOriginalMenuItems(data)
          setMenuItems({ treeData: data })
        })
    }
  }, [])

  useEffect(() => {
    if (changed === true) return
    if (!menuItems) return
    if (originalMenuItems === menuItems.treeData) return

    setChanged(true)
  }, [menuItems])

  if (!menuItems) {
    return <div className="px-3">Loading...</div>
  }

  const onSave = () => {
    setStatus('loading')

    // Recursive function to remove 'expanded' property from each node
    const transformTreeData = (nodes) => {
      return nodes.map((node) => {
        const { expanded, children, ...rest } = node // Destructure to remove 'expanded' and keep the rest
        const newNode = { ...rest }
        if (children && children.length > 0) {
          newNode.dropdownItems = transformTreeData(children) // Recursively process and rename 'children'
        }
        return newNode
      })
    }

    const headerData = transformTreeData(
      menuItems.treeData.filter((item) => item.name === 'Header')[0].children
    )
    const footerData = transformTreeData(
      menuItems.treeData.filter((item) => item.name === 'Footer')[0].children
    )

    fetch(`/websites/${websiteSlug}`, {
      method: 'PATCH',
      body: JSON.stringify({
        website: {
          navigation: {
            header: headerData,
            footer: footerData,
          },
        },
      }),
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setChanged(false)
        setStatus(false)
        setSaved(true)
      })
  }

  const getNodeKey = ({ treeIndex }) => treeIndex

  return (
    <div className="px-3">
      <h4>Menu Editor</h4>
      <p>Allows editing of the website header & footer</p>
      {saved && status !== 'loading' && <div className="text-success">Saved! Changes are live</div>}
      {changed && (
        <button
          className={'btn btn-outline-secondary' + (status === 'loading' ? ' disabled' : '')}
          onClick={onSave}
          id="save-menu"
        >
          {status === 'loading' && (
            <>
              <i className="fa fa-spinner fa-spin mr-1" /> Saving...
            </>
          )}
          {!status && 'Save'}
        </button>
      )}
      <div>
        <SortableTree
          treeData={menuItems.treeData}
          onChange={(e) => {
            setMenuItems({ treeData: e })
          }}
          isVirtualized={false}
          generateNodeProps={({ node, path }) => ({
            title: (
              <MenuTitle
                node={node}
                path={path}
                setMenuItems={setMenuItems}
                getNodeKey={getNodeKey}
              />
            ),
            buttons: [
              <RemoveButton
                node={node}
                path={path}
                setMenuItems={setMenuItems}
                getNodeKey={getNodeKey}
              />,
              <AddButton path={path} setMenuItems={setMenuItems} getNodeKey={getNodeKey} />,
            ],
          })}
        />
      </div>
    </div>
  )
}

export default MenuEditor
