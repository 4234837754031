export const carGradeBadgeClass = (grade) => {
  switch (grade) {
    case 'A':
      return 'badge-success'
    case 'B':
      return 'badge-primary'
    case 'C':
      return 'badge-warning'
    case 'D':
      return 'badge-danger'
    case 'E':
      return 'badge-danger'
    case 'F':
      return 'badge-danger'
    default:
      return 'badge-secondary'
  }
}