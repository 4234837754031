import { useRouteError } from 'react-router-dom'

const Error: React.FC = () => {
  const error = useRouteError()
  console.log(error)
  return (
    <div className="container  d-flex justify-content-center align-items-center">
      <div className="text-center">
        <div className="mt-5">
          <h1 className="display-4">Oops!</h1>
          <p>Something went wrong. Please try refreshing the page.</p>
          <p>If the error persists, please contact our team.</p>
        </div>
        <button className="btn btn-primary" onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    </div>
  )
}

export default Error
