import { useContext, useState } from "react"
import { useDroppable, useDraggable } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { humanize } from '../../entries/utils'
import { formatSimpleDuration } from '../../entries/timeAgo'
import LeadSourceBadge from '../LeadSourceBadge'
import { updateLeadAPI } from '../../leads/utils'
import { KanbanContext, LeadClusterContext } from "../../contexts"
import SlideIn from '../../leadCluster/SlideIn'

const RequiresApproval = ({ leadCluster }) => {
  let [loading, setLoading] = useState(false)
  let { refreshData } = useContext(KanbanContext)

  const toggle = async (approved) => {
    setLoading(true)
    let data = {
      lead_cluster: {
        approved: approved
      }
    }

    await updateLeadAPI(leadCluster.id, data)
      .then(res => res.json())
      .then(() => {
        refreshData()
      })
    setLoading(false)
  }

  const Loading = () => (
    <div>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  )

  return (
    <div className="p-2 d-flex align-items-center border-bottom">
      <div className="text-center font-weight-bold mr-auto">APPROVE:</div>
      <div className="btn-group">
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => toggle(true)}
        >
          {loading ? (
            <Loading />
          ) : (
            <span>Approve</span>
          )}
        </button>
      </div>
    </div>
  )
}

const SlideInComponent = ({ cluster, buttonComponent }) => {

  let [leadCluster, setLeadCluster] = useState(cluster)

  return (
    <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
      <SlideIn
        leadCluster={leadCluster}
        buttonComponent={buttonComponent}
      />
    </LeadClusterContext.Provider>
  )
}

const KanbanItem = ({
  leadCluster,
  index,
  parent,
}) => {

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: leadCluster.id,
    data: {
      id: leadCluster.id,
      title: leadCluster.title,
      index: index,
      parent: parent,
    },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: 100000,
    opacity: leadCluster.loading ? 0.5 : 1,
  }

  const renderButton = ({ setVisible }) => (
    <div
      className="btn btn-outline-primary btn-sm"
      onClick={() => setVisible(true)}
    >
      View
    </div>
  )

  return (
    <div
      className={"border mb-2 rounded " + (leadCluster.requires_approval ? 'bg-light-red' : 'bg-white')}
      style={style}
      ref={setNodeRef}
    >
      <div
        className="border-bottom p-2"
        {...listeners}
        {...attributes}
      >
        <div className="d-flex">
          <h6 dangerouslySetInnerHTML={{ __html: leadCluster.title }} />
          <div className="text-secondary small ml-auto">
            {formatSimpleDuration(new Date(leadCluster.created_at), new Date())} ago
          </div>
        </div>
      </div>
      {leadCluster.car && leadCluster.car.make && (
        <div className="p-2 border-bottom d-flex align-items-center">
          <img src={leadCluster.car.primary_image_url} height="40" className="rounded" loading="lazy" />
          <div className="ml-2 text-secondary">
            <small>{leadCluster.car.year} {leadCluster.car.make} {leadCluster.car.model}</small>
            <div className="small">{leadCluster.car.stocknum}</div>
            <div className="small">${leadCluster.car.price.toLocaleString()}</div>
          </div>
        </div>
      )}
      {leadCluster.user && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="badge badge-info">
            {leadCluster.user?.name}
          </div>
        </div>
      )}
      {leadCluster.requires_approval && (
        <RequiresApproval leadCluster={leadCluster} />
      )}
      <div className="p-2 d-flex align-items-center">
        <div>
          <div className="badge badge-sm badge-secondary">
            {leadCluster.category}
          </div>
          <div>
            <LeadSourceBadge lead={leadCluster} />
          </div>
        </div>
        <div className="ml-auto">
          <SlideInComponent
            cluster={leadCluster}
            buttonComponent={renderButton}
          />
        </div>
      </div>
    </div>
  )
}

export default function KanbanLane({ title, items, colour_class_name }) {
  const { setNodeRef } = useDroppable({
    id: title,
  })

  return (
    <div style={{ width: 300, minWidth: 300 }}>
      <div className="border rounded my-3 bg-white mr-2 mb-3">
        <div
          ref={setNodeRef}
        >
          <div className="heading border-bottom p-2">
            <div className={`badge badge-${colour_class_name}`}>{humanize(title)}</div>
          </div>
          <div className="p-2">
            {items.map((leadCluster, index) => (
              <KanbanItem
                leadCluster={leadCluster}
                key={leadCluster?.id}
                index={index}
                parent={title}
              />
            ))}
          </div>
          <div className="border-top p-2">
            <div className="text-center small text-secondary">
              {items.length} {items.length === 1 ? 'lead' : 'leads'} total
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
