/**
 * Brought over from the React Query PR:
 * https://github.com/dealerstudio/td_dashboard/pull/3009/files
 * It might be nice to make our own full-fledged `useQuery` and `useMutation` hooks
 * based on Tanstack, since we can't install the package.
 **/

/**
 * Handle the response from a `fetch` and forcefully throw an appropriate error
 * if the response has an issue
 **/
export async function handleResponse<T = unknown>(res: Response): Promise<T> {
  if (!res.ok) {
    const json = await res.json()
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number
      }
      error.status = res.status
      throw error
    } else {
      throw new Error(`An unexpected error occurred${res?.statusText ? `: ${res.statusText}` : ''}`)
    }
  }

  if (res.status === 204) {
    // avoid throwing an error for NO CONTENT delete actions
    return res.statusText as T
  }
  return res.json()
}
