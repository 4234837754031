import { useContext } from 'react'
import { LeadContext } from '../contexts'

const CarsAlsoViewed = ({ lead }) => {
  const { carsAlsoViewed } = lead
  return (
    <>
      <div className="small text-secondary text-center mb-1 mt-3">
        Customer Also Viewed:
      </div>
      <div className="row mb-2">
        {carsAlsoViewed.slice(0, 3).map(car => (
          <div key={car.id} className="col-md-4">
            <div className="box p-2 d-flex">
              <a href={`/cars/${car.slug}`}>
                <img src={car.primary_image_url} height="50" className="mr-2 rounded" alt={`${car.make} ${car.model}`} />
              </a>
              <div className="text-secondary">
                <small>{car.make} {car.model}</small>
                <div className="small">{(car.price || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default CarsAlsoViewed