import { useEffect } from 'react'
import DragAndDrop from './DragAndDrop'

import {
  setModuleLayouts,
  getAnalyticsBlock,
  getDealership,
  getWebsite,
  automateCampaignDeletion,
  automateAddCampaigns,
} from './utils'
import Tabs from './Tabs'
import { useParams } from 'react-router-dom'
import {
  useAnalyticsDashboard,
  useConfig,
  useItems,
  usePreviewData,
  useTabs,
} from './contexts/hooks'
import Header from './Header'

const AnalyticsDashboard: React.FC = () => {
  const { level, canView, editModeEnabled } = useAnalyticsDashboard()
  const { config } = useConfig()
  const { selectedTab, tabLayouts, tabTitles } = useTabs()
  const { handleUpdateItems } = useItems()
  const { setEditingPreviewData } = usePreviewData()
  const params = useParams()

  const dealershipSlug =
    params.dealershipSlug || getDealership()?.slug || getWebsite()?.dealershipId.toString()

  const multipleTabs: boolean = Object.keys(tabLayouts).length > 1 && tabTitles.length > 1

  useEffect(() => {
    if (tabLayouts && selectedTab in tabLayouts) {
      handleUpdateItems(tabLayouts[selectedTab])
    }
  }, [tabLayouts, selectedTab])

  // useEffect that runs when the component mounts
  useEffect(() => {
    // If there is no data in the block, set the current items to the block
    const blockData = getAnalyticsBlock().data
    if (blockData && Object.keys(blockData).length === 0 && blockData.constructor === Object) {
      setModuleLayouts(tabLayouts, tabTitles, config)
    }
    if (dealershipSlug) {
      automateCampaignDeletion(dealershipSlug)
      if (level !== 'Manufacturer') {
        automateAddCampaigns(dealershipSlug)
      }
    }
  }, [])

  // Set preview data to null when exiting edit mode
  useEffect(() => {
    if (!editModeEnabled) {
      setEditingPreviewData(null)
    }
  }, [editModeEnabled])

  return (
    <>
      {canView ? (
        <div className="p-4">
          <Header />
          {multipleTabs || editModeEnabled ? (
            <div className="d-flex align-items-center py-2">
              <Tabs />
            </div>
          ) : null}
          <DragAndDrop />
        </div>
      ) : null}
    </>
  )
}

export default AnalyticsDashboard
