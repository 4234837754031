export const PushNotificationService = {
  getPushNotifications(params, csrf) {
    const queryParams = params
      ? Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      : ''

    return fetch(`/admin/push_notifications.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      credentials: 'same-origin'
    }).then((res) => res.json())
  }
}