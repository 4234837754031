import { useContext } from 'react'
import { LeadClusterContext } from '../contexts';
import SlideIn from './SlideIn'

const LeadEventStats = () => {
  const { leadCluster } = useContext(LeadClusterContext)

  if (!leadCluster) return <></>

  const Wrapper = ({ setVisible, children }) => (
    <div className="btn btn-sm mr-1 position-relative event-stat" onClick={() => setVisible(true)}>
      {children}
    </div>
  )

  return (
    <>
      <SlideIn leadCluster={leadCluster} buttonComponent={({ setVisible }) => (
        <>
          <Wrapper setVisible={setVisible}>
            {leadCluster.event_counts?.email && <span className="event-count">{leadCluster.event_counts.email}</span>}
            <i className="fa fa-envelope mr-1" />
          </Wrapper>
          <Wrapper setVisible={setVisible}>
            {leadCluster.event_counts?.phone && <span className="event-count">{leadCluster.event_counts.phone}</span>}
            <i className="fa fa-phone mr-1" />
          </Wrapper>
          <Wrapper setVisible={setVisible}>
            {leadCluster.event_counts?.sms && <span className="event-count">{leadCluster.event_counts.sms}</span>}
            <i className="fa fa-comment mr-1" />
          </Wrapper>
          <Wrapper setVisible={setVisible}>
            <i className="fa fa-car mr-1" />
          </Wrapper>
        </>
      )} />
    </>
  )
}

export default LeadEventStats