import { useParams } from 'react-router-dom'

import Loading from '../../Loading'
import { SpecificationsFormData, SpecificationsForm } from './SpecificationsForm'
import { fetchOnClient, useQuery } from '../../tanstackPlaceholderUtils'

const SpecificationsPage = () => {
  const { modelSlug } = useParams()
  const { data, loading, error } = useQuery<SpecificationsFormData>({
    query: fetchOnClient,
    queryKey: `/models/${modelSlug}.json`,
  })

  const manufacturerSlug = data?.manufacturer_name?.toLowerCase().replace(/ /g, '-')
  const modelName = data?.name

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }
  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }
  return (
    <div className="w-100 p-3">
      <div className="container">
        <h4>Model Specifications</h4>
        <p>Add specifications related to the {modelName} model in the below table.</p>
        <a
          href={`/manufacturers/${manufacturerSlug}/model_specification_categories`}
          className="btn btn-success"
        >
          Add/Edit Categories
        </a>
        <div className="row my-5">
          <div style={{ maxWidth: '1200px' }} className="col-12">
            <SpecificationsForm modelSlug={modelSlug} defaultValues={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecificationsPage
