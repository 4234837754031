import { useState, useEffect } from 'react'
import { standardHeaders } from "../entries/utils"
import { useParams } from "react-router-dom"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const SearchPages = () => {
  let [pages, setPages] = useState([])
  let { websiteSlug } = useParams()

  useEffect(() => {
    fetch(`/websites/${websiteSlug}/search_pages`, {
      method: 'GET',
      headers: standardHeaders
    }).then(res => res.json()).then(data => {
      setPages(data)
    })
  }, [])

  return (
    <div className="m-3 box">
      <div className="p-3">
        Search Pages
      </div>

      <DataTable
        value={pages}
      >
        <Column field="title" header="Title" />
        <Column field="path" header="URL" />
      </DataTable>
    </div>
  )
}

export default SearchPages