import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { humanize } from '../entries/utils'

function leadClass(status) {
  switch (status) {
    case 'lost':
      return 'badge-danger';
    case 'converted':
    case 'contacted':
      return 'badge-success';
    default:
      return 'badge-warning';
  }
}

function EmailInfo({ email }) {
  return (
    <div className="px-3 py-1">
      <div className="box bg-white rounded p-3">
        <div className="row align-items-center">
          <div className="d-flex px-3 w-100">
            <div className="mr-3">
              <i className={`fa fa-envelope fa-lg ${email.sent_at ? 'text-success' : 'text-secondary'}`}></i>
            </div>
            <div>
              {email.sent_at ? (
                <p className="m-0">Email sent to customer {formatDistanceToNow(new Date(email.sent_at))}</p>
              ) : email.send_at && email.send_at > new Date() ? (
                <div className="m-0">
                  Email will be sent to customer {timeAgoInWords(email.send_at)} from now if lead remains in status
                  <span className={`badge ${leadClass(email.auto_response.lead_status)}`}>
                    {humanize(email.auto_response.lead_status)}
                  </span>
                </div>
              ) : null}
              {email.subject ? (
                <>
                  <small className="mr-2">Email Subject: {email.subject}</small>
                  <span className="badge badge-secondary">{humanize(email.status)}</span>
                </>
              ) : (
                <small>
                  Email Subject: {email.auto_response?.subject}
                  Email from: {email.auto_response?.from_address}
                </small>
              )}
              {email.error && (
                <div className="badge badge-danger">Email failed to send: {email.error}</div>
              )}
            </div>
            <div className="ml-auto align-items-center">
              {email.email_body && (
                <a href={`/dealerships/${window.dealer_slug}/emails/${email.id}`} className="btn btn-outline-primary">
                  View
                </a>
              )}
              {!email.sent_at && email.send_at && email.send_at > new Date() && (
                <a href={`/dealerships/${window.dealer_slug}/emails/${email.id}`} method="delete" className="btn btn-outline-primary">
                  Cancel
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailInfo;