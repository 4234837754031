import { Panel } from 'primereact/panel'
import moment from 'moment-timezone'

const LeadClusterStateChanges = ({ stateChanges }) => {
  return (
    <Panel header="Timeline" toggleable>
      <div className="lead-cluster-state-timeline">
        {stateChanges.map((stateChange, index) => (
          <div key={index} className="timeline-event">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              {(() => {
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                let formattedTime = moment.utc(stateChange.created_at)
                  .tz(timezone)
                  .format('DD/MM/YYYY, h:mm:ss a')
                return (
                  <>
                    <div className="timeline-date">{formattedTime}</div>
                    <h5 className="timeline-state">
                      <span className={`badge ${stateChange.badge_colour}`}>
                        {stateChange.new_state}
                      </span>
                    </h5>
                    <p className="timeline-user">{stateChange.user.name}</p>
                  </>
                )
              })()}
            </div>
          </div>
        ))}
      </div>
    </Panel>
  )
}

export default LeadClusterStateChanges
