import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { standardHeaders } from '../../entries/utils'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { handleNotificationEvent } from '../utils'
import { Dropdown } from 'primereact/dropdown'
import { linkFacebookAccount } from '../utils'
import { CurrentUserContext, DealershipContext } from '../../contexts'
import * as Routes from '../../../routes'
import { SetState, User } from '../types'
import Loading from '../../Loading'
import { useFetchDealership } from '../../dataHooks'
import { Dialog } from 'primereact/dialog'
import EditAdAccount from './EditAdAccount'

type AdAccount = {
  id: number
  name: string
  externalId: string
  dealershipId?: number
  dealershipName?: string
  dealershipSlug?: string
  userName?: string
  userIsAuthorised: boolean
}

type AdAccounts = {
  assignedAccounts: AdAccount[]
  unassignedAccounts: AdAccount[]
}

type Dealership = { id: number; name: string; slug?: string; city?: string; city_slug?: string }

type Provider = 'Facebook' | 'Google'

export type Campaign = {
  id: string
  name: string
  websiteId: string | null
  websiteName: string | null
}

// Define the type for the campaigns state
export type CampaignsState = {
  [accountId: string]: Campaign[]
}

export type Website = {
  id: string
  name: string
  slug?: string
  autoAssign: boolean
}

async function fetchAdAccounts(
  provider: Provider,
  dealershipSlug: string | undefined,
  notification: React.MutableRefObject<Toast>
): Promise<AdAccounts> {
  try {
    const route = dealershipSlug
      ? `/dealerships/${dealershipSlug}/${provider.toLowerCase()}_ads.json`
      : Routes.ad_accounts_social_ad_accounts_path()

    const response = await fetch(route)
    const data = await response.json()
    return data?.adAccounts
  } catch (error) {
    if (error.status === 422 && error.message === 'Facebook token has expired') {
      handleNotificationEvent(notification, error.message, 'error')
      return {} as AdAccounts
    }
    console.error('Error:', error)
    return {} as AdAccounts
  }
}

async function bulkUpdate(
  adAccounts: AdAccount[],
  campaigns: CampaignsState, // Add campaigns
  autoAssignedWebsites: Website[], // Add auto-assigned websites
  notification: React.MutableRefObject<Toast>,
  isAdmin: boolean,
  isDealership: boolean,
  dealershipId: number,
  provider: Provider
): Promise<void> {
  const authorisedAdAccounts = isAdmin
    ? adAccounts
    : adAccounts.filter((adAccount) => adAccount.userIsAuthorised)

  const allCampaigns = Object.values(campaigns).flat()
  try {
    const URL = isDealership
      ? `/dealerships/${dealershipId}/social_ad_accounts/bulk_update.json`
      : Routes.bulk_update_social_ad_accounts_path()
    const response = await fetch(URL, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        ad_accounts: authorisedAdAccounts,
        campaigns: allCampaigns,
        auto_assigned_websites: autoAssignedWebsites,
        provider,
      }),
    })
    const data = await response.json()
    if (response.ok) {
      handleNotificationEvent(notification, data.message, 'success')
      if (data.adAccounts) {
        data.adAccounts.any((account: any) => {
          const account_updated = adAccounts.find((adAccount) => adAccount.externalId === account.external_id)
          if (account_updated) {
            account_updated.id = account.id
          }
        }
        )
      }
    } else handleNotificationEvent(notification, data.message, 'error')
  } catch (error) {
    console.error('Error:', error)
  }
}

const DealershipDropdown: React.FC<{
  externalId: string
  updateAdAccountDealership: (externalId: string, dealership: Dealership) => void
  dealerships: Dealership[]
}> = ({ externalId, updateAdAccountDealership, dealerships }) => {
  const handleChange = (event: { value: Dealership }) => {
    updateAdAccountDealership(externalId, event.value)
  }

  return (
    <Dropdown
      placeholder="Set Dealership"
      onChange={handleChange}
      options={dealerships?.map((dealership) => ({
        label: dealership.name,
        value: { id: dealership.id, name: dealership.name, slug: dealership.slug },
      }))}
    ></Dropdown>
  )
}

const Table: React.FC<{
  assignedAdAccounts: AdAccount[]
  unassignedAdAccounts: AdAccount[]
  isDealership: boolean
  dealershipId: number
  updateAdAccountDealership: (externalId: string, dealership: Dealership) => void
  unAssignAdAccount: (externalId: string) => void
  assignAdAccount: (externalId: string) => void
  handleDeleteAdAccount: (adAccountId: number) => void
  notification: MutableRefObject<Toast>
  currentUser: User
  provider: Provider
  campaigns: CampaignsState
  setCampaigns: SetState<CampaignsState>
  autoAssignedWebsites: Website[]
  setAutoAssignedWebsites: SetState<Website[]>
}> = ({
  assignedAdAccounts,
  unassignedAdAccounts,
  isDealership,
  dealershipId,
  updateAdAccountDealership,
  unAssignAdAccount,
  assignAdAccount,
  notification,
  currentUser,
  provider,
  campaigns,
  setCampaigns,
  autoAssignedWebsites,
  setAutoAssignedWebsites,
}) => {
    const [dealerships, setDealerships] = useState<Dealership[]>([])
    const [isSaveDialogVisible, setIsSaveDialogVisible] = useState<boolean>(false)

    const userHasAtLeastOneAuthorisation = assignedAdAccounts
      ?.concat(unassignedAdAccounts)
      .some((adAccount) => adAccount.userIsAuthorised)

    useEffect(() => {
      if (currentUser?.admin) {
        const fetchDealerships = async () => {
          try {
            const URL = `${Routes.dealership_ids_dealerships_path()}.json`
            const response = await fetch(URL)
            const data: Dealership[] = await response.json()
            setDealerships(data)
          } catch (error) {
            console.error('Error fetching dealerships:', error)
          }
        }

        fetchDealerships()
      }
    }, [currentUser])
    return (
      <>
        <div className="row">
          <div className="col-12">
            {currentUser?.admin || userHasAtLeastOneAuthorisation ? (
              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setIsSaveDialogVisible(true)}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}
            <h3 className="text-success">Assigned Ad Accounts</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Ad Acccount Name</th>
                  <th>Dealership</th>
                  <th>Authorised User</th>
                </tr>
              </thead>
              <tbody>
                {assignedAdAccounts && assignedAdAccounts.length > 0 ? (
                  assignedAdAccounts.map(
                    ({
                      id,
                      name,
                      externalId,
                      dealershipName,
                      dealershipId,
                      userName,
                      userIsAuthorised,
                      dealershipSlug,
                    }) => (
                      <tr key={externalId}>
                        <td>
                          {userIsAuthorised || currentUser?.admin ? (
                            <EditAdAccount
                              id={id}
                              name={name}
                              dealershipName={dealershipName}
                              dealershipId={dealershipId}
                              dealershipSlug={dealershipSlug}
                              provider={provider}
                              externalId={externalId}
                              userIsAuthorised={userIsAuthorised}
                              campaigns={campaigns}
                              setCampaigns={setCampaigns}
                              autoAssignedWebsites={autoAssignedWebsites}
                              setAutoAssignedWebsites={setAutoAssignedWebsites}
                            />
                          ) : (
                            name
                          )}
                        </td>
                        <td>
                          <div>
                            {dealershipName}
                            {/* Only show this X button if we're in admin view */}
                            {currentUser?.admin || userIsAuthorised ? (
                              <div
                                className="btn fas fa-times-circle"
                                style={{ color: 'red' }}
                                onClick={() => unAssignAdAccount(externalId)}
                              ></div>
                            ) : null}
                          </div>
                        </td>
                        <td>{userName}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No linked ad accounts
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {unassignedAdAccounts && unassignedAdAccounts.length > 0 ? (
          <div className="row mb-4">
            <div className="col-12">
              <h3 className="text-danger">Unassigned Ad Accounts</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Ad Acccount Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {unassignedAdAccounts.map(({ externalId, name }) => (
                    <tr key={externalId}>
                      <td>{name} </td>
                      <td>
                        {isDealership ? (
                          <a
                            className="mr-4"
                            style={{ cursor: 'pointer' }}
                            onClick={() => assignAdAccount(externalId)}
                          >
                            Assign
                          </a>
                        ) : (
                          <DealershipDropdown
                            externalId={externalId}
                            updateAdAccountDealership={updateAdAccountDealership}
                            dealerships={dealerships}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {/*  Only show the save button if admin or if the user has at least one authorised ad account
      There is a check to only update accounts for the authorised user */}
        {currentUser?.admin || userHasAtLeastOneAuthorisation ? (
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-primary float-right"
                onClick={() => setIsSaveDialogVisible(true)}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
        <Dialog
          header={`Save Changes?`}
          visible={isSaveDialogVisible}
          style={{ width: '50vw', textAlign: 'center' }}
          onHide={() => setIsSaveDialogVisible(false)}
          dismissableMask
        >
          <i
            className="far fa-exclamation-triangle"
            style={{ color: '#fcc600', fontSize: '22px' }}
          ></i>
          <h5 className="py-1 ">
            Unassigned accounts will be deleted, along with any associated campaigns. Are you sure you
            want to proceed?
          </h5>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <button
              className="btn btn-primary mt-2 "
              onClick={() => setIsSaveDialogVisible(false)}
              style={{ width: '150px' }}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger mt-2 "
              onClick={() => {
                setIsSaveDialogVisible(false)
                bulkUpdate(
                  assignedAdAccounts?.concat(unassignedAdAccounts),
                  campaigns,
                  autoAssignedWebsites,
                  notification,
                  currentUser?.admin,
                  isDealership,
                  dealershipId,
                  provider
                )
              }}
              style={{ width: '150px' }}
            >
              Save
            </button>
          </div>
        </Dialog>
      </>
    )
  }

const SocialAdAccounts: React.FC<{ provider: Provider; isDealership: boolean }> = ({
  provider,
  isDealership = false,
}) => {
  const currentUser = useContext(CurrentUserContext) as User
  const { dealership } = useContext(DealershipContext) as { dealership: Dealership }
  const notification = useRef<Toast>(null)
  const { dealershipSlug } = useParams<{ dealershipSlug: string }>()
  const [assignedAdAccounts, setAssignedAdAccounts] = useState<AdAccount[]>([])
  const [unassignedAdAccounts, setUnassignedAdAccounts] = useState<AdAccount[]>([])
  const [campaigns, setCampaigns] = useState<CampaignsState>({})
  const [autoAssignedWebsites, setAutoAssignedWebsites] = useState<Website[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  useFetchDealership()

  const updateAdAccountDealership = (externalId: string, dealership: Dealership): void => {
    const currentAdAccount = unassignedAdAccounts?.find(
      (adAccount) => adAccount.externalId === externalId
    )
    if (!currentAdAccount) return

    setUnassignedAdAccounts((prevState) =>
      prevState.filter((adAccount) => adAccount.externalId !== externalId)
    )
    setAssignedAdAccounts((prevState) => [
      ...prevState,
      {
        ...currentAdAccount,
        dealershipId: dealership.id,
        dealershipName: dealership.name,
        dealershipSlug: dealership.slug,
        userName: currentUser?.name,
        userIsAuthorised: true,
      },
    ])
  }

  const unAssignAdAccount = (externalId: string): void => {
    setAssignedAdAccounts((prevState) =>
      prevState.filter((adAccount) => adAccount.externalId !== externalId)
    )
    setUnassignedAdAccounts((prevUnassignedAccounts) =>
      [
        ...prevUnassignedAccounts,
        ...assignedAdAccounts
          .filter((adAccount) => adAccount.externalId === externalId)
          .map((adAccount) => ({
            ...adAccount,
            dealershipId: null,
            dealershipName: null,
            dealershipSlug: null,
            userName: currentUser?.name,
            userIsAuthorised: true,
          })),
      ].sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const assignAdAccount = (adAccountId: string): void => {
    setUnassignedAdAccounts((prevState) =>
      prevState.filter((adAccount) => adAccount.externalId !== adAccountId)
    )
    setAssignedAdAccounts((prevAssignedAccounts) =>
      [
        ...prevAssignedAccounts,
        ...unassignedAdAccounts
          .filter((adAccount) => adAccount.externalId === adAccountId)
          .map((adAccount) => ({
            ...adAccount,
            dealershipId: dealership.id,
            dealershipName: dealership.name,
            dealershipSlug: dealership.slug,
            userName: currentUser?.name,
            userIsAuthorised: true,
          })),
      ].sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const handleDeleteAdAccount = async (adAccountId: number) => {
    try {
      const URL = Routes.dealership_social_ad_account_path(dealership.id, adAccountId)
      const response = await fetch(URL, {
        method: 'DELETE',
        headers: standardHeaders,
      })
      const data = await response.json()
      if (response.ok) {
        handleNotificationEvent(notification, data.message, 'success')
        setUnassignedAdAccounts((prevUnassignedAccounts) =>
          prevUnassignedAccounts.filter((adAccount) => adAccount.id !== adAccountId)
        )
      } else {
        handleNotificationEvent(notification, data.message, 'error')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  // Fetch the ad accounts on initial page load
  useEffect(() => {
    setLoading(true)
    const loadAdAccounts = async () => {
      const accounts = await fetchAdAccounts(provider, dealershipSlug, notification)
      setAssignedAdAccounts(accounts?.assignedAccounts?.sort((a, b) => a.name.localeCompare(b.name)))
      setUnassignedAdAccounts(
        accounts?.unassignedAccounts?.sort((a, b) => a.name.localeCompare(b.name))
      )
      setLoading(false)
    }
    loadAdAccounts()
  }, [])

  return (
    <>
      <Toast ref={notification} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-6">
            <h1>{provider} Ad Accounts</h1>
          </div>
          <div className="col-6 m-0">
            {provider === 'Facebook' ? (
              <div
                className="float-right btn btn-outline-primary btn-sm"
                onClick={() => linkFacebookAccount(dealershipSlug)}
              >
                <span>
                  <i className="fab fa-facebook-f mr-2 "></i>Link Facebook Account
                </span>
              </div>
            ) : provider === 'Google' ? (
              <div className="float-right">
                <a
                  className="btn btn-outline-primary btn-sm"
                  href={Routes.authorize_dealership_social_ad_accounts_path(dealershipSlug)}
                >
                  <i className="fab fa-google mr-2"></i>Link Google Account
                </a>
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <Table
            assignedAdAccounts={assignedAdAccounts}
            unassignedAdAccounts={unassignedAdAccounts}
            isDealership={isDealership}
            dealershipId={dealership?.id}
            updateAdAccountDealership={updateAdAccountDealership}
            unAssignAdAccount={unAssignAdAccount}
            assignAdAccount={assignAdAccount}
            handleDeleteAdAccount={handleDeleteAdAccount}
            notification={notification}
            currentUser={currentUser}
            provider={provider}
            campaigns={campaigns}
            setCampaigns={setCampaigns}
            autoAssignedWebsites={autoAssignedWebsites}
            setAutoAssignedWebsites={setAutoAssignedWebsites}
          />
        )}
      </div>
    </>
  )
}

export default SocialAdAccounts
