import React, { createContext, useState, ReactNode, useContext } from 'react'
import { Dealership, Level, User } from '../types'
import { CurrentUserContext, DealershipContext } from '../../contexts'
import { useFetchDealership } from '../../dataHooks'

type AnalyticsDashboardContextType = {
  canView: boolean
  level: Level | undefined
  editModeEnabled: boolean
  toggleEditMode: () => void
  currentUser: User
  dealership: Dealership
}

export const AnalyticsDashboardContext = createContext<AnalyticsDashboardContextType>({
  editModeEnabled: false,
  toggleEditMode: () => {},
  canView: false,
  level: undefined,
  currentUser: {
    id: 0,
    email: '',
    admin: false,
    name: '',
    position: '',
    slug: '',
    avatar_url: '',
    dealerships: {},
  },
  dealership: {
    id: '',
    name: '',
    slug: '',
  },
})

// For Analytics Dashboard
const rootElement = document.getElementById('app_root')
const isAnalytics = rootElement?.hasAttribute('analytics_block')

let canView = false
let level: Level | undefined = undefined

if (rootElement && isAnalytics) {
  canView = rootElement.getAttribute('canView') === 'true'
  if (rootElement.getAttribute('Manufacturer')) {
    level = 'Manufacturer'
  } else if (rootElement.getAttribute('Dealership')) {
    level = 'Dealership'
  } else if (rootElement.getAttribute('Website')) {
    level = 'Website'
  }
}

export const AnalyticsDashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const currentUser = useContext(CurrentUserContext) as User

  useFetchDealership()
  const { dealership } = useContext(DealershipContext) as { dealership: Dealership }

  const toggleEditMode = (): void => {
    setEditModeEnabled(!editModeEnabled)
  }

  return (
    <AnalyticsDashboardContext.Provider
      value={{
        canView,
        level,
        editModeEnabled,
        toggleEditMode,
        currentUser,
        dealership,
      }}
    >
      {children}
    </AnalyticsDashboardContext.Provider>
  )
}
