import { useState, useEffect } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Select from "react-select"
import { Toggle } from '../settings/leadSettings'
import { CopyableText } from '../shared/CopyableText'

const Name = (rowData) => {
  return (
    <div>
      {rowData.name}
      <div className="text-secondary small">
        {rowData.location?.name}
      </div>
      {rowData.photos_only && rowData.accepts_cars && (
        <div className="badge badge-danger badge-sm">Photos Only</div>
      )}
    </div>
  )
}

const Actions = (rowData) => {
  return (
    <div>
      <a
        href={`/carsales_accounts/${rowData.id}/edit`}
        className="btn btn-sm btn-outline-primary"
      >
        Edit
      </a>
    </div>
  )
}

const Billing = (rowData) => {

  return (
    <Toggle
      attributeName="billing"
      objectType="carsales_account"
      rowData={rowData}
      url={`/carsales_accounts/${rowData.id}`}
    />
  )
}

const AcceptCars = (rowData) => {
  return (
    <Toggle
      attributeName="accept_cars"
      objectType="carsales_account"
      rowData={rowData}
      url={`/carsales_accounts/${rowData.id}`}
    />
  )
}

const CarsalesId = (rowData) => (
  <CopyableText text={rowData.carsales_id} />
)

const filters = [
  { label: "All", value: null },
  { label: "Carsales Leads", value: "carsales_leads" },
  { label: "Carsales Inventory", value: "carsales_inventory" },
  { label: "Unmatched", value: "unmatched" },
]

const CarsalesAccounts = () => {

  let [loading, setLoading] = useState(true)
  let [carsalesAccounts, setCarsalesAccounts] = useState([])
  let [selectedFilter, setSelectedFilter] = useState(null)

  useEffect(() => {
    setLoading(true)
    let url = '/carsales_accounts.json'

    if (selectedFilter) {
      url = `/carsales_accounts.json?filter=${selectedFilter}`
    }

    fetch(url)
      .then(res => res.json())
      .then(data => {
        setCarsalesAccounts(data)
        setLoading(false)
      })
  }, [selectedFilter])

  return (
    <div className="container py-3">
      <h2>Carsales Accounts</h2>
      <Select
        options={filters}
        placeholder="Filter"
        onChange={(e) => setSelectedFilter(e.value)}
      />
      <div className="box mt-3">
        <DataTable value={carsalesAccounts} loading={loading}>
          <Column field="name" header="Name" body={Name} />
          <Column field="carsales_id" header="Carsales ID" body={CarsalesId} />
          <Column field="dealership.name" header="Dealership" sortable />
          <Column body={Billing} header="Billing" sortable field="billing" />
          <Column body={AcceptCars} header="Accept Cars" sortable field="accept_cars" />
          <Column body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default CarsalesAccounts