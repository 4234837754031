import LoadingBoxes from '../../entries/LoadingBoxes'
import { useViewportData } from '../hooks'
import { ModuleProps } from '../types'

type Campaign = {
  campaign_name: string
  clicks: string
  cpc: string
  ctr: string
  cost: string
  cost_per_lead: string
  leads: string
  cost_per_lead_difference: string
  leads_difference: string
  cost_difference: string
  clicks_difference: string
  cpc_difference: string
  ctr_difference: string
  cost_diff_percentage: string
  ctr_diff_percentage: string
  cpc_diff_percentage: string
  clicks_diff_percentage: string
  cost_per_lead_diff_percentage: string
  leads_diff_percentage: string
}

const PerformanceByCampaign: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  function getColor(value: string, reverse: boolean = false): string {
    if (value?.toString()?.includes('-')) {
      return reverse ? 'text-success' : 'text-danger'
    } else {
      return reverse ? 'text-danger' : 'text-success'
    }
  }

  return (
    <div ref={viewportRef} >
      {!loading && data && data[item?.module] ? (
        <div>
          {/* table for displaying campaign data */}
          <table className="table table-sm table-striped mt-3" style={{ fontSize: '15px' }}>
            <thead>
              <tr className="text-center">
                <th className="text-left">Campaign Name</th>
                <th>Clicks</th>
                <th>Cost Per Click (CPC)</th>
                <th>Click Through Rate (CTR)</th>
                <th>Leads</th>
                <th>Cost Per Lead</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {data[item?.module]?.slice(0, data[item?.module]?.length - 1 || 0).map((campaign: Campaign, index) => {
                const {
                  campaign_name: name,
                  clicks,
                  cpc,
                  ctr,
                  cost,
                  leads,
                  cost_per_lead,
                  cost_per_lead_difference: costPerLeadDiff,
                  leads_difference: leadsDiff,
                  cost_difference: costDiff,
                  clicks_difference: clicksDiff,
                  cpc_difference: cpcDiff,
                  ctr_difference: ctrDiff,
                  cost_diff_percentage: costDiffPercentage,
                  ctr_diff_percentage: ctrDiffPercentage,
                  cpc_diff_percentage: cpcDiffPercentage,
                  clicks_diff_percentage: clicksDiffPercentage,
                  cost_per_lead_diff_percentage: costPerLeadDiffPercentage,
                  leads_diff_percentage: leadsDiffPercentage
                } = campaign
                return (
                  <tr key={name}>
                    <td>{name}</td>
                    <td className="text-center">
                      <b>{clicks}</b>
                      <br />
                      <small
                        className={getColor(clicksDiff, true)}
                        title={clicksDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = clicksDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = clicksDiff)}
                      >
                        {clicksDiff}
                      </small>
                    </td>
                    <td className="text-center">
                      <b>{cpc}</b>
                      <br />
                      <small
                        className={getColor(cpcDiff, true)}
                        title={cpcDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = cpcDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = cpcDiff)}
                      >
                        {cpcDiff}
                      </small>
                    </td>
                    <td className="text-center">
                      <b>{ctr}</b>
                      <br />
                      <small
                        className={getColor(ctrDiff, true)}
                        title={ctrDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = ctrDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = ctrDiff)}
                      >
                        {ctrDiff}
                      </small>
                    </td>
                    <td className="text-center">
                      <b>{leads}</b>
                      <br />
                      <small
                        className={getColor(leadsDiff, true)}
                        title={leadsDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = leadsDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = leadsDiff)}
                      >
                        {leadsDiff}
                      </small>
                    </td>
                    <td className="text-center">
                      <b>{cost_per_lead}</b>
                      <br />
                      <small
                        className={getColor(costPerLeadDiff, true)}
                        title={costPerLeadDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = costPerLeadDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = costPerLeadDiff)}
                      >
                        {costPerLeadDiff}
                      </small>
                    </td>
                    <td className="text-center">
                      <b>{cost}</b>
                      <br />
                      <small
                        className={getColor(costDiff, true)}
                        title={costDiffPercentage}
                        onMouseEnter={(e) => (e.currentTarget.innerText = costDiffPercentage)}
                        onMouseLeave={(e) => (e.currentTarget.innerText = costDiff)}
                      >
                        {costDiff}
                      </small>
                    </td>
                  </tr>
                )
              })}
              {data && data[item?.module] && data[item?.module]?.length > 0 && (
                <tr>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].campaign_name}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].clicks}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].cpc}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].ctr}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].leads}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].cost_per_lead}</b>
                  </td>
                  <td className="text-center">
                    <b>{data[item?.module][data[item?.module]?.length - 1].cost}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default PerformanceByCampaign
