import { useState, useContext, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import { CurrentUserContext, DealershipContext, UnavailableIntervalContext } from '../contexts'
import DealershipUsersUnavailableIntervalsForm from './dealershipUsersUnavailableIntervalsForm'
import { DealershipUsersUnavailableIntervalsService } from './dealershipUsersUnavailableIntervalsService'
import { useFetchDealership } from '../dataHooks'

const DealershipUsersUnavailableIntervals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentInterval, setCurrentInterval] = useState(null)
  const [intervals, setIntervals] = useState([])
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  const dealershipId = dealership?.id
  const notification = useRef(null)

  useFetchDealership()

  const loadData = () => {
    let params = {
      dealership_id: dealershipId,
    }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    DealershipUsersUnavailableIntervalsService.getIntervals(params, csrf, dealershipId).then(
      (data) => {
        setIntervals(data)
      }
    )
  }

  useEffect(() => {
    if (dealershipId) {
      loadData()
    }
  }, [dealershipId])

  const openModal = (interval = null) => {
    setCurrentInterval(interval)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentInterval(null)
  }

  const checkingOverlappingAppointments = (intervalData) => {
    let params = {
      starts_at: intervalData.starts_at,
      ends_at: intervalData.ends_at,
      user_id: intervalData.user_id,
    }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    return DealershipUsersUnavailableIntervalsService.checkingOverlapingAppointment(
      params,
      csrf,
      dealershipId
    )
  }

  const handleSave = async (intervalData) => {
    intervalData.user = intervalData.user || currentUser
    intervalData.user_id = intervalData.user?.id || currentUser.id
    const startsAtDate = new Date(intervalData.startsAt).toISOString()
    intervalData.starts_at = startsAtDate.toLocaleString('en-GB')
    intervalData.ends_at = new Date(intervalData.endsAt).toISOString()

    // Prevent overlapping intervals for the same user
    const overlappingInterval = intervals.find(
      (existingInterval) => {
        return (
          existingInterval.user.id === intervalData.user_id &&
          new Date(existingInterval.starts_at) < new Date(intervalData.ends_at) &&
          new Date(existingInterval.ends_at) > new Date(intervalData.starts_at) && existingInterval.id !== intervalData.id
        )
      }
    )

    if (overlappingInterval) {
      showToast(
        notification,
        'error',
        'The unavailable interval overlaps with an existing interval for the same user.'
      )
      return
    }

    // Check for existing appointments that overlap with the new interval
    const overlappingAppointments = await checkingOverlappingAppointments(intervalData)
    if (overlappingAppointments.data.length > 0) {
      showToast(
        notification,
        'error',
        'The unavailable interval overlaps with existing appointments assigned to the team member.'
      )
      return
    }

    if (intervalData.id) {
      const url = `/dealerships/${dealership.id}/dealership_users_unavailable_intervals/${intervalData.id}`

      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify(intervalData),
      })

      if (!response.ok) {
        showToast(notification, 'error', 'The unavailable interval could not be saved.')
      } else {
        const data = await response.json()
        setIntervals(intervals.map((interval) => (interval.id === data.id ? data : interval)))
        closeModal()
        showToast(notification, 'success', 'The unavailable interval has been saved successfully.')
      }
    } else {
      // Creating a new unavailable interval
      const url = `/dealerships/${dealership.id}/dealership_users_unavailable_intervals`

      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify(intervalData),
      })

      if (!response.ok) {
        showToast(notification, 'error', 'The unavailable interval could not be saved.')
      } else {
        const data = await response.json()
        data.starts_at = data.starts_at.replace('T', ' ').replace('Z', '')
        setIntervals([...intervals, data])
        closeModal()
        showToast(
          notification,
          'success',
          'The unavailable interval has been created successfully.'
        )
      }
    }
  }

  const handleDelete = (interval) => {
    confirmDialog({
      message: 'Are you sure you want to delete the unavailable interval?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const url = `/dealerships/${dealership.id}/dealership_users_unavailable_intervals/${interval.id}`

        fetch(url, {
          method: 'DELETE',
          headers: standardHeaders,
        })
          .then((response) => {
            if (!response.ok) {
              // Handle non-200 responses
              showToast(notification, 'error', 'The unavailable interval could not be removed.')
              throw new Error('Network response was not ok')
            } else {
              showToast(
                notification,
                'success',
                'The unavailable interval has been removed successfully.'
              )
              // Update state to remove the deleted interval
              setIntervals(
                intervals.filter((existingInterval) => existingInterval.id !== interval.id)
              )
            }
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error)
          })
      },
    })
  }

  const IntervalDate = (date) => {
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Parse the given UTC date string
    const utcDate = moment.utc(date)

    // Convert to the user's local timezone
    const localDate = utcDate.tz(userTimezone)

    return (
      <div>
        <p>
          <span className="text-secondary">
            <i className="fa-regular fa-calendar mr-2"> </i>
          </span>
          <span className="text-secondary"> {localDate.format('DD/MM/YYYY')}</span>
          <br />
          <span className="text-secondary">
            <i className="fa-regular fa-clock mr-2"> </i>
          </span>
          <span className="text-secondary"> {localDate.format('hh:mm A')}</span>
        </p>
      </div>
    )
  }

  return (
    <UnavailableIntervalContext.Provider value={{ intervals, setIntervals }}>
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="px-3 py-1">
        <div className="box">
          <div className="d-flex justify-content-between align-items-center px-3 pt-3 border-bottom">
            <h5 className="text-secondary">Upcoming Dealership User Unavailable Intervals</h5>
            <div
              className="btn btn-outline-primary ml-auto btn-sm mb-2"
              onClick={() => openModal()}
            >
              <i className="fa fa-plus mr-2"></i>
              Add Unavailable Interval
            </div>
          </div>
          <DataTable value={intervals} paginator rows={5}>
            <Column
              field="user.name"
              style={{ maxWidth: '10rem' }}
              header="Unavailable Team Member"
            />
            <Column
              field="starts_at"
              style={{ maxWidth: '8rem' }}
              body={(rowData) => IntervalDate(rowData.starts_at)}
              header="Start Date/Time"
            />
            <Column
              field="ends_at"
              style={{ maxWidth: '8rem' }}
              body={(rowData) => IntervalDate(rowData.ends_at)}
              header="End Date/Time"
            />
            <Column
              style={{ maxWidth: '6.2rem' }}
              header="Actions"
              body={(rowData) => (
                <div>
                  <button
                    className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                    onClick={() => openModal(rowData)}
                  >
                    <i className="fas fa-edit"></i>
                    &nbsp;Edit
                  </button>
                  <button
                    className="btn btn-outline-danger ml-auto btn-sm mb-2"
                    onClick={() => handleDelete(rowData)}
                  >
                    <i className="fas fa-trash"></i>
                    &nbsp;Delete
                  </button>
                </div>
              )}
            />
          </DataTable>
        </div>
        {isModalOpen && (
          <Dialog
            header={currentInterval ? 'Edit Unavailable Interval' : 'New Unavailable Interval'}
            visible={isModalOpen}
            style={{ minWidth: '400px' }}
            onHide={closeModal}
            dismissableMask={true}
          >
            <DealershipUsersUnavailableIntervalsForm
              interval={currentInterval}
              onSave={handleSave}
            />
          </Dialog>
        )}
      </div>
    </UnavailableIntervalContext.Provider>
  )
}

export default DealershipUsersUnavailableIntervals
