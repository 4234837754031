import { useRef, useState } from 'react'
import {
  FormProvider,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { standardHeaders } from '../../entries/utils'
import { PendingText } from '../../tanstackPlaceholderUtils'

export type SpecificationCategoriesFormData = {
  specificationCategories: {
    id?: number
    name: string
  }[]
}

type EditableAction = 'none' | number

const SpecificationCategoriesFormFields = ({
  editAction,
  setEditAction,
  onDelete,
  addNew,
  setAddNew,
}: {
  editAction: EditableAction
  setEditAction: (val: EditableAction) => void
  onDelete: (id: number, remove: UseFieldArrayRemove, index: number) => void
  addNew: boolean
  setAddNew: (val: boolean) => void
}) => {
  const { control, register, watch } = useFormContext<SpecificationCategoriesFormData>()
  const { errors } = useFormState()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'specificationCategories',
  })

  const categories = watch('specificationCategories')

  const inputValidation = (input: string) => {
    if (!input) {
      return 'Category name is required'
    }
    const uniqueFields = categories.filter((f) => f.name.toLowerCase() === input.toLowerCase())
    if (uniqueFields.length > 1) {
      return 'Category names must be unique'
    }
    return true
  }

  return (
    <div className="mb-3">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '70%' }} scope="col">
              Category
            </th>
            <th style={{ width: '30%' }} scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const isEditable = editAction === index
            return (
              <tr key={item.id}>
                <td>
                  <InputText
                    className={'w-100 p-1' + (isEditable ? '' : ' border-0 bg-white')}
                    {...register(`specificationCategories.${index}.name`, {
                      validate: inputValidation,
                    })}
                    placeholder="Category"
                    disabled={!isEditable}
                  />
                  {errors?.specificationCategories?.[index]?.name && (
                    <span className="d-block small text-danger">
                      {errors.specificationCategories[index]?.name?.message}
                    </span>
                  )}
                </td>
                <td className="d-flex justify-content-end">
                  {isEditable ? (
                    <button
                      style={{ flex: 0.5 }}
                      type="submit"
                      className="btn btn-sm btn-outline-success"
                    >
                      <PendingText text="Save" />
                    </button>
                  ) : (
                    <button
                      style={{ flex: 0.5 }}
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => setTimeout(() => setEditAction(index), 50)}
                      disabled={editAction !== 'none'}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    style={{ flex: 0.5 }}
                    type="button"
                    className="btn btn-sm btn-outline-danger ml-1"
                    onClick={() => {
                      onDelete(categories[index].id, remove, index)
                    }}
                    disabled={editAction !== 'none' || !categories[index].id}
                  >
                    {categories[index].id ? 'Delete' : 'Refresh to Delete'}
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-outline-primary mr-3"
          disabled={!addNew}
          onClick={() => {
            append({ name: '' })
            setEditAction(fields.length)
            setAddNew(false)
          }}
        >
          Add New Category
        </button>
      </div>
    </div>
  )
}

export const SpecificationCategoriesForm = ({
  manufacturerSlug,
  defaultValues = {},
}: {
  manufacturerSlug: string
  defaultValues?: Partial<SpecificationCategoriesFormData>
}) => {
  // Manage form state
  const [editAction, setEditAction] = useState<EditableAction>('none')
  const [addNew, setAddNew] = useState<boolean>(true)
  const form = useForm<SpecificationCategoriesFormData>({
    mode: 'onSubmit',
    defaultValues,
  })
  const toast = useRef(null)

  // Handle form data submission
  const onSubmit = async (data: SpecificationCategoriesFormData) => {
    const id = data.specificationCategories[editAction]?.id
    const name = data.specificationCategories[editAction]?.name
    const url = id
      ? `/manufacturers/${manufacturerSlug}/model_specification_categories/${id}`
      : `/manufacturers/${manufacturerSlug}/model_specification_categories`

    const method = id ? 'PATCH' : 'POST'
    try {
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({
          model_specification_category: { name },
        }),
        headers: standardHeaders,
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      toast.current.show({
        severity: 'success',
        summary: 'Saved Successfully',
      })
      setEditAction('none')
      setAddNew(true)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Unable To Save',
        detail: `A server error occurred: ${error.message}`,
      })
    }
  }
  const onDelete = async (id: number, remove: UseFieldArrayRemove, index: number) => {
    if (window.confirm('Are you sure?')) {
      try {
        const response = await fetch(
          `/manufacturers/${manufacturerSlug}/model_specification_categories/${id}`,
          {
            method: 'DELETE',
            headers: standardHeaders,
          }
        )
        remove(index)
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        toast.current.show({
          severity: 'success',
          summary: 'Category Deleted Successfully',
        })
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Unable to Delete Category',
          detail: `A server error occurred: ${error.message}`,
        })
      }
    }
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          await onSubmit(data)
        })}
      >
        <Toast ref={toast} />
        <div className="bg-white table-responsive">
          <SpecificationCategoriesFormFields
            editAction={editAction}
            setEditAction={setEditAction}
            onDelete={onDelete}
            addNew={addNew}
            setAddNew={setAddNew}
          />
        </div>
      </form>
    </FormProvider>
  )
}
