import { useState, useEffect, useContext } from 'react'
import EditableItem from '../leads/EditableItem'

const OrderForm = ({ order, onSave, orderStatusOptions, contacts, parts }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSave({
      id: order?.id,
      contact_id: contact?.id,
      order_status_option_id: orderStatus,
      order_date: orderDate,
      shipping_address_attributes: {
        id: order?.shipping_address?.id,
        street: shippingStreet,
        city: shippingCity,
        state: shippingState,
        postcode: shippingPostcode,
      },
      billing_address_attributes: {
        id: order?.billing_address?.id,
        street: billingStreet,
        city: billingCity,
        state: billingState,
        postcode: billingPostcode,
      },
      order_items_attributes: orderItems,
    })
  }

  const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) {
      return ''
    }
    return date.toISOString().split('T')[0]
  }

  useEffect(() => {
    setContact(order?.contact)
  }, [order])

  let [contact, setContact] = useState(order?.contact)
  let [orderStatus, setOrderStatus] = useState(order?.order_current_status_option_id)
  let [orderDate, setOrderDate] = useState(order?.order_date ? formatDate(new Date(order?.order_date)) : formatDate(new Date(order?.created_at)))
  let [shippingStreet, setShippingStreet] = useState(order?.shipping_address?.street)
  let [shippingCity, setShippingCity] = useState(order?.shipping_address?.city)
  let [shippingState, setShippingState] = useState(order?.shipping_address?.state)
  let [shippingPostcode, setShippingPostcode] = useState(order?.shipping_address?.postcode)
  let [billingStreet, setBillingStreet] = useState(order?.billing_address?.street)
  let [billingCity, setBillingCity] = useState(order?.billing_address?.city)
  let [billingState, setBillingState] = useState(order?.billing_address?.state)
  let [billingPostcode, setBillingPostcode] = useState(order?.billing_address?.postcode)

  let [note, setNote] = useState(order?.note)
  let [orderItems, setOrderItems] = useState(order?.order_items || [])

  const handlePartChange = (index, newPart) => {
    const updatedItems = [...orderItems]
    updatedItems[index].part.name = newPart
    setOrderItems(updatedItems)
  }

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...orderItems]
    updatedItems[index].quantity = newQuantity
    setOrderItems(updatedItems)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <h5>Customer Details</h5>
        {order?.id ? (
          <div>
            <div className="row">
              <div className="col-md-6 my-1">
                <EditableItem
                  field="first_name"
                  label="First Name"
                  value={contact?.first_name}
                  setContact={setContact}
                />
              </div>
              <div className="col-md-6 my-1">
                <EditableItem
                  field="last_name"
                  label="Last Name"
                  value={contact?.last_name}
                  setContact={setContact}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-1">
                <EditableItem
                  field="phone_number"
                  label="Phone"
                  value={contact?.phone_number}
                  setContact={setContact}
                />
              </div>
              <div className="col-md-6 my-1">
                <EditableItem
                  field="email"
                  label="Email"
                  value={contact?.email}
                  setContact={setContact}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-1">
                <EditableItem
                  field="postcode"
                  label="Postcode"
                  value={contact?.postcode}
                  setContact={setContact}
                />
              </div>
              <div className="col-md-6 my-1">
                <EditableItem
                  field="business_name"
                  label="Business Name"
                  value={contact?.business_name}
                  setContact={setContact}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 my-1">
              <div className="form-group">
                <label htmlFor="contactSelect">Select Contact</label>
                <select
                  className="form-control"
                  id="contactSelect"
                  onChange={(e) => setContact(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select a contact
                  </option>
                  {contacts.map((contact) => (
                    <option key={contact.id} value={contact.id}>
                      {contact.first_name} {contact.last_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        <h5>Order Details</h5>
        <div className="row">
          <div className="col-md-6 my-1">
            <div className="form-group">
              <label htmlFor="orderStatus">Order Status</label>
              <select
                className="form-control"
                id="orderStatus"
                value={orderStatus}
                onChange={(e) => {
                  console.log('Selected value:', e.target.value)
                  setOrderStatus(e.target.value)
                }}
                required
              >
                <option value="" disabled>
                  Select an order status
                </option>
                {orderStatusOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="form-group">
              <label htmlFor="orderDate">Order Date</label>
              <input
                type="date"
                className="form-control"
                id="orderDate"
                value={orderDate}
                onChange={(e) => setOrderDate(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 my-1">
            <label htmlFor="orderItems">Order Items</label>
            <div className="form-group">
              {(orderItems && orderItems.length > 0 ? orderItems : [{}]).map((item, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <select
                    className="form-control"
                    value={item.part_name}
                    onChange={(e) => handlePartChange(index, e.target.value)}
                    readOnly={true}
                  >
                    <option value="" disabled>
                      Select a part
                    </option>
                    {parts.map((part) => (
                      <option key={part.id} value={part.name}>
                        {part.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    className="form-control"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, e.target.value || 1)}
                    min={1}
                    step={1}
                    readOnly={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-6 my-1">
            <div className="form-group">
              <label htmlFor="shippingCost">Shipping Cost</label>
              <input
                type="text"
                className="form-control"
                id="shippingCost"
                value={order?.shipping_cost ? parseFloat(order.shipping_cost).toFixed(2) : '0.00'}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="form-group">
              <label htmlFor="totalPrice">Total Price</label>
              <input
                type="text"
                className="form-control"
                id="totalPrice"
                value={order?.total_price ? parseFloat(order.total_price).toFixed(2) : '0.00'}
                readOnly
              />
            </div>
          </div>
          
        </div>
        <div className="row">
          <div className="col-md-6 my-1">
            <h5>Shipping Address</h5>
            <div className="form-group row">
              <label htmlFor="street" className="col-sm-3 col-form-label">
                Street:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="street"
                  className="form-control"
                  value={shippingStreet}
                  onChange={(e) => setShippingStreet(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="city" className="col-sm-3 col-form-label">
                City:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  value={shippingCity}
                  onChange={(e) => setShippingCity(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="state" className="col-sm-3 col-form-label">
                State:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="state"
                  className="form-control"
                  value={shippingState}
                  onChange={(e) => setShippingState(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="postcode" className="col-sm-3 col-form-label">
                Postcode:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="postcode"
                  className="form-control"
                  value={shippingPostcode}
                  onChange={(e) => setShippingPostcode(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 my-1">
            <h5>Billing Address</h5>
            <div className="form-group row">
              <label htmlFor="street" className="col-sm-3 col-form-label">
                Street:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="street"
                  className="form-control"
                  value={billingStreet}
                  onChange={(e) => setBillingStreet(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="city" className="col-sm-3 col-form-label">
                City:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  value={billingCity}
                  onChange={(e) => setBillingCity(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="state" className="col-sm-3 col-form-label">
                State:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="state"
                  className="form-control"
                  value={billingState}
                  onChange={(e) => setBillingState(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="postcode" className="col-sm-3 col-form-label">
                Postcode:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="postcode"
                  className="form-control"
                  value={billingPostcode}
                  onChange={(e) => setBillingPostcode(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default OrderForm
