// MANAGED BY App.js
import { capitalise } from '../entries/utils'
import { useState, useRef, useEffect, useContext } from 'react'
import "yet-another-react-lightbox/styles.css"
import CarSpecs from './CarSpecs'
import LeadBox from './LeadBox'
import ThirdPartyData from './ThirdPartyData'
import ApiLogs from './Apilogs'
import showToast from '../shared/ShowToast'
import * as Routes from '../../routes'
import { Toast } from 'primereact/toast'
import ThirdPartyRestriction from './ThirdPartyRestriction'
import TestDrivesBox from './TestDrivesBox'
import { TestDriveService } from '../testDrives/testDriveService'
import Loading from '../Loading'
import { useParams } from 'react-router-dom'
import CarTabs from './CarTabs'
import { Link } from 'react-router-dom'
import CarSettings from './CarSettings'
import { CarContext, CurrentUserContext } from '../contexts'
import { csrfToken } from '../entries/utils'
import CarPhotos from './CarPhotos'

const CarWrapper = () => {
  const { carId } = useParams()
  let [car, setCar] = useState(false)
  let [dealership, setDealership] = useState(false)

  useEffect(() => {
    fetch(`/cars/${carId}/show_react.json`)
      .then(res => res.json())
      .then(data => {
        setCar(data.car)
        setDealership(data.dealership)
      })
  }, [])

  if (!car || !dealership) return (<div className="p-3"><Loading /></div>)

  return (
    <App car_data={car} dealership={dealership} />
  )
}

const App = ({ car_data, dealership }) => {
  const currentUser = useContext(CurrentUserContext)
  const notification = useRef(null)
  let [loading, setLoading] = useState(false)
  const [car, setCar] = useState(car_data)
  const [testDrives, setTestDrives] = useState(null)

  const loadTestDrives = async () => {
    const params = { car_id: car.id, page: 1, rows: 50 }

    TestDriveService.getTestDrives({ params, csrfToken, dealershipId: car.dealership_id }).then((data) => {
      setTestDrives(data.data.test_drives)
    })
  }

  useEffect(() => {
    loadTestDrives()
  }, [car])
  let [carSettingsVisible, setCarSettingsVisible] = useState(false)

  const handleThirdPartyRestriction = async () => {
    let ignore_manufacturer_restriction = !car.ignore_manufacturer_restriction
    setLoading(true)
    let response = await fetch(Routes.car_path(car.slug), {
      method: 'PATCH',
      body: JSON.stringify({
        ignore_manufacturer_restriction: ignore_manufacturer_restriction
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json"
      },
    })
    let data = await response.json()
    setLoading(false)
    if (data) {
      setCar({ ...car, ignore_manufacturer_restriction: ignore_manufacturer_restriction })
      showToast(notification, 'success', 'Third party restriction updated!', `Third party restriction was successfully ${ignore_manufacturer_restriction ? 'ignored' : 'applied'}.`)
    } else {
      showToast(notification, 'error', 'Third party restriction not updated!', 'Third party restriction was not updated.')
    }
  }

  const Banner = ({ car }) => {
    let data = [car.status, car.car_type, car.simple_colour, car.stocknum, car.regplate, car.location?.name]
    let string = ''
    data.map((sub, i) => {
      if (sub) {
        sub = (i < 2) ? capitalise(sub?.replace(/_/g, ' ')) : sub
        string += i !== 0 ? ' | ' + sub : sub
      }
    })
    return (
      <div className="banner text-center py-5 bg-light">
        <h1 className="m-0">
          {car.name}
        </h1>
        <h4 className="text-secondary m-0">{string}</h4>
        {car.tag && (<div className="badge badge-danger">{car.tag}</div>)}
      </div>
    )
  }

  const carDetails = { apilogs: car.apilogs, dealership_slug: car.dealership_slug, slug: car.slug, name: car.name, stocknum: car.stocknum }

  return (
    <CarContext.Provider value={{ car, setCar }}>
      <CarSettings car={car} visible={carSettingsVisible} setVisible={setCarSettingsVisible} setCar={setCar}>
        <></>
      </CarSettings>
      <Toast ref={notification} />
      <Banner car={car} />
      <br />
      <div className="px-3 pb-5">
        <div className="d-md-flex">
          <Link to={Routes.dealership_cars_path(car.dealership_slug)} className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2">Back</Link>
          {car.can_edit && currentUser?.admin && (
            <a href={Routes.new_stock_image_set_path()} className="btn btn-outline-primary btn-block-md-down mr-md-2 ml-md-2 mb-2">Add Stock images</a>
          )}
          {car.can_edit && (
            <>
              <a href={Routes.edit_car_path(car.slug)} className="btn btn-outline-primary btn-block-md-down mr-md-2 ml-md-2 mb-2">
                <i className="fa fa-edit mr-1"></i>
                Edit
              </a>
              <a href={Routes.new_image_car_path(car.slug)} className="btn btn-outline-primary btn-block-md-down mr-md-2 mb-2">
                <i className="fa fa-camera mr-1"></i>
                Add Photos
              </a>
              <a href={Routes.add_video_car_path(car.slug)} className="d-none d-md-block btn btn-outline-primary btn-block-md-down mr-md-2 mb-2">
                <i className="fa fa-video mr-1"></i>
                Add Video
              </a>
              <a href={Routes.print_car_path(car.slug)} className="d-none d-md-block btn btn-outline-primary btn-block-md-down mr-md-2 mb-2">
                <i className="fa fa-print mr-1"></i>
                Print
              </a>
              <div className="dropdown">
                <button className="btn btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown">
                  <i className="fa fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a href={`/dealerships/${car.dealership_slug}/test_drives?car_id=${car.id}`} className='dropdown-item'>
                    <i className="fa fa-steering-wheel mr-1"></i>
                    Test Drives
                  </a>
                  <a href={Routes.car_file_uploads_path(car.slug)} className="dropdown-item">
                    <i className="fa fa-download mr-1"></i>
                    Incoming FTP Photos
                  </a>
                  <a href={Routes.car_exports_car_path(car.slug)} className="dropdown-item">
                    <i className="fa fa-upload mr-1"></i>
                    Photo Exports
                  </a>
                  <a href={Routes.apicalls_car_path(car.slug)} className="dropdown-item">
                    <i className="fa fa-rectangle-history mr-1"></i>
                    Api Logs
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
        <br />
        {car.stock_rules && car.stock_rules.length > 0 && (
          <div>
            <ThirdPartyRestriction
              ignore_manufacturer_restriction={car.ignore_manufacturer_restriction}
              handleThirdPartyRestriction={handleThirdPartyRestriction}
              manufacturer={car.manufacturer}
              stock_rules={car.stock_rules}
            />
          </div>
        )}
        <CarPhotos dealership={dealership} />
        <CarTabs car={car} setCarSettingsVisible={setCarSettingsVisible} />
        <CarSpecs capitalise={capitalise} />
        <div>
          {car.lead_clusters && car.lead_clusters.length > 0 && (
            <LeadBox leadClusters={car.lead_clusters} />
          )}
        </div>
        <div>
          {testDrives && testDrives.length > 0 && (
            <>
              <TestDrivesBox testDrives={testDrives} />
            </>
          )}
        </div>
        <div>
          {car.third_party_integrations && car.third_party_integrations.length > 0 && (
            <ThirdPartyData third_party_data={car.third_party_integrations} />
          )}
        </div>
        <br />
        {car.apilogs && car.apilogs.length > 0 && (
          <ApiLogs dealership={dealership} car={carDetails} />
        )}
      </div>
    </CarContext.Provider>
  )
}

export default CarWrapper