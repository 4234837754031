import { Controller } from 'react-hook-form'

const Notes = ({ control }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">Notes</div>
      <div className="card-body">
        <Controller
          name="dealerComments"
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              className="form-control"
              placeholder="Record any relevant notes here"
              style={{ minHeight: '110px' }}
            />
          )}
        />
      </div>
    </div>
  )
}

export default Notes
