import { FormEvent, useState } from 'react'
import type { EnquiryAddon } from './EnquiryAddonTypes'
import { capitalize } from '../editor/common/Utils'

export const formatAddonOption = (val: string) => {
  return val
    .split('_')
    .map((v) => capitalize(v))
    .join(' ')
}

const EnquiryAddonForm = ({
  enquiryAddon,
  priceOptions,
  formTypes,
  onSave,
}: {
  enquiryAddon: EnquiryAddon
  priceOptions: string[]
  formTypes: string[]
  onSave: (enquiryAddon: EnquiryAddon) => Promise<void>
}) => {
  const [title, setTitle] = useState(enquiryAddon?.title || '')
  const [description, setDescription] = useState(enquiryAddon?.description || '')
  const [formType, setFormType] = useState(enquiryAddon?.form_type || '')
  const [price, setPrice] = useState(enquiryAddon?.price || '')
  const [priceOption, setPriceOption] = useState(enquiryAddon?.price_option || '')

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSave({
      id: enquiryAddon?.id,
      title: title,
      description: description,
      form_type: formType,
      price: price,
      price_option: priceOption,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          className="form-control"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <input
          type="text"
          className="form-control"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="formType">Form Type</label>
        <select
          className="form-control"
          id="formType"
          value={formType}
          onChange={(e) => setFormType(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a form type
          </option>
          {formTypes.map((formType) => (
            <option key={formType} value={formType}>
              {formatAddonOption(formType)}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="price">Price($)</label>
        <input
          type="number"
          step="0.01"
          className="form-control"
          id="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="priceOption">Price Option</label>
        <select
          className="form-control"
          id="priceOption"
          value={priceOption}
          onChange={(e) => setPriceOption(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a price option
          </option>
          {priceOptions.map((priceOption) => (
            <option key={priceOption} value={priceOption}>
              {formatAddonOption(priceOption)}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default EnquiryAddonForm
