import MeiliSearch from 'meilisearch'
import React, { useEffect, useState } from 'react'

import * as Routes from '../../routes'
import { getMeilisearchConfig } from '../entries/utils'

interface DepositRequestModalProps {
  isOpen: boolean
  onClose: () => void
  dealershipId: number
  contactId: number
}

const DepositRequestModal: React.FC<DepositRequestModalProps> = ({
  isOpen,
  onClose,
  dealershipId,
  contactId,
}) => {
  const [availableCars, setAvailableCars] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()
  const client = new MeiliSearch({
    host: meilisearchHost,
    apiKey: meilisearchApiKey,
  })

  const index = client.index(`Car_${process.env.RAILS_ENV}`)

  useEffect(() => {
    const fetchCars = async () => {
      const searchResults = await index.search('', {
        limit: 5,
        offset: 0,
        filter: [`dealership_id=${dealershipId}`],
      })
      setAvailableCars(searchResults.hits)
    }

    fetchCars()
  }, [dealershipId])

  const handleSearchChange = async (event) => {
    const value = event.target.value
    setSearchValue(value)
    const searchResults = await index.search(value, {
      limit: 5,
      offset: 0,
      filter: [`dealership_id=${dealershipId}`],
    })
    setAvailableCars(searchResults.hits)
  }

  if (!isOpen) return null

  return (
    <div className="modal fade show" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Send Deposit Request</h5>
            <button type="button" className="close" onClick={onClose}>
              &times;
            </button>
          </div>

          <div className="modal-body">
            <div className="input-group">
              <div className="input-group-prepend">
                <button className="btn btn-primary">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <input
                type="search"
                placeholder="Stock number, make, model..."
                className="form-control"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>

            <div className="box my-2 car-box">
              {availableCars.length > 0 ? (
                availableCars.map((car) => (
                  <div key={car.id} className="car-grid">
                    <div className="item">
                      <img src={car.primary_image_url} className="rounded border" />
                    </div>
                    <div className="item">
                      {car.name}
                      <div className="small text-secondary">
                        {car.status && (
                          <span className="subtitle-item text-success">
                            {car.status.replace('_', ' ').toUpperCase()}
                          </span>
                        )}
                        {car.car_type && <span className="subtitle-item">{car.car_type}</span>}
                        {car.colour && <span className="subtitle-item">{car.colour}</span>}
                        {car.stocknum && <span className="subtitle-item">{car.stocknum}</span>}
                      </div>
                    </div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item d-flex align-items-center">
                      <form
                        action={Routes.send_deposit_request_dealership_contact_path(
                          dealershipId,
                          contactId
                        )}
                        method="post"
                      >
                        <input type="hidden" name="car_id" value={car.id} />
                        <input
                          type="hidden"
                          name="authenticity_token"
                          value={
                            document
                              .querySelector('meta[name="csrf-token"]')
                              ?.getAttribute('content') || ''
                          }
                        />
                        <button type="submit" className="btn btn-primary" id="send-deposit-request">
                          Send
                        </button>
                      </form>
                    </div>
                  </div>
                ))
              ) : (
                <div id="no-results" className="text-center">
                  No results found
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositRequestModal
