import { Toast } from 'primereact/toast'
import { RefObject } from 'react'

// notification param is a useRef
const showToast = (
  notification: RefObject<Toast>,
  severityValue: 'success' | 'info' | 'warn' | 'error',
  summaryValue: string,
  detailValue: string
) => {
  if (notification?.current) {
    notification.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    })
  } else {
    console.warn('Toast reference is not available yet.')
  }
}

export default showToast
