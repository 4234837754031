import { useState } from 'react'
import type { ModuleProps } from '../types'
import { useViewportData } from '../hooks'
import LoadingBoxes from '../../entries/LoadingBoxes'
import { TooltipContentStyles } from '../ModuleWrapperComponent'
import { Provider, Root, Trigger, Content } from '@radix-ui/react-tooltip'
import { useAnalyticsDashboard } from '../contexts/hooks'

type TotalPageViewsData = [string, number, number, string][]

type TotalPageViews = {
  [website: string]: TotalPageViewsData
}

const TopPageViews: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const { level } = useAnalyticsDashboard()
  // For testing
  // const originalData = data?.top_page_views['Orange Motor Group']

  // const newData = data
  //   ? {
  //       'Orange Motor Group': [...originalData],
  //       Motorama: [...originalData],
  //     }
  //   : null

  const totalPageViews: TotalPageViews = data?.top_page_views
  // top page views is an object with keys as website and values as array of [path, count, previous_count, perencentage_change]
  const [minimized, setMinimized] = useState<{ [website: string]: boolean }>({})

  const toggleMinimized = (website: string) => {
    setMinimized((prevState) => ({
      ...prevState,
      [website]: !prevState[website],
    }))
  }

  const viewStats = (difference: number, percentage: string) => {
    return (
      <div>
        <div>
          {difference > 0 ? (
            <div className="text-success d-flex" id={`total-page-views-${difference}`}>
              +{difference} <span className="mx-1">|</span>
              <div style={{ width: '50px' }} className="text-center">
                {percentage !== null ? `+${percentage}%` : 'N/A'}
              </div>
            </div>
          ) : (
            <div className="text-danger d-flex" id={`total-page-views-${difference}`}>
              {difference} <span className="mx-1">|</span>
              <div style={{ width: '50px' }} className="text-center">
                {' '}
                -{percentage}%
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div ref={viewportRef}>
      {!loading && totalPageViews ? (
        <div>
          <div className="row mt-2">
            <div className="col-8 d-flex align-items-center">Page Path</div>
            <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center text-center">
              Page Views
              <span className="small text-secondary">
                ({timeRange?.timeframe || 'Last 30 Days'})
              </span>
            </div>
            <div className="col-2 p-0 d-flex justify-content-end align-items-center">
              <Provider delayDuration={50}>
                <Root>
                  <Trigger asChild>
                    <p style={{ cursor: 'pointer' }}>Difference</p>
                  </Trigger>
                  <Content sideOffset={5} style={TooltipContentStyles}>
                    This increase/decrease represents the difference from the same period before the
                    selected range. It indicates recent growth/decline compared to the previous
                    equivalent timeframe. N/A represents that there was no data in the previous time
                    period to compare to.
                  </Content>
                </Root>
              </Provider>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '2px',
                  width: '100%',
                  margin: '0 auto',
                }}
              />
            </div>
          </div>
          <div className="mt-2">
            {Object.keys(totalPageViews).map((website, websiteIndex) => (
              <div key={websiteIndex} className="p-2 mb-2">
                {level !== 'Website' && (
                  <button className="toggle-button" onClick={() => toggleMinimized(website)}>
                    <strong>{website}</strong>
                    {minimized[website] ? (
                      <i className="fa-solid fa-chevron-down ml-2 text-secondary"></i>
                    ) : (
                      <i className="fa-solid fa-chevron-up ml-2 text-secondary"></i>
                    )}
                  </button>
                )}
                {!minimized[website] && (
                  <div className="mt-2">
                    {totalPageViews[website]?.map(
                      ([path, count, previousCount, percentage], index) => (
                        <div key={index} className="row">
                          <div className="col-8">{path}</div>
                          <div className="col-2 p-0 d-flex justify-content-center">{count}</div>
                          <div className="col-2 p-0 d-flex justify-content-end">
                            {viewStats(count - previousCount, percentage)}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
            {Object.keys(totalPageViews).length === 0 && (
              <div className="text-center mt-3">No Data available</div>
            )}
          </div>
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default TopPageViews
