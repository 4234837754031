import { Chart, registerables } from 'chart.js'
import { Bar, Line, Pie } from 'react-chartjs-2'
import { ChartDataConfig, ChartDataConfigDataset } from '../types'
import { CSSProperties } from 'react'
Chart.register(...registerables)

const PieChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) => dataset.data?.length > 0
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Pie data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const BarChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Bar data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const LineChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Line data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const chartColors = [
  '#2085ec', // Blue
  '#72b4eb', // Light Blue
  '#0a417a', // Dark Blue
  '#8464a0', // Purple
  '#cea9bc', // Light Pink
  '#f48fb1', // Pink
  '#ffb74d', // Orange
  '#4db6ac', // Teal
  '#81c784', // Green
  '#ff8a65', // Coral
  '#d1b500', // Dull Gold
  '#e65100', // Dull Deep Orange
  '#7cb342', // Dull Light Green
  '#9e9d24', // Dull Lime
  '#fbc02d', // Dull Yellow
  '#455a64', // Dull Blue Grey
  '#c2185b', // Dull Magenta
  '#7b1fa2', // Dull Deep Purple
  '#0097a7', // Dull Cyan
  '#00796b', // Dull Dark Teal
  '#4e3b31', // Dull Brown
  '#ec407a', // Dull Light Magenta
  '#9c6b9f', // Dull Lavender
  '#7e6b5c', // Dull Taupe
  '#a7c6c2', // Dull Light Aqua
  '#ffa000', // Dull Amber
]

export { BarChart, LineChart, PieChart, chartColors }
