import LoadingBoxes from '../../entries/LoadingBoxes'
import { useViewportData } from '../hooks'
import { ModuleProps } from '../types'

type Campaign = {
  keyword: string
  clicks: string
  cpc: string
  ctr: string
  impressions: string
  clicks_difference: string
  cpc_difference: string
  ctr_difference: string
  ctr_diff_percentage: string
  cpc_diff_percentage: string
  clicks_diff_percentage: string
  impressions_difference: string
  impressions_diff_percentage: string
}

const TopPerformingKeywords: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  function getColor(value: string, reverse: boolean = false): string {
    if (value.toString().includes('-')) {
      return reverse ? 'text-success' : 'text-danger'
    }else{
      return  reverse ? 'text-danger' : 'text-success'
    }
  }

  return (
    <div ref={viewportRef} >
      {!loading && data && data[item?.module] ? (
        <div>
          {/* table for displaying campaign data */}
          <table className="table table-sm table-striped mt-3" style={{fontSize: '15px'}}>
            <thead>
              <tr className="text-center">
                <th className="text-left">Keyword</th>
                <th>Clicks</th>
                <th>Impressions</th>
                <th>Cost Per Click (CPC)</th>
                <th>Click Through Rate (CTR)</th>
              </tr>
            </thead>
            <tbody>
              {data[item?.module]?.slice(0, data[item?.module]?.length-1 || 0).map((campaign: Campaign, index) => {
                const {
                  keyword: name,
                  clicks,
                  cpc,
                  ctr,
                  impressions,
                  clicks_difference: clicksDiff,
                  cpc_difference: cpcDiff,
                  ctr_difference: ctrDiff,
                  ctr_diff_percentage: ctrDiffPercentage,
                  cpc_diff_percentage: cpcDiffPercentage,
                  clicks_diff_percentage: clicksDiffPercentage,
                  impressions_difference: impressionsDiff,
                  impressions_diff_percentage: impressionsDiffPercentage
                } = campaign
                return (
                  <tr key={name}>
                    <td>{name}</td>
                    <td className="text-center">
                      <b>{clicks}</b>
                      <br />
                      <small className={getColor(clicksDiff)}>{clicksDiff}</small>
                    </td>
                    <td className="text-center">
                    <b>{impressions}</b>
                    <br />
                    <small className={getColor(impressionsDiff, true)}>{impressionsDiff}</small>
                    </td>
                    <td className="text-center">
                      <b>{cpc}</b>
                      <br />
                      <small className={getColor(cpcDiff, true)}>{cpcDiff}</small>
                    </td>
                    <td className="text-center">
                      <b>{ctr}</b>
                      <br />
                      <small className={getColor(ctrDiff)}>{ctrDiff}%</small>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default TopPerformingKeywords
