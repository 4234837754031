import { useState, useContext } from "react"
import { VehicleBuilder } from "../entries/FormElements"
import { updateLeadAPI } from '../leads/utils'
import { LeadClusterContext } from "../contexts"

const AddCarForm = ({ setVisible }) => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext) || {}
  let [manufacturer, setManufacturer] = useState()
  let [family, setFamily] = useState()
  let [year, setYear] = useState()
  let [loading, setLoading] = useState(false)

  const addCar = () => {

    let params = {
      lead_cluster: {
        car_lead_clusters_attributes: [{
          manufacturer_id: manufacturer,
          family_id: family,
          year: year
        }]
      }
    }
    setLoading(true)
    updateLeadAPI(leadCluster.id, params)
      .then(res => res.json())
      .then(data => {
        setLoading(false)
        setLeadCluster(data)
        setVisible(false)
      })
  }

  return (
    <div className="mt-3">
      <VehicleBuilder
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        family={family}
        setFamily={setFamily}
        year={year}
        setYear={setYear}
      />
      <div className={"btn btn-outline-primary btn-block" + (loading ? ' disabled' : '')} onClick={addCar} id='save-car-button'>
        {loading ? <i className="fa fa-spinner fa-spin"></i> : "Add Car"}
      </div>
    </div>
  )
}

export default AddCarForm