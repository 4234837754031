import React from 'react'

import * as Routes from '../../routes'
import { Contact, Dealership } from './types'

interface LoyaltyBalanceProps {
  contact: Contact
  dealership: Dealership
}

const LoyaltyBalance: React.FC<LoyaltyBalanceProps> = ({ contact, dealership }) => (
  <div className="h-100 d-flex flex-column">
    <div className="position-relative p-3 flex-grow-1">
      <h4 className="mb-0">Loyalty Balance</h4>
      <div className="h-100 d-flex align-items-center">
        <div className="loyalty-circle mr-4">
          <i className="fa fa-trophy fa-lg py-5"></i>
        </div>
        <div>
          <h2 className="mb-0 text-success">{contact.total_points}</h2>
          <p className="text-secondary">{contact.total_points_spent}</p>
        </div>
      </div>
    </div>
    <div className="p-3 box-bottom-button">
      <p className="m-0">
        <a
          href={Routes.new_dealership_contact_contact_point_path(dealership.id, contact.id)}
          className="btn btn-primary"
        >
          <i className="fa fa-plus"></i> Click here to add points
        </a>
      </p>
    </div>
  </div>
)

export default LoyaltyBalance
