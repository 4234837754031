import { useParams } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { PrimeReactProvider } from 'primereact/api'
import { Toast } from 'primereact/toast'

import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import { InsuranceOptionService } from './InsuranceOptionService'
import InsuranceOptionForm  from './InsuranceOptionForm'

const InsuranceOptions = () => {
  const { websiteSlug } = useParams()
  const [insuranceOptions, setInsuranceOptions] = useState([])
  const notification = useRef(null)
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = (insuranceOption = null) => {
    setSelectedInsuranceOption(insuranceOption)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedInsuranceOption(null)
    setIsModalOpen(false)
  }

  const handleSave = async (insuranceOption) => {
    if (insuranceOption.id) {
      const url = Routes.website_insurance_option_path(websiteSlug, insuranceOption.id)
      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify({ insurance_option: insuranceOption }),
      })
      if (response.ok) {
        showToast(notification, 'success', 'Insurance Option Updated')
        closeModal()
        fetchInsuranceOptions()
      } else {
        showToast(notification, 'error', 'Error updating Insurance Option')
      }
    } else {
      const url = Routes.website_insurance_options_path(websiteSlug)
      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ insurance_option: insuranceOption }),
      })
      if (response.ok) {
        showToast(notification, 'success', 'Insurance Option Created')
        closeModal()
        fetchInsuranceOptions()
      } else {
        showToast(notification, 'error', 'Error creating Insurance Option')
      }
    }
  }

  const fetchInsuranceOptions = async () => {
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const response = await InsuranceOptionService.getInsuranceOptions({}, csrf, websiteSlug)
    setInsuranceOptions(response.data.insurance_options)
  }

  useEffect(() => {
    fetchInsuranceOptions()
  }, [])

  return (
    <PrimeReactProvider>
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="px-3 py-1">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <h2>Insurance Options</h2>
              <button
                className="btn btn-outline-primary"
                id="new-insurance-option-button"
                onClick={() => openModal()}
              >
                <i className="fa fa-plus"></i>
                &nbsp;Create Insurance Option
              </button>
            </div>
            <DataTable value={insuranceOptions}>
              <Column field="title" header="Title" />
              <Column field="daily_price" header="Daily Price($)" />
              <Column field="deductible_price" header="Deductible Price($)" />
              <Column field="financial_responsibility" header="Financial Responsibility" />
              <Column
                header="Actions"
                body={(rowData) => (
                  <div>
                    <button
                      className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                      onClick={() => openModal(rowData)}
                    >
                      {' '}
                      <i className="fas fa-edit"></i>
                      &nbsp;Edit
                    </button>
                    <button
                      className="btn btn-outline-danger ml-auto btn-sm mb-2"
                      onClick={() =>
                        confirmDialog({
                          message: 'Are you sure you want to delete this Insurance Option?',
                          header: 'Delete Insurance Option',
                          icon: 'pi pi-exclamation-triangle',
                          accept: async () => {
                            const url = Routes.website_insurance_option_path(websiteSlug, rowData.id)
                            const response = await fetch(url, {
                              method: 'DELETE',
                              headers: standardHeaders,
                            })
                            if (response) {
                              showToast(notification, 'success', 'Insurance Option Deleted')
                              fetchInsuranceOptions()
                            } else {
                              showToast(notification, 'error', 'Error Insurance Option')
                            }
                          },
                        })
                      }
                    >
                      <i className="fas fa-trash"></i>
                      &nbsp;Delete
                    </button>
                  </div>
                )}
              ></Column>
            </DataTable>
            <Dialog
              header={
                selectedInsuranceOption ? 'Edit Insurance Option' : 'New Insurance Option'}
              visible={isModalOpen}
              style={{ width: '50vw' }}
              onHide={closeModal}
            >
              <InsuranceOptionForm
                insuranceOption={selectedInsuranceOption}
                onSave={handleSave}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </PrimeReactProvider>
  )
}

export default InsuranceOptions