import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../Loading'
import { Switch, Select, Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const carGradeOptions = ['A', 'B', 'C', 'D', 'E'].map(o => ({ value: o, label: o }))
const minimumLeadOptions = [1, 2, 3, 4, 5, 6, 7, 8].map(o => ({ value: o, label: `${o} leads` }))
const dayOptions = [1, 2, 3, 4, 5, 6, 7, 8].map(o => ({ value: o, label: `${o} days` }))
const hourOptions = [12, 24, 36, 48, 72, 84, 96, 120, 144, 168].map(o => ({ value: o, label: `${o} hours` }))

const SmartPublisherSettings = ({ smartPublisherRestriction }) => {
  let [loading, setLoading] = useState(false)

  let { dealershipSlug } = useParams()

  let [restrictByGrade, setRestrictByGrade] = useState(smartPublisherRestriction?.restrict_by_grade || false)
  let [restrictByLeadNumber, setRestrictByLeadNumber] = useState(smartPublisherRestriction?.restrict_by_leads || false)
  let [carGradesToRestrict, setCarGradesToRestrict] = useState(smartPublisherRestriction?.car_grades_to_restrict || [])
  let [gradeRestrictionNumberOfDays, setGradeRestrictionNumberOfDays] = useState(smartPublisherRestriction?.grade_restriction_number_of_days || 7)
  let [leadRestrictionNumberOfHours, setLeadRestrictionNumberOfHours] = useState(smartPublisherRestriction?.leads_restriction_number_of_hours || 48)
  let [minimumLeadNumberToRestrict, setMinimumLeadNumberToRestrict] = useState(smartPublisherRestriction?.minimum_lead_number_to_restrict || 3)
  let [leadCountingPeriod, setLeadCountingPeriod] = useState(smartPublisherRestriction?.lead_counting_period || 24)
  let [errors, setErrors] = useState()

  const submit = () => {
    setLoading(true)

    let data = {
      dealership: {
        smart_publisher_restriction_attributes: {
          restrict_by_grade: restrictByGrade,
          restrict_by_leads: restrictByLeadNumber,
          grade_restriction_number_of_days: gradeRestrictionNumberOfDays,
          leads_restriction_number_of_hours: leadRestrictionNumberOfHours,
          lead_counting_period: leadCountingPeriod,
          car_grades_to_restrict: carGradesToRestrict,
          minimum_lead_number_to_restrict: minimumLeadNumberToRestrict,
          ...(smartPublisherRestriction?.id && { id: smartPublisherRestriction.id })
        }
      }
    }

    fetch(`/dealerships/${dealershipSlug}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: standardHeaders
    }).then((response) => {
      if (response.status === 422) {
        return response.json().then((data) => {
          setLoading(false);
          setErrors(data);
          return Promise.reject(data);
        });
      }
      return response.json(); // Handle other statuses normally
    }).then(res => {
      setLoading(false)
    })
  }

  return (
    <div className="p-3 w-100">
      <div className="d-flex mb-2">
        <h4>Smart Publisher Settings</h4>
      </div>

      <div className="box mb-2">
        <div className="py-4 px-3">
          <Switch
            label="Restrict cars by Car Grade"
            value={restrictByGrade}
            onChange={() => setRestrictByGrade(!restrictByGrade)}
            id="restrict-by-grade-switch"
          />
          {(restrictByGrade) && (
            <div className="mt-3">
              <Select
                options={carGradeOptions}
                isMulti
                label="Which car grades would you like to restrict?"
                value={carGradesToRestrict.map(o => ({ value: o, label: o }))}
                onChange={e => {
                  setCarGradesToRestrict(e.map(o => o.value))
                }}
              />
              <Select
                label="How long would you like to restrict these items? (in days)"
                placeholder="Eg. 7 days"
                options={dayOptions}
                value={{ value: gradeRestrictionNumberOfDays, label: `${gradeRestrictionNumberOfDays} days` }}
                onChange={(e) => setGradeRestrictionNumberOfDays(e.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="box">
        <div className="py-4 px-3">
          <Switch
            label="Restrict cars by number of leads"
            value={restrictByLeadNumber}
            onChange={() => setRestrictByLeadNumber(!restrictByLeadNumber)}
            id="restrict-by-leads-switch"
          />
          {(restrictByLeadNumber) && (
            <div className="mt-3">
              <Select
                options={minimumLeadOptions}
                label="How many leads should a car have before we restrict the car?"
                value={{ label: `${minimumLeadNumberToRestrict} leads`, value: minimumLeadNumberToRestrict }}
                onChange={value => setMinimumLeadNumberToRestrict(value.value)}
              />
              <Select
                options={hourOptions}
                label="Select lead counting period (hours)"
                hint="Specify the time window for counting leads"
                value={{ label: `${leadCountingPeriod} hours`, value: leadCountingPeriod }}
                onChange={value => setLeadCountingPeriod(value.value)}
              />
              <Select
                options={hourOptions}
                label="How long would you like to restrict these items? (in hours)"
                placeholder="Eg. 48 hours"
                value={{ label: `${leadRestrictionNumberOfHours} hours`, value: leadRestrictionNumberOfHours }}
                onChange={(e) => setLeadRestrictionNumberOfHours(e.value)}
              />
            </div>
          )}
        </div>
      </div>
      <Errors errors={errors} />
      <div className={"btn btn-outline-secondary mt-3" + (loading ? ' disabled' : '')} onClick={submit} disabled={loading}>
        {loading ? "Loading..." : "Save Changes"}
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [loading, setLoading] = useState(false)
  let [smartPublisherRestriction, setSmartPublisherRestriction] = useState(false)

  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/smart_publisher_settings.json`)
      .then(res => res.json()).then(res => {
        setSmartPublisherRestriction(res)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  return (
    <SmartPublisherSettings smartPublisherRestriction={smartPublisherRestriction} />
  )
}

export default Wrapper