import { useParams } from 'react-router-dom'
import { useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'

import { standardHeaders } from '../entries/utils'
import EnquiryAddonForm, { formatAddonOption } from './EnquiryAddonForm'
import * as Routes from '../../routes'
import { handleResponse } from '../formUtils'
import { fetchOnClient, useQuery } from '../tanstackPlaceholderUtils'
import Loading from '../Loading'
import type { EnquiryAddon, EnquiryAddonsData } from './EnquiryAddonTypes'

const EnquiryAddons = () => {
  const { websiteSlug } = useParams()
  const notification = useRef(null)
  const [selectedEnquiryAddon, setSelectedEnquiryAddon] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading, error, revalidate } = useQuery<EnquiryAddonsData>({
    query: fetchOnClient,
    queryKey: `${Routes.website_enquiry_addons_path(websiteSlug)}.json`,
  })

  const openModal = (enquiryAddon = null) => {
    setSelectedEnquiryAddon(enquiryAddon)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedEnquiryAddon(null)
    setIsModalOpen(false)
  }

  const handleSave = async (enquiryAddon: EnquiryAddon) => {
    if (enquiryAddon.id) {
      const url = Routes.website_enquiry_addon_path(websiteSlug, enquiryAddon.id)
      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify({ enquiry_addon: enquiryAddon }),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Saved Successfully',
        })

        closeModal()
        await revalidate()
      } else {
        notification.current.show({
          severity: 'error',
          summary: 'Unable To Save',
          detail: `A server error occurred when saving the data: ${response.statusText}`,
        })
      }
    } else {
      const url = Routes.website_enquiry_addons_path(websiteSlug)
      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ enquiry_addon: enquiryAddon }),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Saved Successfully',
        })
        closeModal()
        await revalidate()
      } else {
        notification.current.show({
          severity: 'error',
          summary: 'Unable To Save',
          detail: `A server error occurred when saving the data: ${response.statusText}`,
        })
      }
    }
  }

  const formTypeBody = (rowData: EnquiryAddon) => {
    return <div>{formatAddonOption(rowData.form_type)}</div>
  }

  const priceOptionBody = (rowData: EnquiryAddon) => {
    return <div>{formatAddonOption(rowData.price_option)}</div>
  }

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }
  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }

  const enquiryAddons = data?.data?.enquiry_addons ?? []
  const priceOptions = data?.data?.price_options ?? []
  const formTypes = data?.data?.form_types ?? []

  return (
    <div>
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="px-3 py-1">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3 d-flex align-items-start justify-content-between">
              <div>
                <h2>Enquiry Addons</h2>
                <p>Additional items that users can add to their enquiry such as accessories.</p>
              </div>
              <button
                className="btn btn-outline-primary"
                id="new-enquiry-addon-button"
                onClick={() => openModal()}
              >
                <i className="fa fa-plus"></i>
                &nbsp;Create Enquiry Addon
              </button>
            </div>
            <DataTable value={enquiryAddons}>
              <Column field="title" header="Title" />
              <Column field="description" header="Description" />
              <Column field="form_type" body={formTypeBody} header="Form Type" />
              <Column field="price" header="Price($)" />
              <Column field="price_option" body={priceOptionBody} header="Price Option" />
              <Column
                header="Actions"
                body={(rowData) => (
                  <div>
                    <button
                      className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                      onClick={() => openModal(rowData)}
                    >
                      <i className="fas fa-edit"></i>
                      &nbsp;Edit
                    </button>
                    <button
                      className="btn btn-outline-danger ml-auto btn-sm mb-2"
                      onClick={() =>
                        confirmDialog({
                          message: 'Are you sure you want to delete this Enquiry Addon?',
                          header: 'Delete Enquiry Addon',
                          icon: 'pi pi-exclamation-triangle',
                          accept: async () => {
                            try {
                              const url = Routes.website_enquiry_addon_path(websiteSlug, rowData.id)
                              const response = await fetch(url, {
                                method: 'DELETE',
                                headers: standardHeaders,
                              })
                              await handleResponse(response)
                              notification.current.show({
                                severity: 'success',
                                summary: 'Successfully deleted',
                              })
                              await revalidate()
                            } catch (error) {
                              notification.current.show({
                                severity: 'error',
                                summary: 'Unable to Delete Addon',
                                detail: error.message,
                              })
                            }
                          },
                        })
                      }
                    >
                      <i className="fas fa-trash"></i>
                      &nbsp;Delete
                    </button>
                  </div>
                )}
              ></Column>
            </DataTable>
            <Dialog
              header={selectedEnquiryAddon ? 'Edit Enquiry Addon' : 'New Enquiry Addon'}
              visible={isModalOpen}
              style={{ width: '50vw' }}
              onHide={closeModal}
            >
              <EnquiryAddonForm
                enquiryAddon={selectedEnquiryAddon}
                priceOptions={priceOptions}
                formTypes={formTypes}
                onSave={handleSave}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EnquiryAddons
