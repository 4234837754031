import { useClearRefinements } from 'react-instantsearch'

function ClearRefinements({ translations, excludedAttributes }) {
  const { refine, isRefined } = useClearRefinements({ excludedAttributes })

  
  const handleClick = () => {
    localStorage.removeItem(`toggle_${window.dealer_slug}`)
    localStorage.removeItem(`refinementList_${window.dealer_slug}`)
    localStorage.removeItem(`sortBy_${window.dealer_slug}`)
    localStorage.removeItem(`hierarchicalMenu_${window.dealer_slug}`)
    refine()
  }

  return (
    <div className="ais-ClearRefinements">
      <button
        className="ais-ClearRefinements-button"
        onClick={handleClick}
      >
        {translations.resetButtonText}
      </button>
    </div>
  )
}

export default ClearRefinements
