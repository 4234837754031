import React from 'react'
import * as Routes from '../../routes'
import { Dealership, LeadCluster } from './types'

interface LeadClustersSectionProps {
  dealership: Dealership
  leadClusters: LeadCluster[]
}

const LeadClustersSection: React.FC<LeadClustersSectionProps> = ({ dealership, leadClusters }) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-bolt mr-1" />
      Lead Clusters
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Sent At</th>
        </tr>
      </thead>
      <tbody>
        {leadClusters.map((cluster) => (
          <tr key={cluster.id}>
            <td>
              <i className="fa fa-bolt mr-1" />
              <a href={Routes.dealership_lead_cluster_path(dealership, cluster.id)}>
                {cluster.category}
              </a>
            </td>
            <td>{cluster.created_at}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default LeadClustersSection
