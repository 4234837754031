import { useParams } from 'react-router-dom'

import Loading from '../../Loading'
import { RedirectsFormData, WebsiteRedirectsForm } from './RedirectsForm'
import { fetchOnClient, useQuery } from '../../tanstackPlaceholderUtils'

const WebsiteRedirectsPage = () => {
  const { websiteSlug } = useParams()
  const { data, loading, error } = useQuery<RedirectsFormData>({
    query: fetchOnClient,
    queryKey: `/websites/${websiteSlug}/redirects.json`,
  })

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }
  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }
  return (
    <div className="w-100 p-3">
      <div className="container-fluid">
        <h4>Website Redirects</h4>
        <p>Add custom redirects to this website</p>
        <div className="row my-5">
          <div style={{ maxWidth: '1200px' }} className="col-12">
            <WebsiteRedirectsForm websiteSlug={websiteSlug} defaultValues={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteRedirectsPage
