import { useState, useEffect, useCallback, useMemo } from 'react'
import * as Routes from '../../routes'
import { getDealership } from './utils'
import { Location, User } from './types'

type SelectLocationsProps = {
  handleUpdateSelectedLocation: (arg0: Location) => void
  currentUser: User
}

const SelectLocations: React.FC<SelectLocationsProps> = ({
  handleUpdateSelectedLocation,
  currentUser,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [selectedLocation, setSelectedLocation] = useState<Location>({ id: 0, name: '' })
  const dealership = getDealership()

  const allLocations: Location = { id: -1, name: 'All Locations' }

  const fetchLocations = useCallback(async (): Promise<void> => {
    if (dealership?.slug) {
      setLoading(true)
      const response = await fetch(
        `${Routes.dealership_locations_path(dealership?.slug)}?with_lead=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      const locationData: Location[] = await response.json()
      setLoading(false)
      const userLocations = currentUser?.admin
        ? []
        : currentUser?.dealerships[dealership.id].locations
      if (currentUser?.admin || userLocations?.length === 0) {
        setLocations([allLocations, ...locationData])
      } else {
        // retreive common locations between userLocations and locationData
        const commonLocations = locationData.filter((location) => {
          return userLocations.some((userLocation) => userLocation.id === location.id)
        })
        if (commonLocations.length > 0) {
          if (commonLocations.length === 1) {
            setSelectedLocation(commonLocations[0])
            handleUpdateSelectedLocation(commonLocations[0])
          } else {
            setLocations([allLocations, ...commonLocations])
          }
        }
      }
    }
  }, [dealership?.slug])

  useEffect(() => {
    fetchLocations()
  }, [fetchLocations])

  const memoizedLocations = useMemo(() => {
    return locations
  }, [locations])

  const onLocationChange = useCallback(
    (event: { target: { value: string } }): void => {
      const newSelectedLocation = memoizedLocations.find(
        (location) => location?.name === event.target.value
      )
      if (newSelectedLocation) {
        setSelectedLocation(newSelectedLocation)
        handleUpdateSelectedLocation(newSelectedLocation)
      }
    },
    [memoizedLocations, handleUpdateSelectedLocation]
  )

  return (
    <>
      {!loading && memoizedLocations.length > 0 && (
        <div className="input-form-group mr-2">
          <select
            value={selectedLocation?.name}
            onChange={onLocationChange}
            className="form-control"
            id="location-select"
          >
            <option value="">Location...</option>
            {memoizedLocations?.map((location) => (
              <option key={location?.id} value={location?.name}>
                {location?.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}

export default SelectLocations
