import React from 'react';
import { useMenu } from 'react-instantsearch';

function MenuSelect(props) {
  const { items, refine } = useMenu(props, {
    $$widgetType: 'custom.menuSelect',
  });

  return (
    <div className="ais-MenuSelect">
      <select
        className="ais-MenuSelect-select"
        onChange={(event) => refine(event.target.value)}
        value={items.filter(item => item.isRefined)[0]?.value || ""}
      >
        <option value="" className="ais-MenuSelect-option">
          {props.seeAllOption}
        </option>
        {items.sort((a, b) => b.count - a.count).map((item) => (
          <option
            key={item.label}
            className="ais-RefinementList-option"
            value={item.value}
          >
            {item.label} ({item.count})
          </option>
        ))}
      </select>
    </div >
  );
}

export default MenuSelect