import React from 'react'

const ItemStatus = (testDrive) => {
  const status = testDrive.status
  const color = status === 'Ready' ? 'text-info' : status === 'Completed' ? 'text-success' : 'text-warning'
  const icon  = status === 'Ready' ? 'fas fa-key' : status === 'Completed' ? 'fas fa-steering-wheel' : 'fas fa-spinner'
  return (
      <div className={color} id={`status-${testDrive.id}`}>
        <i className={icon}></i> &nbsp;
        {status}
      </div>
  )
}
export default ItemStatus