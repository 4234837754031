import { RefinementList } from 'react-instantsearch'
import { FacetDropdown } from '../../facetDropdown/components/FacetDropdown'

const RefinementListSelect = ({ attribute, buttonText, ...props }) => (
  <FacetDropdown buttonText={buttonText}>
    <RefinementList attribute={attribute} {...props} />
  </FacetDropdown>
)

export default RefinementListSelect
