import { useState, useEffect } from "react"
import { Dialog } from "primereact/dialog"
import { Tooltip } from 'react-tooltip'
import { StockRule, OverwriteSwitch } from './ThirdPartyRestriction'

const Footer = ({ hit, setCar }) => {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <OverwriteSwitch hit={hit} setCar={setCar} />
    </div>
  )
}

const RestrictionsPopup = ({ hit, setCar }) => {
  let [visible, setVisible] = useState(false)
  let [stockRules, setStockRules] = useState(false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      fetch(`/cars/${hit.id}/stock_rules.json`)
        .then(res => res.json())
        .then(data => {
          setStockRules(data)
          setLoading(false)
        })
    }
  }, [visible])

  return (
    <>
      {(hit.restrict_third_party || hit.restricted_on_all_websites_from_stock_rule || hit.restricted_on_group_site) && (
        <>
          <div className="d-none d-lg-flex align-items-center mr-2" onClick={() => setVisible(true)}>
            <i className={`fa fa-exclamation-triangle ${hit.ignore_manufacturer_restriction ? 'text-warning' : 'text-danger'}`} id={`restrictions-${hit.id}`} />
            <Tooltip
              anchorSelect={`#restrictions-${hit.id}`}
              place="bottom"
              style={{ zIndex: 10000 }}
            >
              Third party restrictions apply
              {hit.ignore_manufacturer_restriction && <><br></br>However are curently being <b>ignored</b></>}
            </Tooltip>
          </div>
          <Dialog
            header="Restrictions"
            visible={visible}
            style={{ width: "50vw" }}
            modal={true}
            onHide={() => setVisible(false)}
            dismissableMask={true}
            contentClassName="p-0"
            footer={() => <Footer hit={hit} setCar={setCar} />}
          >
            {loading && (
              <div className="d-none d-lg-flex align-items-center p-3">
                <i className="fa fa-spinner fa-spin text-primary" /> Loading...
              </div>
            )}
            {stockRules && (stockRules.map(stockRule => (
              <StockRule rule={stockRule} key={`stock-rule-${stockRule.id}`} />
            )))}
          </Dialog>
        </>
      )}
    </>
  )
}

export default RestrictionsPopup