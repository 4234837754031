import React, { createContext, useState, ReactNode } from 'react'
import { AnalyticsBlockConfig } from '../types'
import { getConfig, isEmptyObject } from '../utils'

type ConfigContextType = {
  config: AnalyticsBlockConfig
  handleUpdateConfig: (newConfig: AnalyticsBlockConfig) => void
}

export const ConfigContext = createContext<ConfigContextType>({
  config: {} as AnalyticsBlockConfig,
  handleUpdateConfig: () => {},
})

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<AnalyticsBlockConfig>(
    !isEmptyObject(getConfig())
      ? getConfig()
      : { layoutSize: 'large', defaultTimeRangeOverride: [] }
  )

  const handleUpdateConfig = (newConfig: AnalyticsBlockConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }))
  }

  return (
    <ConfigContext.Provider value={{ config, handleUpdateConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}
