import { Title, Contact, Status, Location } from '../testDrives/Search'

const TestDrivesBox = ({ testDrives }) => {
  return null // TODO: fix this table and re-enable it
  return (
    <div className="box p-3 mb-3">
      <h4 className='mb-1'><i className='fa fa-steering-wheel mr-1'></i> Test Drives</h4>
      <div className="table-responsive">
        <table className="table mb-0" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Contact</th>
              <th>Status</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {testDrives.map((testDrive) => (
              <tr key={`testDrive-${testDrive.id}`}>
                <td><Title testDrive={testDrive} /></td>
                <td><Contact testDrive={testDrive} /></td>
                <td><Status testDrive={testDrive} /></td>
                <td><Location testDrive={testDrive} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TestDrivesBox