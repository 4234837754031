import { useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Toast } from 'primereact/toast'
import { useHits } from 'react-instantsearch'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'

import { formatPhone, truncateString, standardHeaders } from '../entries/utils'
import LeadEventStats from './LeadEventStats'
import showToast from '../shared/ShowToast'
import { updateLeadUserId, updateLeadState } from '../leads/utils'
import OwnerSelect from '../leads/OwnerSelect'
import StateSelect from '../leads/StateSelect'
import LeadSourceBadge from './LeadSourceBadge'
import { Tooltip } from 'react-tooltip'
import RequiresApproval from '../leads/RequiresApproval'
import * as Routes from '../../routes'
import LeadMergeForm from './LeadMergeForm'

import { LeadClusterContext } from '../contexts'

const Item = ({ item }) => {

  return (
    <>
      {item?.image_url && <img src={item?.image_url} height="40" className="rounded mr-2" loading="lazy"></img>}
      <div style={{ lineHeight: 1.2 }}>
        <div className="mb-0">{item?.make} {truncateString((item?.model || ''), 30)}</div>
        <small className="text-secondary">
          {item?.year}
          {item?.stocknum && (
            <>
              <span id={`item-stocknum-${item?.id}`}> - {item?.stocknum}</span>
              <Tooltip
                anchorSelect={`#item-stocknum-${item?.id}`}
                place="bottom"
                style={{ zIndex: 10000 }}
              >
                Stock no
              </Tooltip>
            </>
          )}
        </small>
      </div>
    </>
  )
}

const Category = ({ hit, leadCluster }) => {
  const { category } = hit

  if (leadCluster.leads?.length > 1) {
    if (leadCluster.leads.every(i => i.category === leadCluster.category)) {
      return (
        <>{leadCluster.leads.length} x {leadCluster.category.replace('Enquiry', '')} leads</>
      )
    }

    return (
      <>{leadCluster.leads.length} leads</>
    )
  }

  return (
    <>{category?.replace('Enquiry', '')}</>
  )
}

const Hit = ({
  hit,
  selectedRows,
  handleRowSelection,
  onStateChange,
  onOwnerChange,
  bulkChangeUserId,
  bulkChangeState,
  leadStatusOptions,
  users
}) => {
  let [leadCluster, setLeadCluster] = useState(hit)
  const { dealership_slug, id, user_id, lead_state, credit_score, item, created_at, last_lead_created_at, name, has_error, email, phone } = hit
  let url = `/dealerships/${dealership_slug}/lead_clusters/${id}`

  const selected = selectedRows.includes(id)
  const disabledSelects = selectedRows.length > 0 && !selected
  const [userId, setUserId] = useState(user_id)
  let newUserId = selected ? (bulkChangeUserId || user_id) : userId
  let newState = selected ? (bulkChangeState || lead_state) : null
  const contextValue = useMemo(() => ({ leadCluster, setLeadCluster }), [leadCluster, setLeadCluster])
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <LeadClusterContext.Provider value={contextValue}>
      <tr className={(selected ? "selected " : " ") + (leadCluster.requires_approval ? 'bg-light-red' : '')}>
        <td>
          <input
            type="checkbox"
            checked={selected}
            onChange={() => handleRowSelection(id)}
          />
        </td>
        <td>
          <div>
            <Link to={url} state={hit}>
              <Category hit={hit} leadCluster={leadCluster} />
            </Link>
          </div>
          <small className="text-secondary">{moment(last_lead_created_at || created_at).fromNow()}</small>
          <small className="text-secondary"> <br />{moment(last_lead_created_at || created_at).tz(userTimezone).format('DD/MM/YYYY hh:mm A')}</small>
          <div>
            {has_error && <div className="badge badge-danger mr-1">Error</div>}
            <LeadSourceBadge />
          </div>
        </td>
        <td className="d-none d-lg-table-cell">
          <div className="d-flex">
            {hit.items && hit.items.length > 0 ? (<Item item={hit.items[0]} />) : <Item item={item} />}
          </div>
        </td>
        <td className="small">
          <div>{truncateString(name, 30)}</div>
          {email ? (
            <a href={`mailto:${email}`}>{email}</a>
          ) : <span className="text-secondary">No Email</span>}
          {phone && (
            <div className="mt-2">{formatPhone(phone)}</div>
          )}
          {credit_score && (
            <div className="mt-2">
              <span className="badge badge-info">Credit Score: {credit_score}</span>
            </div>
          )}
        </td>
        <td>
          <OwnerSelect
            hit={hit}
            onOwnerChange={async (leadId, newUserId) => {
              setUserId(newUserId)
              await onOwnerChange(leadId, newUserId)
            }}
            selectedValue={newUserId || user_id}
            disabled={disabledSelects}
            users={users}
          />
        </td>
        <td>
          {leadStatusOptions && <StateSelect
            hit={hit}
            attribute="lead_state"
            onStateChange={onStateChange}
            selectedValue={newState || lead_state}
            disabled={disabledSelects}
            leadStatusOptions={leadStatusOptions}
            leadCluster={leadCluster}
            setLeadCluster={setLeadCluster}
          />}
          <RequiresApproval />
        </td>
        <td className="d-none d-xl-table-cell">
          <div className="mt-1">
            <LeadEventStats />
          </div>
        </td>
      </tr >
    </LeadClusterContext.Provider>
  )
}

const Hits = ({ leadStatusOptions, users, recentlyUpdated = [] }) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [bulkChangeUserId, setBulkChangeUserId] = useState(null)
  const [bulkChangeState, setBulkChangeState] = useState(null)
  const [hitsState, setHitsState] = useState([])
  const notification = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedLeadClusters, setSelectedLeadClusters] = useState([])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const { hits } = useHits()

  useEffect(() => {
    setHitsState(hits)
  }, [hits])

  const handleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  const deleteLeadAPI = (leadClusterId) => {
    return fetch(Routes.dealership_lead_cluster_path(window.dealership?.id, leadClusterId), {
      method: 'DELETE',
      headers: standardHeaders
    })
  }

  const removeLeadsFromSearchIndex = (dealership_slug, leadIds) => {
    return fetch(`${window.location.origin}/dealerships/${dealership_slug}/leads/remove_leads_from_search_index`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({ lead_ids: leadIds })
    })
  }

  const handleOwnerChange = async (leadId, newUserId) => {
    setBulkChangeUserId(newUserId)
    await updateLeadUserId(leadId, newUserId)

    selectedRows.forEach(rowId => {
      if (rowId !== leadId) { // Avoid updating the lead that triggered the change
        updateLeadUserId(rowId, newUserId)
      }
    })
    const updatedHits = hitsState.map(hit => {
      if (selectedRows.includes(hit.id)) {
        return { ...hit, user_id: newUserId }
      }
      return hit
    })
    setHitsState(updatedHits)
  }

  const handleStateChange = async (leadId, newState, leadCluster, setLeadCluster) => {
    setBulkChangeState(newState)
    await updateLeadState(leadId, newState)
      .then(r => r.json())
      .then(res => {
        if (setLeadCluster && leadCluster) {
          console.log(res)
          setLeadCluster({
            ...leadCluster,
            lead_state: res.lead_state,
            requires_approval: res.requires_approval
          })
        }
      })

    selectedRows.forEach(rowId => {
      if (rowId !== leadId) { // Avoid updating the lead that triggered the change
        updateLeadState(rowId, newState)
      }
    })
    const updatedHits = hitsState.map(hit => {
      if (selectedRows.includes(hit.id)) {
        return { ...hit, lead_state: newState }
      }
      return hit
    })
    setHitsState(updatedHits)
  }

  const handleBulkDelete = () => {
    if (!window.confirm('Are you sure you want to delete these lead clusters?')) {
      return
    }
    selectedRows.forEach(rowId => {
      deleteLeadAPI(rowId)
    })
    showToast(notification, 'success', 'Lead clusters deleted!', '')

    const lead = leadFromRow(selectedRows[0])
    const dealership_slug = lead.dealership_slug
    removeLeadsFromSearchIndex(dealership_slug, selectedRows)
    setHitsState(hitsState.filter(hit => !selectedRows.includes(hit.id)))
    setSelectedRows([])
  }

  const handleMerge = async (mergeData) => {
    // if (!window.confirm('This will move the leads and cars of interest of the latest lead cluster into the earlier one, then delete the latest lead cluster. Any contact details from the deleted lead cluster will be lost. Are you sure you want to proceed?')) {
    //   return
    // }
    const selectedDetails = mergeData.selectedDetails
    const leadIds = selectedLeadClusters.map(lead => lead.id)
    const lead_cluster_id_to_keep = selectedDetails.leadCluster
    const lead_cluster_id_to_delete = leadIds.find(id => id !== lead_cluster_id_to_keep)
    const hitToKeep = hitsState.find(hit => hit.id === lead_cluster_id_to_keep)
    const hitToDelete = hitsState.find(hit => hit.id === lead_cluster_id_to_delete)
    // Merge the hits
    const mergedHit = {
      ...hitToKeep,
      leads: [...hitToKeep.leads, ...hitToDelete.leads],
      items: [...hitToKeep.items, ...hitToDelete.items]
    };
    const dealership_slug = selectedLeadClusters[0].dealership_slug
    if (dealership_slug && lead_cluster_id_to_keep) {
      const url = Routes.merge_dealership_lead_cluster_path(dealership_slug, lead_cluster_id_to_keep)
      fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ lead_cluster_id_to_delete: lead_cluster_id_to_delete, email: selectedDetails.email, phone: selectedDetails.phone, first_name: mergeData.first_name, last_name: mergeData.last_name })
      })
        .then(res => res.json())
        .then(data => {
          showToast(notification, 'success', 'The lead clusters were merged successfully', '')
          closeModal()

          // Update the hits state
          const updatedHits = hitsState
            .filter(hit => hit.id !== lead_cluster_id_to_delete)
            .map(hit => hit.id === lead_cluster_id_to_keep ? mergedHit : hit)

          setHitsState(updatedHits)
          setSelectedRows([])
        })
        .catch(error => {
          showToast(notification, 'error', 'Error merging the lead clusters', '')
          console.error(error)
        })
    }
  }

  const openMergeModal = () => {
    setSelectedLeadClusters(selectedRows.map(rowId => leadFromRow(rowId.toString())))
    // Open Dialog with LeadMergeForm
    openModal()

  }

  const leadFromRow = (rowId) => {
    return hitsState.find(hit => hit.id === rowId)
  }

  return (
    <div className="bg-white my-2">
      <Toast ref={notification} />
      {isModalOpen && (
        <Dialog
          header="Merge Lead Clusters"
          visible={isModalOpen}
          style={{ minWidth: '1000px' }}
          onHide={closeModal}
          dismissableMask={true}
        >
          <LeadMergeForm
            onSave={handleMerge}
            selectedLeadClusters={selectedLeadClusters}
          />
        </Dialog>
      )}
      <div className="table-responsive">
        <table className="table table-bordered rounded mb-0">
          <thead>
            <tr>
              <th>
                {selectedRows.length > 0 && (
                  <>
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={() => setSelectedRows([])}
                    />
                    <button type="button" className="btn btn-danger position-fixed lead-cluster-trash-button lead-cluster-shadow-button">
                      <i role="button" style={{ color: 'dark-red' }} title="Delete the selected lead clusters" className="fa fa-trash" onClick={handleBulkDelete} />
                    </button>
                  </>
                )}

                {selectedRows.length === 2 && (
                  <>
                    <button type="button" className="btn btn-success position-fixed lead-cluster-merge-button lead-cluster-shadow-button" onClick={openMergeModal}>
                      <i role="button" title="Merge the selected lead clusters" className="fa fa-merge" />
                      &nbsp;Merge
                    </button>
                  </>
                )}
              </th>
              <th>Category</th>
              <th className="d-none d-lg-table-cell">Item</th>
              <th>
                <i className="fa fa-envelope mr-1" /> Details
              </th>
              <th>
                <i className="fa fa-user mr-1" /> Assigned to
              </th>
              <th>Status</th>
              <th className="d-none d-xl-table-cell">
                Events
              </th>
            </tr>
          </thead>
          <tbody>
            {hitsState.map(hit => {
              let item = hit
              let recentlyUpdatedData = recentlyUpdated.filter(l => l.id == hit.id)[0]
              if (recentlyUpdatedData) {
                item = {
                  ...hit,
                  ...recentlyUpdatedData
                }
              }
              return (
                <Hit
                  hit={item}
                  key={item.id}
                  selectedRows={selectedRows}
                  handleRowSelection={handleRowSelection}
                  onOwnerChange={handleOwnerChange}
                  onStateChange={handleStateChange}
                  bulkChangeUserId={bulkChangeUserId}
                  bulkChangeState={bulkChangeState}
                  leadStatusOptions={leadStatusOptions}
                  users={users}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleRowSelection: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired,
  bulkChangeUserId: PropTypes.number,
  bulkChangeState: PropTypes.string,
  leadStatusOptions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
}

Hits.propTypes = {
  leadStatusOptions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
}

export default Hits