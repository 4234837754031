import { useContext, useEffect, useState } from "react"
import { CurrentUserContext } from "../contexts"
import { ProgressSpinner } from "primereact/progressspinner"

const isGardx = window.location.hostname.includes("gardx")

const Dealership = ({ dealership }) => {
  let currentUser = useContext(CurrentUserContext)

  const link = isGardx
    ? `/dealerships/${dealership.slug}/warranties`
    : `/dealerships/${dealership.slug}`

  return (
    <a href={link} className="index-dealership-box">
      <div className="card w-100 mb-2 overflow-hidden">
        <div className="card-body mb-0 pb-0 d-flex align-items-center">
          {dealership.logo_url && (
            <div className="mr-3">
              <img
                src={dealership.logo_url}
                height={30}
                className="rounded brand-logo"
                style={{ maxHeight: '30px' }}
                alt={dealership.name}
              />
            </div>
          )}
          <h6 className="m-0 text-dark unstyled">{dealership.name}</h6>
          <div className="ml-auto mr-10">
            <i className="fa fa-angle-right"></i>
          </div>
        </div>
        <hr className="mb-0" />
        {currentUser?.dealerships[dealership.id]?.manager && (
          <div className="bg-light p-2">
            {dealership.websites?.map((website) => (
              <div key={website.id}>
                <a href={`/dealerships/${dealership.id}/websites/${website.id}`}>
                  {website.name}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </a>
  )
}

const LoginForm = ({ ssoEnabled, identityProvidersJson, isMobileClient }) => {
  const identityProviders = JSON.parse(identityProvidersJson)
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  return (
    <div className="card w-100">
      <div className="card-header">
        <h2>Log in</h2>
      </div>
      <div className="card-body">
        <form action="/users/sign_in" method="post">
          <input type="hidden" name="authenticity_token" value={csrf} />
          <div className="form-group">
            <label className="form-control-label email required" htmlFor="user_email">Email <abbr title="required">*</abbr></label>
            <input
              type="email"
              name="user[email]"
              autoFocus
              placeholder="Email"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label className="form-control-label password required" htmlFor="user_password">Password <abbr title="required">*</abbr></label>
            <input
              type="password"
              name="user[password]"
              placeholder="Password"
              className="form-control"
            />
          </div>
          <button
            type="submit"
            className="btn btn-secondary"
          >
            Log in
          </button>
        </form>
        <div className="mt-2">
          <div><a href='/users/password/new'>Forgot your password?</a></div>
          <div><a href='/users/unlock/new'>Didn't receive unlock instructions?</a></div>
          {(ssoEnabled) && ( // SSO is always false for the mobile app
            <div><a rel="nofollow" data-method="post" href="/users/auth/saml">Sign in with SSO</a></div>
          )}

          {isMobileClient && ( // Give the users a dropdown menu to select the SSO provider
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sign in with SSO
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {identityProviders.map((identityProvider) => (
                  <a
                    key={identityProvider.name}
                    className="dropdown-item"
                    rel="nofollow"
                    data-method="post"
                    href={`/users/auth/saml?identity_provider_id=${identityProvider.id}`}>
                    {identityProvider.name}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}


const IndexPage = ({ ssoEnabled, identityProvidersJson }) => {
  let [data, setData] = useState(null)
  let [loading, setLoading] = useState(false)
  let currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    setLoading(true)
    fetch('/welcome/app_details.json')
      .then(res => res.json())
      .then(data => {
        setData(data)
        setLoading(false)
      })
  }, [])


  const renderDealerships = () => {
    return data?.dealerships?.map((dealership) => <Dealership dealership={dealership} key={`dealership-${dealership.id}`} />)
  }

  if (loading) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <ProgressSpinner />
      </div>
    )
  }

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 align-items-center d-flex mh-100">
            <div className="d-flex align-items-center justify-content-center flex-fill">
              <div className="text-center">
                <img src={data?.image_url} className="py-4 logo-index" alt={data?.app_name} />
                <div className="mb-4">
                  <h1>{data?.app_name}</h1>
                  <h5 className="text-secondary">{data?.subtitle}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            {currentUser ? (
              <div className="d-flex flex-column w-100">{renderDealerships()}</div>
            ) : (
              <LoginForm ssoEnabled={ssoEnabled} identityProvidersJson={identityProvidersJson} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
