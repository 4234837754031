import * as Routes from '../../../routes'
import { useEffect, useState } from 'react'
import { ModuleComponentConfig, ModuleItem, Campaign } from '../types'
import Select, { components } from 'react-select'
import { fetchModuleData, getTotalForTimePeriod } from '../utils'
import { useParams } from 'react-router-dom'
import {
  useAnalyticsDashboard,
  usePreviewData,
  useTabs,
  useTimeRangeLocation,
} from '../contexts/hooks'

const FilterCampaigns: React.FC<{
  item: ModuleItem
  moduleConfig: ModuleComponentConfig
  includedCampaigns: Campaign[]
  excludedCampaigns: Campaign[]
  setIncludedCampaigns: (campaigns: Campaign[]) => void
  setExcludedCampaigns: (campaigns: Campaign[]) => void
}> = ({
  item,
  moduleConfig,
  includedCampaigns,
  excludedCampaigns,
  setIncludedCampaigns,
  setExcludedCampaigns,
}) => {
  const { timeRange } = useTimeRangeLocation()
  const { selectedTab, tabLayouts } = useTabs()
  const { dealership } = useAnalyticsDashboard()
  const { setEditingPreviewData, setEditingPreviewTotal } = usePreviewData()

    const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const websiteSlug = useParams<{ websiteSlug: string }>().websiteSlug

    const googleAdsModules = ['Google Adwords Ads', 'Google Display Ads', 'Google VLA Ads']
    const provider =
      moduleConfig?.group === 'Facebook Ads'
        ? 'Facebook'
        : googleAdsModules.includes(moduleConfig?.group)
          ? 'Google'
          : null

  const getAllCampaigns = async (websiteSlug: string): Promise<void> => {
    try {
      setLoading(true)
      const URL = Routes.social_ad_campaigns_dealership_social_ad_accounts_path(
        dealership?.id,
        { provider: provider, website_slug: websiteSlug } // , website_slug: websiteSlug
      )
      const response = await fetch(URL)
      const data = await response.json()
      if (response.ok) {
        setAllCampaigns(data?.campaigns)
      } else console.error('Error:', data)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

    // Custom MultiValueRemove component to disable the delete button when only one item is selected
    const MultiValueRemove = (props) => {
      // Disable the remove button if there's only one item left
      if (props.selectProps.value.length === 1) {
        return null
      }
      // Otherwise, render the default remove button
      return <components.MultiValueRemove {...props} />
    }

    const handleAddIncludedCampaigns = (selectedOptions: { value: Campaign }[]): void => {
      const selectedValues = selectedOptions.map((option) => option.value)
      setIncludedCampaigns(selectedValues?.sort((a, b) => a.name.localeCompare(b.name)))
      const removedCampaigns = allCampaigns.filter(
        (category) => !selectedValues.some((c) => c.externalId === category.externalId)
      )
      setExcludedCampaigns([...removedCampaigns])
    }

    useEffect(() => {
      getAllCampaigns(websiteSlug)
    }, [])

    useEffect(() => {
      if (allCampaigns.length === 0) return

      const currentModule = tabLayouts[selectedTab]?.find((tab) => tab.module === item.module)
      const newIncludedCampaigns = currentModule?.filters?.adCampaigns || allCampaigns

      setIncludedCampaigns(
        newIncludedCampaigns.filter((c) =>
          allCampaigns.some((campaign) => c.externalId === campaign.externalId)
        )
      )
      setExcludedCampaigns(
        allCampaigns.filter(
          (campaign) => !newIncludedCampaigns.some((c) => c.externalId === campaign.externalId)
        )
      )
    }, [allCampaigns, tabLayouts, selectedTab, item.module])

    useEffect(() => {
      fetchModuleData({
        module: item?.module,
        timeRange: timeRange,
        adCampaigns: includedCampaigns,
        selectedTab,
      }).then((data) => {
        setEditingPreviewData((prevState) => {
          return {
            ...prevState,
            [item?.module]: data[item?.module],
          }
        })
      })
      if (provider === 'Facebook' || provider === 'Google') {
        getTotalForTimePeriod(item?.module, timeRange, selectedTab, includedCampaigns).then((data) =>
          setEditingPreviewTotal((prevState) => {
            return {
              ...prevState,
              [item?.module]: data,
            }
          })
        )
      }
    }, [includedCampaigns, excludedCampaigns])

    return (
      <>
        {!loading ? (
          <div className="d-flex w-75">
            <span>
              <h5 className="mr-3 mb-0">Filter campaigns:</h5>
            </span>
            <Select
              isMulti
              isClearable={false}
              placeholder="Select Campaign"
              value={includedCampaigns.map((campaign) => ({
                label: campaign.name,
                value: campaign,
              }))}
              options={excludedCampaigns.map((campaign) => ({
                label: campaign.name,
                value: campaign,
              }))}
              className="mr-2"
              onChange={handleAddIncludedCampaigns}
              onKeyDown={(event) => {
                // Prevent clearing the last item using backspace
                if (event.key === 'Backspace' && includedCampaigns.length === 1) {
                  event.preventDefault()
                }
              }}
              components={{ MultiValueRemove }} // Override the default component
              styles={{
                option: (provided) => ({
                  ...provided,
                  textAlign: 'left', // Aligns options text to the left
                }),
              }}
            />
          </div>
        ) : null}
      </>
    )
  }

export default FilterCampaigns
