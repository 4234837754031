import { useRefinementList } from 'react-instantsearch';
import { RefinementList } from 'react-instantsearch';

// this RefinemntList will only render if there are items available
const ConditionalRefinementList = ({ attribute }) => {
  const { items } = useRefinementList({ attribute });

  // Only render if there are items available
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="search-panel" id="search-status">
      <h5>DMS Status</h5>
      <RefinementList attribute={attribute} />
    </div>
  );
};

export default ConditionalRefinementList;