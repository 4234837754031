import LoadingBoxes from '../../entries/LoadingBoxes'
import ChartComponent from '../Charts/ChartComponent'
import type { ModuleProps, TimeRange } from '../types'
import { useViewportData } from '../hooks'
import {
  formatTotal,
  getModuleComponentFromName,
  getTotalForTimePeriod,
  isEmptyObject,
} from '../utils'
import { useEffect, useState } from 'react'
import { TotalForTimePeriod } from '../TotalForTimePeriod'
import { useAnalyticsDashboard, usePreviewData, useTabs } from '../contexts/hooks'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Facebook Ads',
]

const PaymentsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.number_of_payments > 0 ? (
        <ul className="mt-4">
          <li>
            {data.number_of_payments} payments {timeframe}
          </li>
          <li>
            {data.total_amount} total in payments {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const GenericModuleComponent: React.FC<ModuleProps> = ({
  timeRange,
  item,
  selectedLocation,
  isOverlay = false,
}) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const moduleConfig = getModuleComponentFromName(item?.module)
  const { editingPreviewData, editingPreviewTotal } = usePreviewData()
  const { selectedTab } = useTabs()
  const { editModeEnabled } = useAnalyticsDashboard()
  const [totalForTimePeriod, setTotalForTimePeriod] = useState<number | string | null>(null)

  // TODO: Improve this logic or maybe move to BE
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  useEffect(() => {
    if (allowedModulesForTotal.includes(moduleConfig?.group)) {
      setTotalForTimePeriod(null)

      if (moduleConfig?.group == 'Facebook Ads' || moduleConfig?.group == 'Google Adwords Ads') {
        getTotalForTimePeriod(item?.module, timeRange, selectedTab).then(setTotalForTimePeriod)
      }
    }
  }, [item, timeRange])

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      {!loading && dataToShow ? (
        <>
          <TotalForTimePeriod
            item={item}
            moduleConfig={moduleConfig}
            totalForTimePeriod={totalForTimePeriod}
            editingPreviewTotal={editingPreviewTotal}
          />
          <ChartComponent
            chartData={dataToShow || []}
            isOverlay={isOverlay}
            module={item?.module}
          />
        </>
      ) : (
        <LoadingBoxes />
      )}
      {moduleConfig?.title === 'Payments' ? (
        <PaymentsFooter data={data} timeRange={timeRange} />
      ) : null}
    </div>
  )
}

export default GenericModuleComponent
