import React from 'react'

import { Text } from './types'

interface RecentTextsSectionProps {
  texts: Text[]
}

const RecentTextsSection: React.FC<RecentTextsSectionProps> = ({ texts }) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-envelope mr-1" /> Recent Texts
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Sent at</th>
          <th>Phone</th>
          <th>Content</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {texts.map((text) => (
          <tr key={text.id}>
            <td>{text.created_at}</td>
            <td>{text.phone}</td>
            <td className="text-secondary">{text.content}</td>
            <td>{text.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default RecentTextsSection
