import { useState, useEffect, useContext } from 'react'
import { DealershipContext } from '../../../contexts'
import ContactSelector from './ContactSelector'
import VehicleSelector from './VehicleSelector'

export const NewTestDrive = ({ onCreateTestDrive }) => {
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)

  const { dealership }: any = useContext(DealershipContext)

  useEffect(() => {
    if (selectedCar && selectedContact) {
      onCreateTestDrive({
        carId: selectedCar.value,
        contactId: selectedContact.value,
      })
    }
  }, [selectedCar, selectedContact])

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between" style={{ columnGap: '8px' }}>
        <ContactSelector
          dealership={dealership}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
        />
        <VehicleSelector
          dealership={dealership}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
        />
      </div>
    </div>
  )
}
