import * as Routes from '../../routes'
export const OrderStatusOptionsService = {
  async getOrderStatusOptions(params, csrf, websiteId) {
    const queryParams = params
      ? Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      : ''
    let pagesRoute = ''

    pagesRoute = Routes.website_order_status_options_path(websiteId)

    const res = await fetch(`${pagesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    })
    return await res.json()
  }
} 