import { useState, useEffect } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'

const LeadMergeForm = ({ onSave, selectedLeadClusters }) => {
  const [selectedLeadCluster, setSelectedLeadCluster] = useState(null)
  const [selectedDetails, setSelectedDetails] = useState({
    leadCluster: null,
    name: null,
    email: null,
    phone: null,
  })
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(() => {
    if (selectedLeadClusters && selectedLeadClusters.length > 0) {
      setSelectedLeadCluster(selectedLeadClusters[0].id)
      setSelectedDetails({
        leadCluster: selectedLeadClusters[0].id,
        name: selectedLeadClusters[0].name,
        email: selectedLeadClusters[0].email,
        phone: selectedLeadClusters[0].phone,
      })
    }
  }, [selectedLeadClusters])

  const handleSubmit = (e) => {
    e.preventDefault()
    const nameParts = selectedDetails.name ? selectedDetails.name.split(' ') : []

    const mergeData = {
      selectedDetails: selectedDetails,
      first_name: nameParts[0] || '',
      last_name: nameParts[1] || '',
    }
    onSave(mergeData)
  }

  const handleDetailChange = (detail, value) => {
    setSelectedDetails((prevDetails) => ({
      ...prevDetails,
      [detail]: value,
    }))
  }

  const renderDetailCell = (detail, leadCluster) => {
    let labelContent
    if (detail === 'leadCluster') {
      labelContent = moment(leadCluster.last_lead_created_at || leadCluster.created_at)
        .tz(userTimezone)
        .format('DD/MM/YYYY hh:mm A')
    } else {
      labelContent = leadCluster[detail]
    }

    const isLeadClusterSelected = detail === 'leadCluster' && selectedLeadCluster === leadCluster.id
    const isDetailSelected = selectedDetails[detail] === leadCluster[detail]

    const backgroundColor = isLeadClusterSelected || isDetailSelected ? '#28a745' : 'transparent'
    const color = isLeadClusterSelected || isDetailSelected ? '#ffffff' : '#000000'
    const fontWeight = isLeadClusterSelected || isDetailSelected ? 'bold' : 'normal'

    return (
      <div
        key={leadCluster.id}
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor,
          color,
          fontWeight,
          padding: '4px',
        }}
      >
        {detail === 'leadCluster' ? (
          <RadioButton
            inputId={`lead-${leadCluster.id}`}
            name="leadCluster"
            value={leadCluster.id}
            onChange={(e) => setSelectedLeadCluster(e.value)}
            checked={selectedLeadCluster === leadCluster.id}
            style={{ marginRight: '8px' }}
          />
        ) : (
          <RadioButton
            inputId={`${detail}-${leadCluster.id}`}
            name={detail}
            value={leadCluster[detail]}
            onChange={(e) => handleDetailChange(detail, e.value)}
            checked={selectedDetails[detail] === leadCluster[detail]}
            style={{ marginRight: '8px' }}
          />
        )}
        <label htmlFor={`${detail}-${leadCluster.id}`} style={{ marginBottom: '0' }}>
          {labelContent}
        </label>
      </div>
    )
  }
  const details = ['Lead Cluster to Keep', 'Name to Keep', 'Email to Keep', 'Phone to Keep']
  return (
    <form onSubmit={handleSubmit}>
      <DataTable value={details}>
        <Column
          field="detail"
          header="Select Details"
          body={(rowData, { rowIndex }) => details[rowIndex]}
        />
        {selectedLeadClusters &&
          selectedLeadClusters.length > 0 &&
          selectedLeadClusters.map((leadCluster) =>
            leadCluster ? (
              <Column
                key={leadCluster.id}
                header=""
                body={(rowData, { rowIndex }) =>
                  renderDetailCell(['leadCluster', 'name', 'email', 'phone'][rowIndex], leadCluster)
                }
              />
            ) : null
          )}
      </DataTable>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default LeadMergeForm
