import * as Routes from '../../../routes'
import LoadingBoxes from '../../entries/LoadingBoxes'
import type { ModuleProps } from '../types'
import { Car } from '../types'
import { getDealership } from '../utils'
import { useViewportData } from '../hooks'

const CarStats: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const dealership = getDealership()
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)

  const {
    count,
    no_photos_count,
    need_description,
    last_7_days,
    removed_or_sold,
    average_price,
    average_age,
    popular_cars,
  } = data || {}

  return (
    <div ref={viewportRef}>
      {!loading ? (
        <div style={{ overflowY: 'auto' }}>
          <ul>
            <li>{count} cars in stock</li>
            {count > 0 && (
              <li className="text-danger">
                <a
                  href={Routes.dealership_cars_path(dealership.slug, {
                    toggle: { needs_photos: true },
                  })}
                >
                  {no_photos_count} cars DO NOT have any photos
                </a>
              </li>
            )}
            {count > 0 && (
              <li className="text-danger">
                <a
                  href={Routes.dealership_cars_path(dealership.slug, {
                    toggle: { needs_description: true },
                  })}
                >
                  {need_description} cars DO NOT have Dealer Comments
                </a>
              </li>
            )}
            <li>{last_7_days} cars added in last 7 days</li>
            <li>{removed_or_sold} cars removed/sold in last 7 days</li>
            {average_price > 0 && <li>{'$' + average_price.toLocaleString()} average car price</li>}
            <li>{average_age} average age of inventory in stock</li>
          </ul>
          <h5>Most popular cars by views</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Car</th>
                <th>Stocknum</th>
                <th>Views</th>
              </tr>
            </thead>
            <tbody>
              {popular_cars?.map((car: Car, index: number) => (
                <tr key={`popular_${car.make}_${car.model}_${index}`}>
                  <td>
                    {car.make} {car.model}
                  </td>
                  <td>{car.stocknum}</td>
                  <td>{car.views}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : <LoadingBoxes />}
    </div>
  )
}

export default CarStats
