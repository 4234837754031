import React from 'react'

import * as Routes from '../../routes'
import { Dealership, Vehicle } from './types'

interface VehiclesSectionProps {
  dealership: Dealership
  vehicles: Vehicle[]
  handleDeleteVehicle: (vehicle: Vehicle) => void
}

const VehiclesSection: React.FC<VehiclesSectionProps> = ({
  dealership,
  vehicles,
  handleDeleteVehicle,
}) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-envelope mr-1" /> Vehicles
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Delivery Image</th>
          <th>Year</th>
          <th>Make</th>
          <th>Model</th>
          <th>Rego</th>
          <th>Sale Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle) => (
          <tr key={vehicle.id}>
            <td>
              <img src={vehicle.photo_url} alt="Car image" height="50" />
            </td>
            <td>{vehicle.year}</td>
            <td>{vehicle.make}</td>
            <td>{vehicle.model}</td>
            <td>{vehicle.rego}</td>
            <td>{vehicle.sale_date}</td>
            <td>
              <a
                className="btn btn-secondary btn-sm mr-2"
                href={Routes.edit_dealership_vehicle_path(dealership, vehicle)}
              >
                <i className="fa fa-edit" /> Edit
              </a>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => handleDeleteVehicle(vehicle)}
              >
                <i className="fa fa-trash" /> Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default VehiclesSection
