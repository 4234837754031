import { useEffect, useState } from 'react'
import SlideIn from '../shared/SlideIn'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import Loading from '../Loading'
import Changes from '../cars/Changes'

const CarsCount = (feedRun) => {
  let { dealershipSlug } = useParams()

  return (
    <a href={`/dealerships/${dealershipSlug}/feed_runs/${feedRun.id}`}>
      {feedRun.cars_count || 0} cars
    </a>
  )
}

const CreatedAt = (feedRun) => {
  return <span>{moment(feedRun.created_at).fromNow()}</span>
}

const ErrorsCount = (feedRun) => {
  return (
    <span className={feedRun.errors_count > 0 ? 'text-danger' : 'text-success'}>
      {feedRun.errors_count}
    </span>
  )
}

const Status = (feedRun) => {
  return (
    <span className={feedRun.status === 'success' ? 'text-success' : 'text-danger'}>
      {humanize(feedRun.status)}
    </span>
  )
}

const DownloadCsv = (feedRun) => {
  return <a href={feedRun.file_url}>Download CSV</a>
}

const ViewLogs = (feedRun) => {
  let { dealershipSlug } = useParams()

  return <a href={`/dealerships/${dealershipSlug}/feed_runs/${feedRun.id}/logs`}>View Logs</a>
}

const ViewPentanaLogs = (pentanaFeed) => {
  let { dealershipSlug } = useParams()

  return <a href={`/dealerships/${dealershipSlug}/pentana_feeds/${pentanaFeed.id}`}>View Logs</a>
}

const Show = ({ feedId, isOpen, setIsOpen }) => {
  let [feed, setFeed] = useState({})
  let [loading, setLoading] = useState(true)
  let [loadingExport, setLoadingExport] = useState(false)
  let [refreshLoading, setRefreshLoading] = useState(false)

  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      fetchFeedData(dealershipSlug, feedId)
        .then((data) => {
          setFeed(data)
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching feed data:', error)
          setLoading(false)
        })
    }
  }, [isOpen])

  async function fetchFeedData(dealershipSlug, feedId) {
    const response = await fetch(`/dealerships/${dealershipSlug}/feeds/${feedId}.json`)
    return await response.json()
  }

  const refreshFeed = () => {
    setLoading(true)
    fetchFeedData(dealershipSlug, feedId)
      .then((data) => {
        setFeed(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error refreshing feed data:', error)
        setLoading(false)
      })
  }

  const resetFeed = () => {
    setRefreshLoading(true)
    fetch(`/dealerships/${dealershipSlug}/feeds/${feedId}/reset`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setFeed(data)
        setRefreshLoading(false)
      })
  }

  const exportFeed = () => {
    setLoadingExport(true)
    fetch(`/dealerships/${dealershipSlug}/feeds/${feedId}/export`, {
      method: 'PUT',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setFeed(data)
        setLoadingExport(false)
      })
  }

  return (
    <SlideIn isOpen={isOpen} setIsOpen={setIsOpen} header="View Feed">
      <div className="p-3">
        {loading ? (
          <div className="text-center">
            <Loading />
            Loading...
          </div>
        ) : (
          <>
            <div className="box mb-3 p-3">
              <div className="mb-3">
                <h4>{feed.name}</h4>
                {['export', 'loopit', 'orbee', 'url-only', 'webhook'].includes(feed.feed_type) && (
                  <>
                    <div className="mb-1">
                      <b>Schema:</b> {feed.schema}
                    </div>
                    <div className="mb-1">
                      <b>Current cars:</b> {feed.current_cars}
                    </div>
                  </>
                )}
                {feed.email && (
                  <div className="mb-1">
                    <b>Email:</b> {feed.email}
                  </div>
                )}
                {feed.reset && (
                  <div className="mb-1">
                    <b>Reset at: </b>
                    {moment(feed.reset).fromNow()}
                  </div>
                )}
              </div>
              <button
                className={'btn btn-outline-secondary btn-sm' + (feed.reset ? ' disabled' : '')}
                onClick={resetFeed}
                disabled={feed.reset}
              >
                {refreshLoading && <i className="fas fa-spinner fa-spin mr-1"></i>}
                Reset Feed
              </button>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/${feedId}/edit`}
                className="btn btn-outline-secondary btn-sm ml-2"
              >
                Edit
              </a>
              {feed.feed_type === 'export' && (
                <button
                  onClick={exportFeed}
                  className="btn btn-outline-secondary btn-sm ml-2"
                >
                  {loadingExport && <i className="fas fa-spinner fa-spin mr-1"></i>}
                  Export
                </button>
              )}
              <p className="small mt-1 text-secondary mb-0">
                Reseting the feed will force the feed to run and not be skipped for the next 24
                hours.
              </p>
            </div>
            {feed.feed_runs && feed.feed_runs.length > 0 && (
              <>
                <div className="d-flex mb-2 align-items-center">
                  <h5>Feed Runs</h5>
                  <button
                    className="btn btn-outline-secondary btn-sm ml-auto"
                    onClick={refreshFeed}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                </div>
                <div className="box">
                  <DataTable value={feed.feed_runs}>
                    <Column header="Created At" field="created_at" body={CreatedAt} />
                    <Column header="Count" field="cars_count" body={CarsCount} />
                    <Column header="Status" field="status" body={Status} />
                    <Column header="Errors" field="errors_count" body={ErrorsCount} />
                    <Column header="Download" body={DownloadCsv} />
                    <Column header="Logs" body={ViewLogs} />
                  </DataTable>
                </div>
              </>
            )}
            {feed.pentana_feeds && feed.pentana_feeds.length > 0 && (
              <>
                <h5>HTML Feeds / Pentana Feeds</h5>
                <div className="box">
                  <DataTable value={feed.pentana_feeds}>
                    <Column header="Created At" field="created_at" body={CreatedAt} />
                    <Column header="Subject" field="subject" />
                    <Column header="Count" field="number_of_items" />
                    <Column header="Processed" field="processed" />
                    <Column header="Download" body={DownloadCsv} />
                    <Column header="Logs" body={ViewPentanaLogs} />
                  </DataTable>
                </div>
              </>
            )}
            {feed.file_uploads && feed.file_uploads.length > 0 && (
              <>
                <h5>Photo File Uploads</h5>
                <div className="box">
                  <DataTable value={feed.file_uploads}>
                    <Column header="Created At" field="created_at" body={CreatedAt} />
                    <Column header="Filename" field="filename" />
                    <Column header="Status" field="status" />
                  </DataTable>
                </div>
              </>
            )}
            {feed.apicalls && feed.apicalls.length > 0 && (
              <>
                <h5>Apicalls</h5>
                <div className="box">
                  <DataTable value={feed.apicalls}>
                    <Column header="Created At" field="created_at" body={CreatedAt} />
                    <Column header="Car" field="car.name" />
                    <Column header="Stocknum" field="car.stocknum" />
                    <Column header="Response" field="response_code" />
                  </DataTable>
                </div>
              </>
            )}
            <Changes
              object={feed}
              url={`/dealerships/${dealershipSlug}/feeds/${feed.id}/changes`}
              objectName="Feed"
            />
          </>
        )}
      </div>
    </SlideIn>
  )
}

export default Show
