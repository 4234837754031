import { useState, useEffect } from 'react'
import SlideIn from '../shared/SlideIn'
import Loading from '../Loading'
import SlideOutHeading from './SlideOutHeading'
import LeadBox from './LeadBox'

const LeadsView = ({ leadsView, setLeadsView }) => {
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState(false)
  let [data, setData] = useState(false)

  useEffect(() => {
    if (leadsView) {
      setLoading(true)
      fetch(`/cars/${leadsView.slug}/preview.json`)
        .then(res => res.json())
        .then(res => {
          setLoading(false)
          // if (res.success) {
          //   console.log(res)
          //   setData(res)
          // } else {
          //   setErrors(true)
          // }
          setData(res)
        })
    } else {
      setData(false)
    }
  }, [leadsView]);

  return (
    <SlideIn isOpen={leadsView} setIsOpen={setLeadsView} header={`Leads for ${leadsView?.name}`}>
      {leadsView && (
        <div className="mt-2">
          <SlideOutHeading car={leadsView} />
          {loading ? <Loading /> : (
            <>
              {data && data.lead_clusters && (
                <LeadBox leadClusters={data.lead_clusters} />
              )}
              {errors && <p>An error has occured</p>}
            </>
          )}
        </div>
      )}
    </SlideIn>
  )
}

export default LeadsView