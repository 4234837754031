import React from 'react'

import { Contact } from './types'

interface ContactDetailsProps {
  contact: Contact
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact }) => (
  <div className="box p-3">
    <div className="row">
      <div className="col">
        <h4>{`${contact.first_name} ${contact.last_name}`}</h4>
        <p>
          <strong>Email:</strong> {contact.email}
        </p>
        <p>
          <strong>Phone number:</strong> {contact.phone_number}
        </p>
        <p>
          <strong>Business Name:</strong> {contact.business_name}
        </p>
        <p>
          <strong>Unsubscribe all:</strong> {contact.unsubscribe_all}
        </p>
        <p>
          <strong>Blocked:</strong> {contact.blocked}
        </p>
        <p>
          <strong>Address:</strong> {contact.full_address}
        </p>
        <p>
          <strong>Created at:</strong> {contact.created_at}
        </p>
      </div>
    </div>
  </div>
)

export default ContactDetails
