import { useState } from 'react'
import { updateContactAPI } from './utils'
import { Errors } from '../entries/FormElements'
import { Input, PhoneInput } from '../entries/FormElements'
import { formatPhone } from '../entries/utils'
import { ownerContext } from './utils'

const formatValue = (field, value) => {
  if (!value) return value

  if (field === 'phone_number') {
    return formatPhone(value)
  } else {
    return value
  }
}

const Form = ({ value, field, setContact, setEditing, placeholder }) => {
  const { owner } = ownerContext();

  const [newValue, setNewValue] = useState(value)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    const data = {
      contact: {
        [field]: newValue
      }
    }

    updateContactAPI(owner.contact_id, data)
      .then(res => {
        if (res.status === 422) {
          return res.json().then((errors) => {
            setLoading(false);
            setErrors(errors);
            return Promise.reject(errors);
          });
        }
        return res.json();
      })
      .then(res => {
        setEditing(false)
        setLoading(false)
        setContact(res)
      })
  }

  const InputComponent = field === 'phone_number' ? PhoneInput : Input

  return (
    <div>
      <InputComponent
        type="text"
        className="form-control mb-2 w-100"
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value)
        }}
        id={`editable-item-${field}-input`}
        placeholder={placeholder}
        disabled={loading}
        wrapperClass=''
        label={false}
      />
      {errors && <Errors errors={errors} />}
      <div className={"btn btn-outline-secondary btn-sm mr-2" + (loading ? ' disabled' : '')} onClick={onSubmit} id={`editable-item-${field}-save`} disabled={loading}>
        {loading ? 'Loading...' : 'Save'}
      </div>
      <div className="btn btn-outline-secondary btn-sm" onClick={() => setEditing(false)} disabled={loading}>
        Cancel
      </div>
    </div>
  )
}


const EditableItem = ({ field, label, value, setContact, placeholder }) => {
  const [onHover, setOnHover] = useState(false)
  const [editing, setEditing] = useState(false)

  return (
    <div
      className={onHover ? 'bg-light py-2 px-3' : "py-2 px-3"}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      id={`editable-item-${field}`}
    >
      {editing ? (
        <Form value={value} setContact={setContact} field={field} setEditing={setEditing} placeholder={placeholder} />
      ) : (
        <div className="d-flex">
          <div>
            <div style={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 'bold' }}>
              {label}:
            </div>
            <div>{formatValue(field, value) || <span className="text-secondary">{label}</span>}</div>
          </div>
          <div className={onHover ? "ml-auto" : 'd-none'} id={`editable-item-${field}-button`}>
            <div className="btn btn-sm btn-outline-secondary" onClick={() => setEditing(true)}>
              <i className="fa fa-edit"></i>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditableItem