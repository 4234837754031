export const employmentOptions = [
  { label: "Full time", value: "FT" },
  { label: "Part time", value: "PT" },
  { label: "Unemployed", value: "UN" },
  { label: "Retired", value: "RT" },
  { label: "Student", value: "ST" },
  { label: "Self employed", value: "SE" },
  { label: "Home duties", value: "HD" },
]

export const residentialOptions = [
  { label: "Owner", value: "O" },
  { label: "Renter", value: "R" },
  { label: "Other", value: "U" },
]

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
]

export const financeMethodCodes = [
  { label: "No Finance", value: "X" },
  { label: "Bank Loan", value: "B" },
  { label: "Bill of Sale", value: "O" },
  { label: "Corporate Hire Purchase", value: "C" },
  { label: "Credit Union(Secured)", value: "U" },
  { label: "Finance Co(Secured)", value: "F" },
  { label: "Hire Purchase", value: "H" },
  { label: "Home Equity Loan", value: "E" },
  { label: "Lease", value: "L" },
  { label: "Novated Lease", value: "N" },
  { label: "Personal Loan(Secured)", value: "P" },
  { label: "Personal Loan(Unsecured)", value: "S" },
]

export const useOptions = [
  { label: "Private use", value: "P" },
  { label: "Ride share", value: "R" },
  { label: "Business use", value: "B" },
]

export const garageOptions = [
  { label: 'Carport', value: 'C' },
  { label: "Parked off the street in the Driveway", value: "D" },
  { label: "Garage", value: "G" },
  { label: "Parked on the street", value: "S" },
]

export const annualDistanceOptions = [
  { label: 'Up to 10,000 km', value: 1 },
  { label: '10,000 – 15,001 km', value: 2 },
  { label: '15,001 – 20,000 km', value: 3 },
  { label: '>20,000 km', value: 4 },
]

export const genderCodeOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' }
]