import { useState, useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Panel } from 'primereact/panel'
import moment from 'moment-timezone'

import { PhoneCallsContext, LeadClusterContext } from '../contexts'

const Audio = (phoneCall) => {
  const [showControls, setShowControls] = useState(false)

  const handleClick = (event) => {
    event.preventDefault()
    setShowControls(true)
  }

  return (
    <div>
      {phoneCall.recording_url && (
        <a onClick={handleClick} href="">
          <i className="fa fa-headphones" aria-hidden="true"></i> Listen
        </a>
      )}
      {showControls && (
        <audio controls>
          <source src={phoneCall.recording_url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  )
}

const startTime = (phoneCall) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const formattedTime = moment(phoneCall.start_time)
    .tz(userTimezone)
    .format('DD/MM/YYYY, h:mm:ss a')
  const relativeTime = moment(phoneCall.start_time).tz(userTimezone).fromNow()
  return (
    <div>
      <p>
        {formattedTime}
        <br />
        <span className="text-secondary"> {relativeTime}</span>
      </p>
    </div>
  )
}

const PhoneCalls = () => {
  const { leadCluster } = useContext(LeadClusterContext)
  const [phoneCalls, setPhoneCalls] = useState(leadCluster.phone_calls || [])

  return (
    <PhoneCallsContext.Provider value={{ phoneCalls, setPhoneCalls }}>
      <div className="px-3 py-1">
        <Panel header={`Phone Calls (${phoneCalls.length})`} toggleable>
          <div className="box">
            <DataTable value={phoneCalls} paginator rows={10} className="p-datatable-sm">
              <Column field="website" header="Website" sortable style={{ maxWidth: '8rem' }} />
              <Column field="location" header="Location" sortable style={{ maxWidth: '8rem' }} />
              <Column field="caller_number" header="Caller Number" sortable />
              <Column field="talktime" header="Talk time" sortable />
              <Column body={startTime} field="start_time_in_words" header="Called" sortable />

              <Column field="recording_url" header="Audio" body={Audio} sortable />
            </DataTable>
          </div>
        </Panel>
      </div>
    </PhoneCallsContext.Provider>
  )
}

export default PhoneCalls
