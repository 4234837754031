import { useState} from 'react'
const InsuranceOptionForm = ({ insuranceOption, onSave }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSave({
      id: insuranceOption?.id,
      title: title,
      daily_price: dailyPrice,
      deductible_price: deductiblePrice,
      financial_responsibility: financialResponsibility,
    })
  }

  const [title, setTitle] = useState(insuranceOption?.title || '')
  const [dailyPrice, setDailyPrice] = useState(insuranceOption?.daily_price || '')
  const [deductiblePrice, setDeductiblePrice] = useState(insuranceOption?.deductible_price || '')
  const [financialResponsibility, setFinancialResponsibility] = useState(
    insuranceOption?.financial_responsibility || ''
  )

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          className="form-control"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="dailyPrice">Daily Price($)</label>
        <input
          type="number"
          step="0.01"
          className="form-control"
          id="dailyPrice"
          value={dailyPrice}
          onChange={(e) => setDailyPrice(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="deductiblePrice">Deductible Price($)</label>
        <input
          type="number"
          step="0.01"
          className="form-control"
          id="deductiblePrice"
          value={deductiblePrice}
          onChange={(e) => setDeductiblePrice(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="financialResponsibility">Financial Responsibility</label>
        <input
          type="text"
          className="form-control"
          id="financialResponsibility"
          value={financialResponsibility}
          onChange={(e) => setFinancialResponsibility(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default InsuranceOptionForm
