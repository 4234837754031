const SlideOutHeading = ({ car }) => (
  <div className="d-flex mb-3">
    <div className="mr-2">
      <img
        src={car.primary_image_url}
        height="100"
        className="rounded border"
      />
    </div>
    <div>
      <h6>{car.name}</h6>
      <p className="mb-0"><b>Listed price:</b> ${car.price?.toLocaleString()}</p>
      <p className="mb-0"><b>Kms:</b> {car.km?.toLocaleString()}</p>
    </div>
  </div>
)

export default SlideOutHeading