// MANAGED BY App.js
import { useEffect, useState } from 'react'
import { Form } from '../preorder_cars'
import { Dialog } from 'primereact/dialog'
import Loading from '../Loading'

const StockImageSetForm = ({ stockImageSet, action = 'edit' }) => {

  let [data, setData] = useState()
  let [loading, setLoading] = useState(false)

  let { id } = stockImageSet

  useEffect(() => {
    if (data) {
      return
    }
    setLoading(true)
    fetch(`/stock_image_sets/${id}.json`)
      .then(res => res.json())
      .then(data => {
        setData(data)
        setLoading(false)
      })
  })

  let url = `/stock_image_sets/${id}`
  if (action === 'new') {
    url = '/stock_image_sets'
  }

  return (
    <div>
      {loading && <Loading />}
      {data && data?.stock_image_set && (
        <form action={url} method="post">
          <input type="hidden" name="_method" value="patch" autocomplete="off"></input>
          <Form
            recordType='stock_image_set'
            showBadge={true}
            colours={data?.colours?.map(c => { return { value: c, label: c } })}
            manufacturer_id={data?.stock_image_set?.manufacturer_id}
            family_id={data?.stock_image_set?.family_id}
            defaultYear={data?.stock_image_set?.year}
            defaultBadge={data?.stock_image_set?.badge}
            defaultColour={data?.stock_image_set?.colour}
            defaultModelYear={data?.stock_image_set?.modelyear}
            defaultBodyconfiguration={data?.stock_image_set?.bodyconfiguration || null}
          />
        </form>
      )}
    </div>
  )
}

const Wrapper = ({ stockImageSet }) => {
  let [visible, setVisible] = useState(false)

  return (
    <>
      <a
        href="#"
        className="dropdown-item"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        <i className="fa fa-edit mr-2"></i>
        Edit
      </a>
      <Dialog
        header="Edit Stock Image Set"
        visible={visible}
        style={{ width: '50vw' }}
        modal={true}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <StockImageSetForm stockImageSet={stockImageSet} />
      </Dialog>
    </>
  )
}

export default Wrapper