import { useState, useRef, useContext } from 'react'
import { isTouchDevice } from '../../utils'
import { TestDriveContext } from '../../../contexts'
import { TestDrive } from '../../TestDrive'

interface DriverLicenseUploadProps {
  uploadDriversLicense: any
  deleteDriversLicense: any
}

const FileUploader = ({ type, uploadDriversLicense }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const backFileInputRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      setIsUploading(true)
      await uploadDriversLicense(file, 'front')
      setIsUploading(false)
    }
  }

  const handleBackFileChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      setIsUploading(true)
      await uploadDriversLicense(file, 'back')
      setIsUploading(false)
    }
  }

  const handleUploadClick = (type: string = 'front') => {
    const inputRef = type === 'front' ? fileInputRef : backFileInputRef
    inputRef.current.click()
  }

  const handleCameraCapture = async (type: string = 'front') => {
    const inputRef = type === 'front' ? fileInputRef : backFileInputRef
    inputRef.current.setAttribute('capture', 'camera')
    inputRef.current.click()
    // Reset capture attribute after click
    setTimeout(() => {
      inputRef.current.removeAttribute('capture')
    }, 100)
  }

  return (
    <>
      <p className="font-weight-light small">
        Upload a valid image of the {type} of customer's driver's license
      </p>
      <input
        type="file"
        ref={type === 'front' ? fileInputRef : backFileInputRef}
        onChange={type === 'front' ? handleFileChange : handleBackFileChange}
        style={{ display: 'none' }}
        accept="image/*"
      />
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-primary mr-2"
          onClick={() => handleUploadClick(type)}
        >
          Upload {isUploading && <i className="fa fa-spinner fa-spin"></i>}
        </button>
        {isTouchDevice() && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleCameraCapture(type)}
          >
            Capture Photo
          </button>
        )}
      </div>
    </>
  )
}

const PhotoDisplay = ({ type, deleteDriversLicense }) => {
  const { testDrive }: any = useContext(TestDriveContext)
  const {
    drivers_license_url,
    drivers_license_name,
    drivers_license_back_url,
    drivers_license_back_name,
  } = testDrive

  return (
    <>
      <div className="mt-2">
        <img
          src={type === 'front' ? drivers_license_url : drivers_license_back_url}
          alt="Uploaded License"
          style={{ maxWidth: '100%', maxHeight: '200px' }}
        />
      </div>
      <div className="mt-2 small">
        File uploaded: {type === 'front' ? drivers_license_name : drivers_license_back_name}
      </div>
      <button
        type="button"
        className="btn btn-danger mt-2"
        onClick={() => deleteDriversLicense(type)}
      >
        <i className="fa fa-trash"></i> Delete
      </button>
    </>
  )
}

export const DriverLicenseUpload = ({
  uploadDriversLicense,
  deleteDriversLicense,
}: DriverLicenseUploadProps) => {
  const { testDrive }: any = useContext(TestDriveContext)
  if (!testDrive) return null
  const {
    drivers_license_url,
    drivers_license_name,
    drivers_license_back_url,
    drivers_license_back_name,
  } = testDrive

  return (
    <div className="card mb-4">
      <div className="card-header">Driver's License</div>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <h4>FRONT</h4>
            {(!drivers_license_url || drivers_license_url.length == 0) && (
              <FileUploader type="front" uploadDriversLicense={uploadDriversLicense} />
            )}

            {drivers_license_url && drivers_license_url.length > 0 && (
              <PhotoDisplay type="front" deleteDriversLicense={deleteDriversLicense} />
            )}
          </div>
          <div className="col-6">
            <h4>BACK</h4>
            {(!drivers_license_back_url || drivers_license_back_url.length == 0) && (
              <FileUploader type="back" uploadDriversLicense={uploadDriversLicense} />
            )}

            {drivers_license_back_url && drivers_license_back_url.length > 0 && (
              <PhotoDisplay type="back" deleteDriversLicense={deleteDriversLicense} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
