import { Toast } from 'primereact/toast'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { User } from '../AnalyticsDashboard/types'
import { CurrentUserContext, DealershipContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import NotesList from '../notes/NotesList'
import showToast from '../shared/ShowToast'
import ContactDetails from './ContactDetails'
import CreditScoreRequestsSection from './CreditScoreRequestSection'
import DepositRequestModal from './DepositRequestModal'
import LeadClustersSection from './LeadClusterSection'
import LoyaltyBalance from './LoyaltyBalance'
import RecentEmailsSection from './RecentEmailsSection'
import RecentTextsSection from './RecentTextsSection'
import ServiceBookingsSection from './ServiceBookingsSection'
import { Contact, Dealership, Vehicle } from './types'
import VehiclesSection from './VehiclesSection'
import WatchlistSection from './WatchlistSection'

const ShowContact: React.FC = () => {
  const [contact, setContact] = useState<Contact | null>(null)
  const [vehicles, setVehicles] = useState<Vehicle[] | null>([])
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const { contactId } = useParams<{ contactId: string }>()
  const notification = useRef(null)

  // Dealership context
  useFetchDealership()
  const { dealership } = useContext(DealershipContext) as {
    dealership: Dealership
  }

  const currentUser = useContext(CurrentUserContext) as User

  async function getContact(dealershipId: number): Promise<void> {
    try {
      const url = `${Routes.dealership_contact_path(dealershipId, contactId)}.json`
      const response = await fetch(url)
      setContact(await response.json())
    } catch (error) {
      console.error(error)
    }
  }

  function can_accept_delivery_error() {
    if (dealership && contact) {
      if (!contact.can_accept_delivery) {
        if (!contact.email_or_phone_present) {
          return (
            <div className="text-center">
              <small className="text-secondary">Please add email</small>
            </div>
          )
        } else if (contact.gmb_profiles_count < 1) {
          return (
            <div className="text-center">
              <small className="text-secondary">Please add GMB profile to dealership</small>
            </div>
          )
        }
      }
    }
  }

  const handleDeleteVehicle = async (vehicle: Vehicle) => {
    try {
      const url = `${Routes.dealership_vehicle_path(dealership.slug, vehicle.id)}.json`

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
        },
      })
      if (response.ok) {
        setVehicles(vehicles?.filter((v) => v.id !== vehicle.id))

        showToast(
          notification,
          'success',
          'Vehicle deleted!',
          'The vehicle was successfully deleted.'
        )
      } else {
        console.error('Failed to delete vehicle')

        showToast(notification, 'error', 'Failed to delete vehicle.', 'Please try again.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (dealership) {
      getContact(dealership?.id)
    }
  }, [dealership])

  useEffect(() => {
    if (contact) {
      setVehicles(contact.vehicles)
    }
  }, [contact])

  const { has_loyalty: contactHasLoyalty } = contact || {}
  const { has_loyalty: dealershipHasLoyalty } = dealership || {}

  return (
    <>
      {dealership && contact ? (
        <div className="container-fluid py-3">
          <Toast ref={notification} />
          <div className="row">
            {/* Left column */}
            <div className="col-md-2 mb-3">
              <a
                className="btn btn-primary btn-block mb-2"
                href={Routes.edit_dealership_contact_path(dealership.id, contact.id)}
              >
                <i className="fa fa-edit" /> Edit
              </a>

              <a
                className="btn btn-outline-secondary btn-block mb-2"
                href={Routes.new_dealership_vehicle_path(dealership.id, { contact_id: contact.id })}
              >
                <i className="fa fa-plus" /> Vehicle
              </a>

              <button
                className="btn btn-outline-primary btn-block mb-2"
                onClick={() => setIsDialogVisible(true)}
              >
                <i className="fa fa-dollar-sign" /> Send Deposit
              </button>

              {contact.can_accept_delivery ? (
                <a
                  className="btn btn-outline-success btn-block mb-2"
                  href={Routes.new_dealership_delivery_path(dealership.id, {
                    contact_id: contact.id,
                  })}
                >
                  <i className="fa fa-plus" /> Delivery
                </a>
              ) : null}

              {dealershipHasLoyalty && contactHasLoyalty ? (
                <>
                  <a
                    className="btn btn-outline-success btn-block mb-2"
                    href={Routes.new_dealership_contact_contact_point_path(
                      dealership.id,
                      contact.id
                    )}
                  >
                    <i className="fa fa-plus" /> Points
                  </a>

                  <a
                    className="btn btn-outline-danger btn-block mb-2"
                    href={Routes.new_dealership_contact_contact_point_path(
                      dealership.id,
                      contact.id,
                      { redeem: true }
                    )}
                  >
                    Redeem Points
                  </a>
                </>
              ) : null}

              {can_accept_delivery_error()}
            </div>

            {/* Right column */}
            <div className="col-md-10">
              {contactHasLoyalty ? (
                <div className="row mb-3">
                  <div className="col-lg-6 mb-3 mb-lg-0">
                    <ContactDetails contact={contact} />
                  </div>
                  <div className="col-lg-6">
                    <div className="box flex-grow h-100 d-flex flex-column">
                      <LoyaltyBalance contact={contact} dealership={dealership} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mb-3">
                  <ContactDetails contact={contact} />
                </div>
              )}

              {/* Lead Clusters Section */}
              {contact.lead_clusters && contact.lead_clusters.length > 0 && (
                <LeadClustersSection dealership={dealership} leadClusters={contact.lead_clusters} />
              )}

              {/* Credit Score Requests Section */}
              {contact.credit_score_requests && contact.credit_score_requests.length > 0 && (
                <CreditScoreRequestsSection creditScoreRequests={contact.credit_score_requests} />
              )}

              {/* Recent Emails Section */}
              {contact.emails && contact.emails.length > 0 && (
                <RecentEmailsSection dealership={dealership} emails={contact.emails} />
              )}

              {/* Recent Texts Section */}
              {contact.texts && contact.texts.length > 0 && (
                <RecentTextsSection texts={contact.texts} />
              )}

              {/* Vehicles Section */}
              {vehicles && vehicles.length > 0 && (
                <VehiclesSection
                  dealership={dealership}
                  vehicles={vehicles}
                  handleDeleteVehicle={handleDeleteVehicle}
                />
              )}

              {/* Watchlist Section */}
              {contact.cars && contact.cars.length > 0 && <WatchlistSection cars={contact.cars} />}

              {/* Service Bookings Section */}
              {contact.service_bookings && contact.service_bookings.length > 0 && (
                <ServiceBookingsSection serviceBookings={contact.service_bookings} />
              )}

              {/* Notes Section */}
              {currentUser ? (
                <div className="box p-3 mb-3">
                  <h4>
                    <i className="fa fa-heart" /> Notes
                  </h4>

                  <NotesList
                    initialNotes={[]}
                    notesPath={Routes.dealership_contact_notes_path(dealership, contact)}
                    currentUserId={currentUser?.id}
                    isAdmin={currentUser?.admin}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <DepositRequestModal
            isOpen={isDialogVisible}
            onClose={() => setIsDialogVisible(false)}
            dealershipId={dealership.id}
            contactId={contact.id}
          />
        </div>
      ) : null}
    </>
  )
}

export default ShowContact
