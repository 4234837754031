import React from 'react'

import * as Routes from '../../routes'
import { CreditScoreRequest } from './types'

interface CreditScoreRequestsSectionProps {
  creditScoreRequests: CreditScoreRequest[]
}

const CreditScoreRequestsSection: React.FC<CreditScoreRequestsSectionProps> = ({
  creditScoreRequests,
}) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-credit-card" /> Credit Score Requests
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>ApiCall Id</th>
          <th>Credit Score</th>
          <th>Sent At</th>
          <th>Third Party Data</th>
        </tr>
      </thead>
      <tbody>
        {creditScoreRequests.map((request) => (
          <tr key={request.id}>
            <td>{request.api_call_id}</td>
            <td>
              {request.scores[0]}
              <br />
              {request.scores[1]}
            </td>
            <td>{request.created_at}</td>
            <td>
              <a
                className="btn btn-outline-primary"
                href={Routes.download_credit_score_requests_path(request, 'request')}
              >
                <i className="fa fa-download" /> Request Payload
              </a>
              <a
                className="btn btn-outline-primary ml-2"
                href={Routes.download_credit_score_requests_path(request, 'response')}
              >
                <i className="fa fa-download" /> Response Payload
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default CreditScoreRequestsSection
