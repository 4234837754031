import moment from 'moment'
import { useCallback, useState } from 'react'
import { useRange } from 'react-instantsearch'

const dropdownOptions = [
  {
    label: '0-15 days',
    start: moment().subtract(15, 'days').unix(),
    end: moment().unix(),
  },
  {
    label: '16-30 days',
    start: moment().subtract(30, 'days').unix(),
    end: moment().subtract(16, 'days').unix(),
  },
  {
    label: '31-45 days',
    start: moment().subtract(45, 'days').unix(),
    end: moment().subtract(31, 'days').unix(),
  },
  {
    label: '46-60 days',
    start: moment().subtract(60, 'days').unix(),
    end: moment().subtract(46, 'days').unix(),
  },
  {
    label: '61-75 days',
    start: moment().subtract(75, 'days').unix(),
    end: moment().subtract(61, 'days').unix(),
  },
  {
    label: '76-90 days',
    start: moment().subtract(90, 'days').unix(),
    end: moment().subtract(76, 'days').unix(),
  },
  {
    label: '91+ days',
    start: moment().subtract(1000, 'days').unix(),
    end: moment().subtract(91, 'days').unix(), // No end limit, represents 91+ days
  },
]

const DateRangeRefinementList = ({ attribute }) => {
  const { range, refine } = useRange({ attribute })
  const [selectedRange, setSelectedRange] = useState(null)

  const handleDropdownClick = useCallback(
    (item) => {
      if (selectedRange === item.label) {
        // Deselect if already selected
        setSelectedRange(null)
        refine([range.min, range.max]) // Reset the range
      } else {
        const min = Math.max(item.start, range.min)
        const max = Math.min(item.end, range.max)
        setSelectedRange(item.label) // Track selected label
        refine([min, max])
      }
    },
    [refine, range, selectedRange]
  )

  return (
    <div className="ais-RefinementList">
      <ul className="ais-RefinementList-list">
        {dropdownOptions.map((item) => (
          <li
            key={item.label}
            className={`ais-RefinementList-item ${selectedRange === item.label ? 'ais-RefinementList-item--selected' : ''
              }`}
          >
            <label className="ais-RefinementList-label">
              <input
                className="ais-RefinementList-checkbox"
                type="checkbox"
                checked={selectedRange === item.label}
                onChange={() => handleDropdownClick(item)}
              />
              <span className="ais-RefinementList-labelText">{item.label}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DateRangeRefinementList