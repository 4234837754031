import { createContext, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

export const LeadContext = createContext({})
export const LeadClusterContext = createContext({})
export const TestDriveContext = createContext({})
export const RootContext = createContext({})
export const OfferContext = createContext({})
export const PromotionContext = createContext({})
export const AppointmentContext = createContext({})
export const PhoneCallsContext = createContext({})
export const UnavailableIntervalContext = createContext({})
export const CarContext = createContext({})
export const PageContext = createContext({})
export const BlogContext = createContext({})
export const CurrentUserContext = createContext({})
export const DealershipContext = createContext({})
export const WebsiteContext = createContext({})
export const KanbanContext = createContext({})

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const userId = window.current_user?.id
    if (userId) {
      fetch(`/users/me.json`)
        .then(response => response.json())
        .then(data => setCurrentUser(data))
        .catch(error => console.error('Error fetching user data:', error))
    }
  }, [])

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const DealershipProvider = ({ children }) => {
  const [dealership, setDealership] = useState(null)

  return <DealershipContext.Provider value={{ dealership, setDealership }}>{children}</DealershipContext.Provider>
}

export const WebsiteProvider = ({ children }) => {
  const [website, setWebsite] = useState(null)

  return <WebsiteContext.Provider value={{ website, setWebsite }}>{children}</WebsiteContext.Provider>
}

export const TestDriveProvider = ({ children }) => {
  const [testDrive, setTestDrive] = useState(null)

  return <TestDriveContext.Provider value={{ testDrive, setTestDrive }}>{children}</TestDriveContext.Provider>
}
