// MANAGED BY App.js
import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Select from 'react-select'

let services = [
  { label: 'Vin to Redbook', value: 'VinToRedbook' },
  { label: 'Equifax Credit Score', value: 'Equifax Credit Score' },
  { label: 'Car Image Background Removal', value: 'Car Image Background Removal' },
  { label: 'SMS', value: 'sms' },
]

const App = ({ }) => {
  let [loading, setLoading] = useState(false)
  let [dealerships, setDealerships] = useState([])
  let [service, setService] = useState(services[0]['value'])

  useEffect(() => {
    setLoading(true)
    setDealerships([])
    fetch(`/admin/billing.json?service=${service}`)
      .then(response => response.json())
      .then(data => {
        setDealerships(data)
        setLoading(false)
      })
  }, [service])

  const months = dealerships.length === 0 ? [] : Object.keys(dealerships[0]?.monthly_counts)

  return (
    <div className="container py-3">
      <h4>Billing</h4>
      <div className="mb-3">
        <Select
          options={services}
          placeholder="Select Service"
          onChange={value => setService(value.value)}
          defaultValue={services[0]}
          isDisabled={loading}
        />
      </div>
      <h4>{service}</h4>
      <div className="box">
        {loading ? (
          <div className="p-3">
            <i className="fa fa-spinner fa-spin mr-1"></i>
            Loading...
          </div>
        ) : (
          <>
            {dealerships && dealerships.length > 0 ? (
              <DataTable value={dealerships} className="p-datatable-striped">
                <Column field="name" header="Name" />
                {months.map(month => (
                  <Column key={month} field={`monthly_counts.${month}`} header={month} />
                ))}
              </DataTable>
            ) : <div className="p-3">No data</div>}
          </>
        )}
      </div>

    </div>
  )
}

export default App