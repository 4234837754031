import { useHits, useInstantSearch } from 'react-instantsearch';
import CarSearchItem from './CarSearchItem'
import { useEffect, useState } from 'react';

const Hits = ({ onSelect, setAutograb, hasAutograb, beta, recentlyUpdatedCars = [], addRecentlyUpdatedCar, ...props }) => {
  let [carIds, setCarIds] = useState([])
  let [views, setViews] = useState([])

  let { hits } = useHits()

  const { status } = useInstantSearch()

  useEffect(() => {
    if (status === 'idle') {
      setCarIds(hits.map(hit => hit.id))
    }
  }, [status])

  useEffect(() => {
    if (carIds.length === 0) {
      return
    }

    let carIdsString = carIds.join(',')
    fetch(`/api/v1/cars?ids=${carIdsString}`)
      .then(response => response.json())
      .then(data => {
        setViews(data)
      })
  }, [carIds])

  return (
    <div className="my-2">
      {hits.map(hit => {
        let car = hit
        let recentlyUpdatedData = recentlyUpdatedCars.filter(c => c.id == hit.id)[0]
        if (recentlyUpdatedData) {
          car = {
            ...hit,
            ...recentlyUpdatedData
          }
        }
        if (views.length > 0) {
          // the views data in meilisearch is inaccurate as this is not updated regularly
          // therefore we need to fetch the views data from the backend
          car = {
            ...car,
            views: views.filter(v => v.id == hit.id)[0]?.views
          }
        }
        return (
          <CarSearchItem
            hit={car}
            key={hit.id}
            handleSelectHit={onSelect}
            setAutograb={setAutograb}
            beta={beta}
            hasAutograb={hasAutograb}
            addRecentlyUpdatedCar={addRecentlyUpdatedCar}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default Hits