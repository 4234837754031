import React, { useState } from 'react';
import moment from 'moment';
import { capitalise, time_ago } from '../entries/utils';

const ThirdPartyData = ({ third_party_data }) => {
  // State to track expanded state for each row
  const [expandedRows, setExpandedRows] = useState({});

  // Function to toggle expanded state for a row
  const toggleExpand = (e, index) => {
    e.preventDefault();
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="box p-3">
      <h4>Third Party Integrations</h4>
      <table className="table table-bordered mb-0 rounded table-striped">
        <thead>
          <tr>
            <th>Service</th>
            <th>ID</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {third_party_data.map((third_party, index) => (
            <>
              <tr key={`third-party-${third_party.id}-${index}`}>
                <td>{capitalise(third_party.service)}</td>
                <td>{third_party.external_id}</td>
                <td>
                  <a href="#" onClick={(event) => toggleExpand(event, index)}>
                    {expandedRows[index] ? 'Hide' : 'View'}
                  </a>
                </td>
              </tr>
              {expandedRows[index] && (
                <tr key={`third-party-expanded-${third_party.id}-${index}`}>
                  <td colSpan="3">
                    <pre>{JSON.stringify(third_party.data, null, 1)}</pre>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ThirdPartyData;
