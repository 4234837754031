import { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ item }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className='col-3 mb-2'>
      <div
        ref={setNodeRef}
        style={style} {...attributes} {...listeners}
      >
        <div className="border rounded p-2">
          <div style={{ minHeight: 50 }}>
            <img src={item.avatar_url} className="mb-2 img-fluid" />
          </div>
          {item.name}
        </div>
      </div>
    </div>
  )
}

const App = ({ users, setUpdated, setIndex }) => {
  const [items, setItems] = useState(users);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <div className="row p-2">
        <SortableContext
          items={items}
          strategy={rectSortingStrategy}
        >
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              item={item}
              index={index}
              setIndex={setIndex}
              setUpdated={setItems}
            />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);

        let newItems = arrayMove(items, oldIndex, newIndex)
        setUpdated(newItems)
        return newItems;
      });
    }
  }
}

export default App
