import * as Routes from '../../routes'
export const TestDriveService = {
  async getTestDrives({params, csrf, dealershipId, urlParams}) {
    let queryParams = params
      ? Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      : ''

    // append urlParams to queryParams
    if (urlParams) {
      urlParams.forEach((value, key) => {
        queryParams += `&${key}=${value}`
      })
    }
    const res = await fetch(`${Routes.dealership_test_drives_path(dealershipId)}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      credentials: 'same-origin'
    })
    return await res.json()
  }
}