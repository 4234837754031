import { useState, useEffect, useContext } from 'react'
import Select from 'react-select'

import DateTimePicker from '../shared/DateTimePicker'
import { CurrentUserContext, DealershipContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const DealershipUsersUnavailableIntervalsForm = ({ interval, onSave }) => {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  const currentUserRole = currentUser?.dealerships?.[dealership?.id]?.role

  const [startsAt, setStartsAt] = useState(() => {
    if (interval?.starts_at) {
      return interval.starts_at
    } else {
      const now = new Date()
      now.setHours(9, 0, 0, 0) // Set time to 9:00 AM
      return now
    }
  })

  const [endsAt, setEndsAt] = useState(() => {
    if (interval?.ends_at) {
      return interval.ends_at
    } else {
      const now = new Date()
      now.setHours(18, 0, 0, 0) // Set time to 6:00 PM
      return now
    }
  })
  const [assignedUser, setAssignedUser] = useState({
    label: currentUser?.name,
    value: currentUser?.id,
  })

  useFetchDealership()

  const handleDateChange = (date, type) => {
    if (type === 'startsAt') {
      setStartsAt(date)
    } else if (type === 'endsAt') {
      setEndsAt(date)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Chech if both dates are selected
    if (!startsAt || !endsAt) {
      alert('Please select both Start and End Dates')
      return
    }

    // Check Start Date is before End Date
    if ((startsAt[0] || startsAt) >= (endsAt[0] || endsAt)) {
      alert('Start Date must be before End Date')
      return
    }

    // prevent past dates
    if ((startsAt[0] || startsAt) < new Date()) {
      alert('Start Date must be in the future')
      return
    }
    onSave({
      id: interval?.id,
      startsAt,
      endsAt,
      user_id: assignedUser?.value,
      user: { id: assignedUser?.value, name: assignedUser?.label },
    })
  }

  useEffect(() => {
    if (interval) {
      let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const localStartDate = new Date(interval.starts_at)
      const localEndDate = new Date(interval.ends_at)

      const adjustedStartDate = new Date(
        localStartDate.toLocaleString('en-US', { timeZone: userTimezone })
      )
      const adjustedEndDate = new Date(
        localEndDate.toLocaleString('en-US', { timeZone: userTimezone })
      )

      setStartsAt(adjustedStartDate)
      setEndsAt(adjustedEndDate)
      setAssignedUser({ label: interval.user?.name, value: interval.user?.id })
    }
  }, [interval])

  const onAssignedUserChange = (user) => {
    setAssignedUser(user)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        {(currentUser?.admin || currentUserRole == 'manager') && (
          <div className="form-group">
            <label htmlFor="user">Unavailable Team Member</label>
            <Select
              options={dealership?.available_users}
              name="user"
              value={assignedUser}
              placeholder="Assign User"
              onChange={onAssignedUserChange}
              className={'user-select'}
            />
          </div>
        )}
        <label htmlFor="starts_at">Starts At</label>
        <DateTimePicker
          id="starts_at"
          value={startsAt}
          onChange={(date) => handleDateChange(date, 'startsAt')}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>

      <div className="form-group">
        <label htmlFor="ends_at">Ends At</label>
        <DateTimePicker
          id="ends_at"
          value={endsAt}
          onChange={(date) => handleDateChange(date, 'endsAt')}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>

      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default DealershipUsersUnavailableIntervalsForm
