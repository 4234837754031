import { Tooltip } from 'react-tooltip'

function healthClass(health) {
    if (health > 80) {
        return "text-success"
    }
    if (health < 40) {
        return "text-danger"
    }
    return ""
}

let detailsTitle = {
    "compliance_date": "Compliance Date",
    "regplate": "Rego",
    "rego_expiry": "Rego Expiry",
    "rego_state": "Rego State",
    "colour": "Exterior Colour",
    "interior_colour": "Interior Colour",
    "km": "Odometer",
    "dealercomments": "Dealer Comments",
    "make": "Make",
    'model': "Model",
    'badge': "Badge",
    'year': "Year",
    'price': "Price",
    'build_date': 'Build Date',
    'has_redbook': 'Redbook Code'
}
// Cannot add badge/series as sometimes they are blank for some variants (and the vehicle is still correct)

let HealthResults = ({ hit }) => (
    <>
        <div className="small">
            Photos: {hit.photos_count}/20
            {hit.photos_count > 19 ? <i className="fa fa-check ml-2 text-success" /> : <i className="fa fa-times ml-2 text-danger" />}
        </div>
        {Object.keys(detailsTitle).filter(d => !hit[d]).map(detail => (
            <div className="small" key={detail}>
                {detailsTitle[detail]}
                {hit[detail] ? <i className="fa fa-check ml-2 text-success" /> : <i className="fa fa-times ml-2 text-danger" />}
            </div>
        ))}
    </>
)

const CarHealth = ({ hit }) => {
    let hitHealth = hit.health
    return (
        <>
            <div className={healthClass(hitHealth)} id={`health-${hit.id}`}>
                <i className="fa fa-stethoscope mr-2"></i>
                {hitHealth}%
            </div>
            <Tooltip
                anchorId={`health-${hit.id}`}
                place="bottom"
                style={{ zIndex: 10000 }}
            >
                <HealthResults hit={hit} />
            </Tooltip>
        </>
    )
}

export default CarHealth