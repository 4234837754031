import { useState } from "react";
import * as ReactDOM from 'react-dom/client';
import { ready } from './entries/utils'
import { VehicleBuilder } from './entries/FormElements'
import { humanize } from "./editor/common/Utils";

export const Form = ({
    recordType,
    showBadge = false,
    colours,
    manufacturer_id,
    family_id,
    defaultYear,
    defaultBadge,
    defaultColour,
    defaultBodyconfiguration,
    defaultModelYear
}) => {
    let [manufacturer, setManufacturer] = useState(manufacturer_id)
    let [family, setFamily] = useState(family_id)
    let [year, setYear] = useState(defaultYear)
    let [badge, setBadge] = useState(defaultBadge)
    let [bodyconfiguration, setBodyconfiguration] = useState(defaultBodyconfiguration)
    let [wheelbaseconfiguration, setWheelbaseconfiguration] = useState()
    let [modelYear, setModelYear] = useState(defaultModelYear)
    let defaultColours = []
    if (colours) {
        defaultColours = colours
    }
    let [colourOptions, setColourOptions] = useState(defaultColours)
    let [colour, setColour] = useState(defaultColour)
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    return (
        <>
            <input type="hidden" name="authenticity_token" value={csrf} />
            <VehicleBuilder
                manufacturer={manufacturer}
                setManufacturer={setManufacturer}
                family={family}
                setFamily={setFamily}
                year={year}
                setYear={setYear}
                defaultFamilyId={family_id}
                defaultYear={defaultYear}
                bodyconfiguration={bodyconfiguration}
                setBodyconfiguration={setBodyconfiguration}
                showBadge={showBadge}
                badge={badge}
                setBadge={setBadge}
                colourOptions={colourOptions}
                colour={colour}
                setColour={setColour}
                wheelbaseconfiguration={wheelbaseconfiguration}
                setWheelbaseconfiguration={setWheelbaseconfiguration}
                modelYear={modelYear}
                setModelYear={setModelYear}
                defaultModelYear={defaultModelYear}
                badgeHint="If blank, will apply to all badges"
            />
            <input name={`${recordType}[manufacturer_id]`} value={manufacturer} type="hidden" />
            <input name={`${recordType}[family_id]`} value={family} type="hidden" />
            <input name={`${recordType}[year]`} value={year} type="hidden" />
            {showBadge && (
                <>
                    <input name={`${recordType}[bodyconfiguration]`} value={bodyconfiguration} type="hidden" />
                    <input name={`${recordType}[modelyear]`} value={modelYear} type="hidden" />
                    <input name={`${recordType}[badge]`} value={badge} type="hidden" />
                    <input name={`${recordType}[colour]`} value={colour} type="hidden" />
                </>
            )}
            <input
                type="submit"
                name="commit"
                value={`Create ${humanize(recordType)}`}
                className="btn btn btn-outline-success"
                data-disable-with="Create Preorder car"
            />
        </>
    )
}

ready(function () {
    let preorderForm = document.getElementById('react-preorder-form')
    if (preorderForm) {
        let manufacturer_id = preorderForm.getAttribute('manufacturer_id') || false
        const root = ReactDOM.createRoot(preorderForm)
        root.render(
            <Form
                recordType='preorder_car'
                manufacturer_id={manufacturer_id}
            />
        );
    }
})

