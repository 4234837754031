const updateCar = ({ car, fields, setCar, setLoading, afterUpdate, setErrors }) => {
  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");

  setLoading(true)
  fetch(`/cars/${car.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: "application/json"
    },
    body: JSON.stringify({
      car: fields,
      authenticity_token: csrf
    }),
  }).then(res => {
    if (res.ok) {
      return res.json()
    }
    throw new Error('Something went wrong');
  }).then(res => {
    setLoading(false)
    if (res.errors) {
      setErrors(res.errors)
    } else {
      // Update the car data only overwriting the tag
      setCar({
        ...car,
        tag: res.tag,
        before_price: res.before_price,
        dapprice: res.dapprice,
        egcprice: res.egcprice,
        price: res.price,
        price_type: res.price_type
      });
      // close modal
      afterUpdate()
    }
  }).catch((error) => {
    console.log(error)
  });
}

export { updateCar }