import React from 'react'

import * as Routes from '../../routes'
import { Car } from './types'

interface WatchlistSectionProps {
  cars: Car[]
}

const WatchlistSection: React.FC<WatchlistSectionProps> = ({ cars }) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-heart" /> Watchlist
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Car</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {cars.map((car) => (
          <tr key={car.id}>
            <td>
              <a href={Routes.car_path(car)}>{car.name}</a>
            </td>
            <td>{car.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default WatchlistSection
