
import { useState } from 'react';
import { Tooltip } from 'react-tooltip'
import moment from 'moment'

let LeadStatsTooltip = ({ hit }) => {
  let [leadStats, setLeadStats] = useState(false)

  let onLoad = () => {
    fetch(`/cars/${hit.id}/stats`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => res.json())
      .then(res => {
        setLeadStats(res)
      })
  }

  return (
    <Tooltip
      anchorSelect={`#leads-count-${hit.id}`}
      place="bottom-left"
      style={{ zIndex: 10000 }}
      afterShow={onLoad}
    >
      {leadStats ? (
        <>
          {leadStats.last_lead_date && <div className="mb-2">Last lead {moment.utc(leadStats.last_lead_date).format("DD/MM")}</div>}

          {leadStats.counts && leadStats.counts.map(l => (
            <>
              <div>{l[1]} x {l[0]}</div>
            </>
          ))}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </Tooltip>
  )
}

export default LeadStatsTooltip