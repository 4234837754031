import React from 'react'

import { ServiceBooking } from './types'

interface ServiceBookingsSectionProps {
  serviceBookings: ServiceBooking[]
}

const ServiceBookingsSection: React.FC<ServiceBookingsSectionProps> = ({ serviceBookings }) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-heart" /> Service Bookings
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>RO</th>
          <th>Description</th>
          <th>Make</th>
          <th>Model</th>
        </tr>
      </thead>
      <tbody>
        {serviceBookings.map((booking) => (
          <tr key={booking.id}>
            <td>{booking.appointment_date}</td>
            <td>{booking.service_ro}</td>
            <td>{booking.service_description}</td>
            <td>{booking.vehicle_make}</td>
            <td>{booking.vehicle_model}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default ServiceBookingsSection
