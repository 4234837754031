import { useState, useEffect, useRef } from 'react'
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'

import { PushNotificationService } from '../pushNotifications/pushNotificationService'

const App = () => {
  const notification = useRef(null)
  const [pushNotifications, setPushNotifications] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(true)
    let page_number = 0
    let params = { page: page_number }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    try {
      PushNotificationService.getPushNotifications(params, csrf).then((data) => {
        setPushNotifications(data?.data?.push_notifications)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="App">
      <div className="container py-3">
        <h4>Push Notifications</h4>
        <Toast ref={notification} />
        <PrimeReactProvider>
          <DataTable
            value={pushNotifications}
            tableStyle={{ minWidth: '50rem' }}
            paginator
            rows={10}
            loading={loading}
          >
            <Column field="title" header="title"></Column>
            <Column field="body" header="body"></Column>
          </DataTable>
        </PrimeReactProvider>
      </div>
    </div>
  )
}

export default App