// MANAGED BY App.js
import { useState, useEffect, useContext } from 'react'
import {
  useParams
} from "react-router-dom"
import { useInstantSearch } from 'react-instantsearch'
import { DealershipContext, WebsiteContext } from '../contexts'

export const DownloadLink = () => {
  const { dealership } = useContext(DealershipContext)
  const { website } = useContext(WebsiteContext)
  const { dealershipSlug, websiteSlug } = useParams()

  const [downloadLink, setDownloadLink] = useState('')

  const { uiState, results } = useInstantSearch()

  let indexUiState = uiState[`Lead_${process.env.RAILS_ENV}:created_at:desc`]

  useEffect(() => {
    let defaultDownloadUrl = `/dealerships/${dealershipSlug}/leads/download_csv.csv`
    if (website?.id || websiteSlug) defaultDownloadUrl = `/websites/${website?.id || websiteSlug}/leads/download_csv.csv`

    let link = ''
    if (results.nbHits <= results.hits.length) {
      let data = []
      results.hits.forEach((hit) => {
        if (data.indexOf(hit.id) < 0)
          data.push(hit.id)
      })
      if (data.length > 0) {
        link = `${defaultDownloadUrl}?ids=${JSON.stringify(data)}`
      }
    } else {
      let refinements = indexUiState?.refinementList || {}
      if (indexUiState?.query) {
        refinements = { ...refinements, ...{ query: indexUiState?.query } }
      }
      if (indexUiState?.range && document.getElementById('flatpicker-date-range')) {
        const pickr = document.getElementById("flatpicker-date-range")._flatpickr
        let range = { "range": { min: pickr.selectedDates[0].getTime() / 1000, max: pickr.selectedDates[1].getTime() / 1000 + 60 * 60 * 24 + 30000 } }
        refinements = { ...refinements, ...range }
      }
      link = `${defaultDownloadUrl}?refinementList=${JSON.stringify(refinements)}`
    }
    setDownloadLink(link || defaultDownloadUrl)
  }, [website?.id, dealership?.slug])

  return (
    <a
      href={`${downloadLink}`}
      className="dropdown-item"
      id="download-csv"
    >
      Download CSV
    </a>
  )
}