import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LeadClusterContext } from '../../contexts'

const SidebarItem = () => {
  let { leadCluster } = useContext(LeadClusterContext)

  return (
    <div className="border-top d-flex pt-3 px-3 align-items-center">
      <div className="mr-auto">
        <b>
          Insurance:
        </b>
      </div>
      <div>
        <Link
          to={`/dealerships/${window.dealership.slug}/lead_clusters/${leadCluster.id}/insurance`}
          className="btn btn-outline-success btn-block btn-sm"
        >
          <i className="fa fa-plus mr-2"></i>
          Quote
        </Link>
      </div>
    </div>
  )
}

export default SidebarItem