import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { standardHeaders } from '../../entries/utils'
import moment from 'moment'
import CustomReportForm from './CustomReportForm'

const CustomReport = ({ customReport, fetchCustomReports, fetchData, customReportsData, data }) => {
  let [deleting, setDeleting] = useState(false)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const destroy = (customReport) => {
    setDeleting(true)
    fetch(`/dealerships/${dealershipSlug}/custom_reports/${customReport.id}.json`, {
      method: 'DELETE',
      headers: standardHeaders
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        fetchCustomReports()
        setDeleting(false)
      }).catch(error => {
        console.error('Error:', error)
      })
  }

  const generate = (customReport) => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/custom_reports/${customReport.id}/generate.json`, {
      method: 'POST',
      headers: standardHeaders
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        fetchData()
        setLoading(false)
      }).catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <tr>
      <td>
        <div>
          {customReport.name}
        </div>
        <span className="small text-secondary">
          Created {moment(customReport.created_at).fromNow()}
        </span>
      </td>
      <td>
        {customReport.car_types.join(', ')}
      </td>
      <td>
        {customReport.manufacturer_names}
      </td>
      <td>
        {customReport.location_names.map(location => (
          <div className="badge badge-secondary mr-1" key={location}>{location}</div>
        ))}
      </td>
      <td>
        <div className="badge badge-secondary">
          {customReport.fields.length}
        </div>
      </td>
      <td>
        <button
          onClick={() => generate(customReport)}
          className={"btn btn-outline-primary btn-sm mr-2" + (loading ? ' disabled' : '')}
          disabled={loading}
        >
          {loading ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-1"></i>
              Loading...
            </span>
          ) : (
            <>Generate</>
          )}
        </button>
        <EditCustomReport
          customReport={customReport}
          customReportsData={customReportsData}
          fetchCustomReports={fetchCustomReports}
          data={data}
        />
        <button
          onClick={() => destroy(customReport)}
          className={"btn btn-outline-danger btn-sm" + (deleting ? ' disabled' : '')}
          disabled={deleting}
        >
          {deleting ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-1"></i>
              Loading...
            </span>
          ) : (
            <>Destroy</>
          )}
        </button>
        <a className="btn btn-outline-secondary btn-sm ml-2" href={`/dealerships/${dealershipSlug}/custom_reports/${customReport.id}/stock_filters`}>
          Stock Filters
          ({customReport.stock_filters_count})
        </a>
      </td>
    </tr>
  )
}

const EditCustomReport = ({ customReport, customReportsData, data, fetchCustomReports }) => {
  return (
    <CustomReportForm
      customReportsData={customReportsData}
      data={data}
      customReport={customReport}
      fetchCustomReports={fetchCustomReports}
    >
      <i className="fa fa-edit mr-1"></i>
      Edit
    </CustomReportForm>
  )
}

export const CustomReports = ({ data, fetchData }) => {
  let { dealershipSlug } = useParams()
  let [customReportsData, setCustomReportsData] = useState(null)

  const fetchCustomReports = () => {
    fetch(`/dealerships/${dealershipSlug}/custom_reports.json`)
      .then(res => res.json())
      .then(res => setCustomReportsData(res))
  }

  useEffect(() => {
    fetchCustomReports()
  }, [])

  return (
    <div className="box mt-3">
      <div className="d-flex p-3">
        <div className="mr-auto">
          <h4>My Custom Reports</h4>
        </div>
        <div className="float-left">
          {data && customReportsData && (
            <CustomReportForm
              data={data}
              fetchCustomReports={fetchCustomReports}
              fetchData={fetchData}
              customReportsData={customReportsData}
            >
              <i className="fa fa-plus mr-1"></i>
              New Custom Report
            </CustomReportForm>
          )}
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Car Types</th>
            <th>Makes</th>
            <th>Locations</th>
            <th>Fields</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customReportsData && customReportsData.custom_reports?.map(customReport => (
            <CustomReport
              key={customReport.id}
              customReport={customReport}
              fetchData={fetchData}
              fetchCustomReports={fetchCustomReports}
              customReportsData={customReportsData}
              data={data}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}