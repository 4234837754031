import React from 'react'

import * as Routes from '../../routes'
import { Dealership, Email } from './types'

interface RecentEmailsSectionProps {
  dealership: Dealership
  emails: Email[]
}

const RecentEmailsSection: React.FC<RecentEmailsSectionProps> = ({ dealership, emails }) => (
  <div className="box p-3 mb-3">
    <h4>
      <i className="fa fa-envelope mr-1" /> Recent Emails
    </h4>
    <table className="table">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Status</th>
          <th>Sent At</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {emails.map((email) => (
          <tr key={email.id}>
            <td>
              <i className="fa fa-envelope" /> {email.subject}
            </td>
            <td className={email.status === 'Opened' ? 'text-success' : 'text-secondary'}>
              {email.status}
            </td>
            <td>{email.sent_at}</td>
            <td>
              <a
                className="btn btn-outline-primary"
                href={Routes.dealership_email_path(dealership, email)}
              >
                View
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default RecentEmailsSection
