import { Move } from './Icons'

type DragHandleProps = {
  isDragging?: boolean
  draghandleProps?: any
}

const DragHandle: React.FC<DragHandleProps> = ({ isDragging, draghandleProps }) => {
  return (
    <div
      style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      className="d-flex align-items-center"
      {...draghandleProps}
    >
      <div className={isDragging ? 'd-none' : 'd-block'}>
        <span className="mb-2">
          <Move />
        </span>
        <span className="ml-2 mr-3" style={{ color: '#5f6269' }}>
          Move
        </span>
      </div>
    </div>
  )
}

export default DragHandle
