import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Input, Select, Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const Form = ({
  categories,
  loadPipelines,
  buttonComponent,
  pipeline,
  action = "create"
}) => {
  let [visible, setVisible] = useState(false)
  let [name, setName] = useState(pipeline?.name || '')
  let [selectedCategories, setSelectedCategories] = useState(pipeline?.categories || [])
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState([])

  let defaultCategories = selectedCategories.map(category => ({ label: category, value: category }))

  const save = () => {
    let data = {
      pipeline: {
        name: name,
        categories: selectedCategories
      }
    }

    let method
    let url

    if (action === 'create') {
      method = 'POST'
      url = `/dealerships/${window.dealership.id}/pipelines`
    } else {
      method = 'PUT'
      url = `/dealerships/${window.dealership.id}/pipelines/${pipeline.id}`
    }

    setLoading(true)
    fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            setLoading(false);
            setErrors(data);
            return Promise.reject(data);
          });
        }
        return response.json(); // Handle other statuses normally
      })
      .then(() => {
        loadPipelines()
        setLoading(false)
        setVisible(false)
      })
  }

  return (
    <>
      {buttonComponent({ setVisible })}
      <Dialog
        header={action === 'create' ? "New Pipeline" : "Edit Pipeline"}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <Input
          label="Name"
          placeholder="Name eg. Service Pipeline"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          label="Lead Categories"
          options={categories.map(category => ({ label: category, value: category }))}
          isMulti={true}
          className="categories-select"
          defaultValue={defaultCategories}
          onChange={(e) => setSelectedCategories(e.map(c => c.value))}
          menuPortalTarget={document.body}
        />
        <Errors errors={errors} />
        <button
          className="btn btn-primary"
          onClick={save}
          disabled={loading}
          id="save-pipeline-button"
        >
          {loading ? <i className="fa fa-spin fa-spinner mr-1" /> : <i className="fa fa-save mr-1" />} Save
        </button>
      </Dialog>
    </>
  )
}

export default Form