export const maxModules = 15

export const rootUrl = document.location.origin

export const salesCategories = [
    "New Vehicle Enquiry",
    "Used Vehicle Enquiry",
    "Test Drive Booking",
    "Special Offer Enquiry",
    "Fleet Enquiry",
    "Finance Enquiry",
    "Insurance Enquiry",
    "Subscription Enquiry",
    "Team Member Enquiry",
    "Vehicle Reservation",
]
