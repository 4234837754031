import React from 'react'
import { useState } from 'react'
import { timeAgoInDays } from '../../entries/utils'
import { Tooltip } from 'react-tooltip'
import { useSearchableData, useSearch } from 'use-orama';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


let defaultOptions = {
  term: '',
  properties: [],
  limit: 100
}

const imageTemplate = (car) => {
  let default_image_url = "https://res.cloudinary.com/total-dealer/image/upload/v1692498338/td_dashboard/placeholder-image_l1iz3a.png"
  let image_url = car.cover_image_url ? car.cover_image_url : default_image_url
  return (
    <a href={car.listing_url} target="_blank">
      <img
        src={image_url}
        height={66}
        width={100}
        className="border rounded"
        style={{ objectFit: 'cover' }}
      />
    </a>
  )
}

const Locations = (car) => {
  return (
    <>
      {car.listing_sources.map(source => (
        <div key={`${car.id}-${source}`}>
          <div className={`circle-${source[0]}`} id={`${car.id}-${source}`}>
            {source[0].toUpperCase()}
          </div>
          <Tooltip
            anchorId={`${car.id}-${source}`}
            place="bottom"
            style={{ zIndex: 10000 }}
          >
            {source}
          </Tooltip>
        </div>
      ))}
    </>
  )
}

const Kms = (car) => {
  return (
    <>
      {car.kms.toLocaleString()}
      <div className="small text-secondary">kms</div>
    </>
  )
}

const Age = (car) => {
  return (
    <>
      {timeAgoInDays(car.listed_at)}d
    </>
  )
}

const Price = (car) => {
  return (
    <>
      ${car.price.toLocaleString()}
    </>
  )
}


let Cars = ({ cars, car }) => {
  let [options, setOptions] = useState({ ...defaultOptions, where: { state: car.state } })

  useSearchableData(cars);

  const { done, results } = useSearch(options);

  let stateFilterOptions = cars
    .map(c => c.state)
    .filter((value, index, self) => self.indexOf(value) === index)

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          {results && (
            <div className="form-group">
              <select
                className="form-control"
                onChange={e => setOptions({ ...options, where: { state: e.target.value } })}
                defaultValue={car.state}
              >
                {stateFilterOptions.map(state => (
                  <option key={state} value={state}>
                    {state} ({cars.filter(c => c.state === state).length})
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {results && <DataTable value={results.hits.map(h => h.document)} tableStyle={{ minWidth: '50rem' }} sortMode="multiple">
        <Column body={imageTemplate} header="Image"></Column>
        <Column field="year" header="Year"></Column>
        <Column field="color" header="Color"></Column>
        <Column field="state" header="State"></Column>
        <Column field="price" body={Price} header="Price" sortable></Column>
        <Column field="kms" body={Kms} header="Kms" sortable></Column>
        <Column field="listed_at" body={Age} header="Age" sortable></Column>
        <Column field="seller_type" header="Seller Type"></Column>
        <Column body={Locations} header="Locations"></Column>
      </DataTable>}
    </>
  )
}

export default Cars