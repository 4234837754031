import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { CurrentUserContext } from '../contexts'
import { Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const password = 't12dsf90jsd4'
const rootUrl = `${window.location.protocol}//${window.location.host}/`

const SettingsLayout = ({ children }) => (
  <div className="settings-layout">
    {children}
  </div>
);

const Catalogues = ({ websites }) => {

  return (
    <SettingsLayout>
      <div className="box p-3 mb-3">
        <h4>Facebook Catalogue (Automotive Feed)</h4>
        <p>Use this feed if you want to create ads using your inventory.</p>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>Website name</th>
              <th>Facebook Catalogue Feed URL</th>
            </tr>
          </thead>
          <tbody>
            {websites.map((website) => (
              <tr key={website.id}>
                <td className="small">{website.name}</td>
                <td className="small">{`${rootUrl}websites/${website.id}/cars.csv?type=facebook_auto`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Username: totaldealer</p>
        <p>Password: {password}</p>
      </div>

      <div className="box p-3 mb-3">
        <h4>Facebook Catalogue (Products Feed)</h4>
        <p>Use this feed if you want to create a Facebook shop.</p>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>Website name</th>
              <th>Facebook Catalogue Feed URL</th>
            </tr>
          </thead>
          <tbody>
            {websites.map((website) => (
              <tr key={website.id}>
                <td className="small">{website.name}</td>
                <td className="small">{`${rootUrl}websites/${website.id}/cars.csv?type=facebook`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Username: totaldealer</p>
        <p>Password: {password}</p>
      </div>

      <div className="box p-3 mb-3">
        <h4>Google VLA Feed</h4>
        <p>Feed used to load stock to Google VLA feeds.</p>
        <p>To customize store_code, this can be passed as a parameter eg. &store_code=abc123</p>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>Website name</th>
              <th>Google VLA Feed URL</th>
            </tr>
          </thead>
          <tbody>
            {websites.map((website) => (
              <tr key={website.id}>
                <td className="small">{website.name}</td>
                <td className="small">{`${rootUrl}websites/${website.id}/cars.csv?type=google_vpd`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Username: totaldealer</p>
        <p>Password: {password}</p>
      </div>
    </SettingsLayout>
  )
};

const Wrapper = () => {
  let [websites, setWebsites] = useState(false)
  let [loading, setLoading] = useState(false)
  let [dealership, setDealership] = useState(false)
  const currentUser = useContext(CurrentUserContext)
  let { dealershipSlug } = useParams()
  let [catalogueEnabled, setCatalogueEnabled] = useState()

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/facebook_catalogue.json`)
      .then(res => res.json())
      .then(res => {
        setWebsites(res.websites)
        setDealership(res.dealership)
        setCatalogueEnabled(res.dealership.enable_catalogues)
      })
  }, [])

  if (!websites || !dealership || !currentUser) {
    return (
      <div className="p-3">
        <p>Loading...</p>
      </div>
    )
  }

  const onChange = () => {
    setLoading(true)
    let newCatalogue = !catalogueEnabled

    fetch(`/dealerships/${dealershipSlug}`, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify({
        dealership: {
          enable_catalogues: newCatalogue
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
        setCatalogueEnabled(newCatalogue)
      })
  }

  return (
    <>
      <div className="w-100 p-3">
        <div className="box p-3 mb-3">
          <p>Facebook Catalogues are {catalogueEnabled ? 'enabled' : 'disabled'} for this dealer.</p>
          <Switch
            value={catalogueEnabled}
            label="Enable Catalogues"
            onChange={onChange}
            isDisabled={loading || !currentUser.admin}
            id={`enable-catalogue-switch`}
          />
        </div>
        {
          catalogueEnabled && (
            <Catalogues
              websites={websites}
            />
          )
        }
      </div>
    </>
  )
}

export default Wrapper;