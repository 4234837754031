import {
  useState, useCallback, useEffect
} from 'react'
import { useRange } from 'react-instantsearch'
import flatpickr from "flatpickr"
import moment from 'moment'

const dropdownOptions = [
  {
    label: 'Last 7 days',
    start: moment().subtract(7, 'd').format('DD-MM-YY'),
    end: moment().format('DD-MM-YY')
  },
  {
    label: 'Last 30 days',
    start: moment().subtract(1, 'months').format('DD-MM-YY'),
    end: moment().format('DD-MM-YY')
  },
  {
    label: moment().subtract(1, 'months').format('MMMM'),
    start: moment().subtract(1, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(1, 'months').endOf('month').format('DD-MM-YY')
  },
  {
    label: moment().subtract(2, 'months').format('MMMM'),
    start: moment().subtract(2, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(2, 'months').endOf('month').format('DD-MM-YY')
  },
  {
    label: moment().subtract(3, 'months').format('MMMM'),
    start: moment().subtract(3, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(3, 'months').endOf('month').format('DD-MM-YY')
  },
  {
    label: 'Last 364 days',
    start: moment().subtract(1, 'years').format('DD-MM-YY'),
    end: moment().format('DD-MM-YY')
  },
]

const DateRangePickerComponent = (props) => {
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)

  const handleDropdownClick = (item) => {
    const pickr = document.getElementById("flatpicker-date-range")._flatpickr
    pickr.setDate([item.start, item.end], true)
  }

  const handleDatePickerChange = useCallback((selectedDates) => {
    let start = Date.parse(selectedDates[0]) / 1000
    let end = Date.parse(selectedDates[1]) / 1000 + 60 * 60 * 24 + 30000
    if (start && end) {
      setMinDate(start)
      setMaxDate(end)
    } else {
      refine(minDate, maxDate)
    }
  }, [])

  const refine = (begin, end) => {
    const min = begin > props.range.min ? begin : props.range.min
    const max = end < props.range.max ? end : props.range.max

    props.refine([min, max])
  }

  useEffect(() => {
    if (minDate && maxDate) {
      refine(minDate, maxDate)
    }
  }, [minDate, maxDate])

  useEffect(() => {
    flatpickr("#flatpicker-date-range", {
      allowInput: true,
      dateFormat: "d/m/y",
      maxDate: moment().format("DD/MM/YY"),
      mode: "range",
      onChange: handleDatePickerChange
    })
  }, [])

  return (
    <div>
      <div className="input-group">
        <input type="text" className="form-control" id="flatpicker-date-range" placeholder="Date Range..." />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            aria-haspopup="true"
          />
          <div className="dropdown-menu dropdown-menu-right">
            {dropdownOptions.map((item) => (
              <a
                key={item.label}
                className="dropdown-item"
                onClick={() => handleDropdownClick(item)}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const DateRangePicker = connectRange(DateRangePickerComponent)
export default DateRangePicker

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectRange(Component) {
  const Range = (props) => {
    const data = useRange(props)

    return <Component {...props} {...data} />
  }

  return Range
}
